import React, { ReactNode } from "react";
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type Severity = "success" | "info" | "error";
export type TextAlign = "left" | "center" | "right";

interface ToastNotificationProps {
  severity?: Severity;
  align?: TextAlign;
  className?: string;
  children: ReactNode;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({
  className,
  align = "left",
  severity,
  children,
}) => {
  const [open, setOpen] = React.useState(true);

  severity ??= "info";
  let color: string;
  switch (severity) {
    case "success":
      color = "#488C6E";
      break;
    case "error":
      color = "#F16063";
      break;
    case "info":
    default:
      color = "#4A5568";
      break;
  }
  return open ? (
    <Alert
      className={className}
      severity={severity}
      variant="filled"
      icon={false}
      sx={{
        fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
        backgroundColor: color,
        fontWeight: 500,
        width: "100%",
        maxWidth: 591,
        "& .MuiAlert-message": {
          width: "100%",
          textAlign: align,
        },
        "& .MuiAlert-action": {
          padding: "4px 0 0 12px",
        },
      }}
      action={
        <IconButton
          data-testid="close-toast-button"
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {children}
    </Alert>
  ) : null;
};

export default ToastNotification;
