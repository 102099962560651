import React, { Dispatch, SetStateAction, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MaterialIconButton from "lib/atoms/Button/MaterialIconButton";
import { IMemberType, TStatus } from "common/interfaces/tenant.interface";
import { capitalize } from "common/utils";
import { modalsFactory } from "../../tenant.utils";
import useTableCollapse from "../../useTableCollapse";
import { Paragraph, theme } from "@gilbarbara/components";
import IconButton from "lib/atoms/Button/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import {
  StyledContainer,
  StyledHeader,
  StyledHeadline,
  StyledSubHeadline,
  StyledTable,
  StyledTableHead,
} from "./manageTenant.cmp";
import { StyledTableRow } from "common/styles/material-ui-styles";
import AddTenantMemberModal from "../addTenantMemberModal/addTenantMemberModal.cmp";
import DeleteMemberModal from "../deleteMemberModal/deleteMember.cmp";
import UpdateMemberModal from "../updateMemberModal/updateMember.cmp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "@emotion/styled";

const initialState = {
  addMember: {
    visible: false,
  },
  deleteMember: {
    visible: false,
    id: "",
    displayName: "",
  },
  editMember: {
    visible: false,
    email: "",
    lastName: "",
    firstName: "",
    id: "",
    role: "",
  },
};

interface IProps {
  data: IMemberType[] | undefined;
  tenantId: string | undefined;
}

type TModalState = typeof initialState;
type TModalSetter = Dispatch<SetStateAction<TModalState>>;

const isAccepted = (status: TStatus) => status === "ACCEPTED";
const splitName = (displayName: string): string[] => displayName.split(" ");

const getFirstName = (words: string[], status: TStatus) =>
  isAccepted(status) ? words.splice(0, words.length - 1).join(" ") : "-";

const getLastName = (words: string[], status: TStatus) =>
  isAccepted(status) ? words[words.length - 1] : "-";

function Members({ data = [], tenantId }: IProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [memberModals, setMemberModals] = useState(initialState);
  const { tableData, expandButton } = useTableCollapse<IMemberType>({
    data,
    collapseText: "See less members",
    expandText: `View all ${data.length} members`,
  });
  const hasNoMembers = !data?.length;

  const setModal = modalsFactory<TModalState, TModalSetter>(
    memberModals,
    setMemberModals
  );

  const addMember = setModal("addMember");
  const deleteMember = setModal("deleteMember");
  const editMember = setModal("editMember");
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "500px",
      width: "100%",
      background: "#010032",
      boxShadow: "3px 4px 25px rgba(30, 26, 44, 0.02)",
      borderRadius: "8px",
      padding: 24,
      transform: "translate(-23px, 0) !important",
    },
    "& .MuiTooltip-popper": {},
    "& .MuiTooltip-arrow": {
      color: "#010032",
      width: "7em",
      height: "2em",
      transform: "translate(16px, -12px) !important",
    },
    [`& .${tooltipClasses.popper}`]: {
      transform: "translate(394px, 442px) !important",
    },
  });
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeadline>
          Members &nbsp;
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomWidthTooltip
                open={open}
                title={
                  <>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "25px",
                      }}
                    >
                      Members
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        marginTop: 8,
                      }}
                    >
                      When you create a tenant, you are automatically the tenant
                      owner. But you can invite more users to your tenant with
                      different roles.
                      <br />
                      <br />
                      Learn more in our &nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://dimensionfour.io/resources/developer-docs/getting-started"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "#ffa654",
                          cursor: "pointer",
                        }}
                      >
                        Documentation.
                      </a>
                    </Paragraph>
                  </>
                }
                placement="bottom-start"
                arrow
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  variant="neutral"
                  style={{ border: "none" }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#3237B8",
                      width: "18px !important",
                      height: "18px !important",
                    }}
                  />
                </IconButton>
              </CustomWidthTooltip>
            </div>
          </ClickAwayListener>
        </StyledHeadline>
        <IconButton variant="primary" onClick={addMember.showModal}>
          <AddIcon />
          Add member
        </IconButton>
      </StyledHeader>
      {hasNoMembers ? (
        <StyledSubHeadline>No member created</StyledSubHeadline>
      ) : (
        <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {tableData?.map((member) => (
              <StyledTableRow key={member.id}>
                <TableCell component="th" scope="row">
                  {getFirstName(splitName(member.displayName), member.status)}
                </TableCell>
                <TableCell>
                  {getLastName(splitName(member.displayName), member.status)}
                </TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell>{capitalize(member.role)}</TableCell>
                <TableCell>{capitalize(member.status)}</TableCell>
                <TableCell sx={{ textAlign: "right", padding: "0" }}>
                  <MaterialIconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                      editMember.withParams({
                        id: member.id,
                        email: member.email,
                        lastName: getLastName(
                          splitName(member.displayName),
                          member.status
                        ),
                        firstName: getFirstName(
                          splitName(member.displayName),
                          member.status
                        ),
                        role: member.role,
                        visible: true,
                      })
                    }
                  >
                    <EditIcon />
                  </MaterialIconButton>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "right",
                    padding: "12px 42px 12px 12px",
                    width: 100,
                  }}
                >
                  <MaterialIconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                      deleteMember.withParams({
                        id: member.id,
                        displayName: isAccepted(member.status)
                          ? member.displayName
                          : member.email,
                        visible: true,
                      })
                    }
                  >
                    <ClearIcon />
                  </MaterialIconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
      {expandButton}
      <AddTenantMemberModal
        open={memberModals.addMember.visible}
        action={addMember.hideModal}
        tenantId={tenantId}
      />
      <DeleteMemberModal
        open={memberModals.deleteMember.visible}
        onClose={deleteMember.hideModal}
        tenantId={tenantId}
        member={memberModals.deleteMember}
      />
      <UpdateMemberModal
        open={memberModals.editMember.visible}
        onClose={editMember.hideModal}
        tenantId={tenantId}
        member={memberModals.editMember}
      />
    </StyledContainer>
  );
}

export default Members;
