import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useSpaceOptions from "hooks/useSpaceOptions";
import { ITenants } from "hooks/useTenant";
import useTracker from "hooks/useTracker";
import { CREATE_SPACE } from "./createSpace.gql";
import { GET_SPACE, GET_SPACES } from "common/graphql/space.gql";
import { GET_TENANTS } from "common/graphql/tenant.gql";
import { ISpaces, ISpaceWithPoints } from "../../tenant.interface";
import { IOption } from "common/interfaces/shared.interface";
import Modal from "lib/organisms/Modal/Modal";
import { shouldUpdateTree } from "lib/organisms/Navbar/useNavbar";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import Spinner from "common/assets/spinner/spinner";
import { Container } from "common/styles/material-ui-styles";
import TextField from "../../../../lib/atoms/TextField/TextField";

import { Paragraph, theme } from "@gilbarbara/components";
interface ICreateSpaceState {
  name: string;
  parent: IOption | null;
}

interface ICreateSpaceProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
}

const initialState = {
  name: "",
  parent: {
    value: "",
    label: "",
  },
};

function CreateSpaceModal({ open, onClose, tenantId }: ICreateSpaceProps) {
  const { trackEvent } = useTracker();
  const [values, setValues] = useState<ICreateSpaceState>(initialState);
  const { options, defaultOption } = useSpaceOptions(tenantId, "SPACE");
  const context = {
    headers: {
      "x-tenant-id": tenantId,
    },
  };

  const [createSpace, { loading, error, reset: resetSpaceMutation }] =
    useMutation(CREATE_SPACE, {
      context,
      update: (store, { data }) => {
        const tenantsData = store.readQuery<ITenants>({ query: GET_TENANTS });
        const spacesData = store.readQuery<ISpaces>({ query: GET_SPACES });
        const createSpaceData = data.space.create;
        const currentSpace = store.readQuery<ISpaceWithPoints>({
          query: GET_SPACE,
          variables: {
            spaceInput: { id: { _EQ: values.parent?.value } },
            pointsInput: {},
          },
        });

        if (currentSpace && "space" in currentSpace) {
          store.writeQuery<ISpaceWithPoints>({
            query: GET_SPACE,
            variables: {
              spaceInput: { id: { _EQ: values.parent?.value } },
              pointsInput: {},
            },
            data: {
              space: {
                id: currentSpace.space.id,
                name: currentSpace.space.name,
                metadata: currentSpace.space.metadata,
                children: {
                  nodes: [
                    ...currentSpace.space.children.nodes,
                    {
                      id: createSpaceData.id,
                      name: createSpaceData.name,
                      normalizedName: createSpaceData.normalizedName,
                      createdAt: createSpaceData.createdAt,
                      updatedAt: createSpaceData.updatedAt,
                      __typename: "Space",
                    },
                  ],
                },
                points: {
                  nodes: [...currentSpace.space.points.nodes],
                },
              },
            },
          });
        }

        if (spacesData && "spaces" in spacesData) {
          store.writeQuery<ISpaces>({
            query: GET_SPACES,
            data: {
              spaces: { nodes: [...spacesData.spaces.nodes, createSpaceData] },
            },
          });
        }

        if (tenantsData && "tenants" in tenantsData) {
          store.writeQuery<ITenants>({
            query: GET_TENANTS,
            data: {
              tenants: tenantsData.tenants.map((tenant) =>
                tenant.id !== tenantId
                  ? tenant
                  : { ...tenant, totalSpaces: tenant.totalSpaces + 1 }
              ),
            },
          });
        }
      },
    });

  const onSubmit = async () => {
    const { name, parent } = values;
    const normalizedValues = parent?.value
      ? { name, parentId: parent.value }
      : { name };
    await createSpace({
      variables: {
        input: {
          ...normalizedValues,
        },
      },
    });
    sessionStorage.removeItem("navigation");
    shouldUpdateTree(!shouldUpdateTree());
    trackEvent({ action: "clickedCreateSpace" });
    onClose();
  };

  useEffect(() => {
    setValues(initialState);
    resetSpaceMutation();
  }, [open, resetSpaceMutation]);

  useEffect(() => {
    setValues((prevState) => ({ ...prevState, parent: defaultOption }));
  }, [open]);

  return (
    <Modal
      open={open}
      title=""
      footer={{
        okText: "Create space",
        onOk: onSubmit,
        onCancel: onClose,
        okButtonProps: {
          disabled: !values.name,
        },
      }}
      style={{ width: "467px" }}
    >
      <Container>
        <Paragraph
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "43px",
            color: "#010032",
          }}
        >
          Create space
        </Paragraph>
        <Paragraph
          style={{
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "25px",
            marginTop: 8,
            color: "#010032",
          }}
        >
          Create a space under tenant “D4”.
          <br />
          <br />
          For mor information about how to create a space by using our API, read
          our
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dimensionfour.io/resources/user-guide/set-up-your-space"
            style={{
              textDecoration: "underline",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
              color: "#010032",
            }}
          >
            &nbsp;documentation.
          </a>
        </Paragraph>
        <br />
        <TextField
          id="outlined-required"
          label="Name*"
          placeholder="e.g. Location"
          onChange={({ target: { value } }) =>
            setValues({ ...values, name: value })
          }
          value={values.name}
          sx={{
            mt: 2,
          }}
          style={{ width: "100%", marginTop: "16px" }}
        />
        <Autocomplete
          id="outlined-required"
          placeholder="e.g. Oslo kommune"
          options={options}
          onChange={(event, value) => setValues({ ...values, parent: value })}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          value={values.parent}
          sx={{
            mt: 2,
          }}
          style={{ width: "100%", marginTop: "16px" }}
          renderInput={(params) => (
            <TextField {...params} label="Parent Space*" />
          )}
        />
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default CreateSpaceModal;
