export const EMPTY_STRING = "";

export function isEmptyObject(value: object) {
  return (
    Boolean(value && typeof value === "object") && !Object.keys(value).length
  );
}

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

// need to add types
export function pipe(...rest: any[]) {
  return (x: any) => rest.reduce((y, f) => f(y), x);
}

export const setSessionItem = (key: string, value: any) =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const getSessionItem = (key: string) => {
  const item = sessionStorage.getItem(key);
  if (typeof item === "string") return JSON.parse(item);
};

export const isNil = (value: any) => value === null || value === undefined;
