import React, { Dispatch, SetStateAction, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ClearIcon from "@mui/icons-material/Clear";
import { IAccessTokenType } from "common/interfaces/tenant.interface";
import { modalsFactory } from "../../tenant.utils";
import IconButton from "lib/atoms/Button/IconButton";
import MaterialIconButton from "lib/atoms/Button/MaterialIconButton";
import {
  StyledContainer,
  StyledHeader,
  StyledHeadline,
  StyledSubHeadline,
  StyledTable,
  StyledTableHead,
} from "./manageTenant.cmp";
import { StyledTableRow } from "common/styles/material-ui-styles";
import AddTokenModal from "../addTokenModal/addTokenModal.cmp";
import DeleteTokenModal from "../deleteTokenModal/deleteTokenModal.cmp";
import useTableCollapse from "../../useTableCollapse";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Paragraph, theme } from "@gilbarbara/components";

const initialState = {
  addToken: {
    visible: false,
  },
  deleteToken: {
    visible: false,
    id: "",
    name: "",
  },
};

interface IProps {
  data: IAccessTokenType[] | undefined;
  tenantId: string | undefined;
}

type TModalState = typeof initialState;
type ModalSetter = Dispatch<SetStateAction<TModalState>>;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "500px",
    width: "100%",
    background: "#010032",
    boxShadow: "3px 4px 25px rgba(30, 26, 44, 0.02)",
    borderRadius: "8px",
    padding: 24,
    transform: "translate(-23px, 0) !important",
    cursor: "pointer !important",
  },
  "& .MuiTooltip-arrow": {
    color: "#010032",
    width: "7em",
    height: "2em",
    transform: "translate(16px, -12px) !important",
  },
  [`& .${tooltipClasses.popper}`]: {
    transform: "translate(394px, 442px) !important",
    cursor: "pointer !important",
  },
});
function AccessTokens({ data = [], tenantId }: IProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [tokenModals, setTokenModals] = useState(initialState);
  const { tableData, expandButton } = useTableCollapse<IAccessTokenType>({
    data,
    collapseText: "See less tokens",
    expandText: `View all ${data?.length} tokens`,
  });
  const hasNoTokens = !data?.length;

  const setModal = modalsFactory<TModalState, ModalSetter>(
    tokenModals,
    setTokenModals
  );

  const addToken = setModal("addToken");
  const deleteToken = setModal("deleteToken");

  const token = {
    id: tokenModals.deleteToken.id,
    name: tokenModals.deleteToken.name,
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeadline>
          Access Tokens &nbsp;
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomWidthTooltip
                open={open}
                title={
                  <>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "25px",
                      }}
                    >
                      Access token
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        marginTop: 8,
                      }}
                    >
                      In order to use our GraphQL API you need to create an
                      access token, so we can make sure you have access to the
                      data.
                      <br />
                      <br /> Learn more about access tokens in our &nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://dimensionfour.io/resources/user-guide/generate-access-token"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "#ffa654",
                          cursor: "pointer",
                        }}
                      >
                        Documentation.
                      </a>
                    </Paragraph>
                  </>
                }
                placement="bottom-start"
                arrow
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  variant="neutral"
                  style={{ border: "none" }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#3237B8",
                      width: "18px !important",
                      height: "18px !important",
                    }}
                  />
                </IconButton>
              </CustomWidthTooltip>
            </div>
          </ClickAwayListener>
        </StyledHeadline>
        <IconButton variant="primary" onClick={addToken.showModal}>
          <AddIcon />
          Create token
        </IconButton>
      </StyledHeader>
      {hasNoTokens ? (
        <StyledSubHeadline>No token created</StyledSubHeadline>
      ) : (
        <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {tableData?.map((token) => (
              <StyledTableRow key={token.id}>
                <TableCell component="th" scope="row">
                  {token.name}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "right", padding: "12px 42px 12px 12px" }}
                >
                  <MaterialIconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                      deleteToken.withParams({
                        visible: true,
                        id: token.id,
                        name: token.name,
                      })
                    }
                  >
                    <ClearIcon />
                  </MaterialIconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}

      {expandButton}
      <AddTokenModal
        open={tokenModals.addToken.visible}
        tenantId={tenantId}
        onClose={addToken.hideModal}
      />
      <DeleteTokenModal
        open={tokenModals.deleteToken.visible}
        tenantId={tenantId}
        onClose={deleteToken.hideModal}
        token={token}
      />
    </StyledContainer>
  );
}

export default AccessTokens;
