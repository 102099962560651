import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useTracker from "hooks/useTracker";
import useDebounce from "hooks/useDebounce";
import { ISpace, IPoint } from "../tenant.interface";
import { IOption } from "common/interfaces/shared.interface";
import { GET_SPACE } from "common/graphql/space.gql";
import { allPropsEmpty, lastActiveNormalizer } from "../tenant.utils";
import IconButton from "lib/atoms/Button/IconButton";
import TabsPanel from "lib/molecules/TabsPanel/TabsPanel";
import PointsTable from "../components/pointsTable/pointsTable.cmp";
import SpacesList from "../components/spacesList/spacesList.cmp";
import ManageSpace from "../components/manageSpace/manageSpace.cmp";
import CreatePointModal from "../components/createPointModal/createPointModal.cmp";
import CreateSpaceModal from "../components/createSpaceModal/createSpaceModal.cmp";
import Layout from "cmp/templates/layoutTemplate.cmp";
import SearchWithFilters from "../components/searchWithFilters/searchWithFilters.cmp";
import useFilters from "../components/searchWithFilters/useFilters";
import Search from "../components/search/search.cmp";
import { ReactComponent as ProfileImage } from "common/assets/profile.svg";
import { ReactComponent as Document } from "common/assets/overview/document.svg";
import { useAppContext } from "context";

export type ISpacePageState = {
  defaultTab?: number;
};

interface ISubSpacesWithPoints {
  space: {
    name: string;
    metadata: object;
    id: string;
    children: {
      nodes: ISpace[];
    };
    points: {
      nodes: IPoint[];
    };
  };
}

export interface IFilters {
  name: string;
  spaceId?: IOption | string;
  updatedAt?: IOption | string;
}

const TABS = ["POINTS", "SPACES", "MANAGE SPACE"] as const;

const initialFilters = {
  name: "",
  spaceId: "",
  updatedAt: "",
};

const filteringSettings: Record<keyof IFilters, "_EQ" | "_GT"> = {
  name: "_EQ",
  spaceId: "_EQ",
  updatedAt: "_GT",
};

function SpacesPage() {
  const {
    setState,
    state: { run, stepIndex, steps, continuous },
  } = useAppContext();
  const [modals, setModals] = useState({
    createSpace: false,
    createPoint: false,
  });
  const [spaceSearch, setSpaceSearch] = useState("");
  const [spaceName, setSpaceName] = useState("");
  const { trackPageView, trackEvent } = useTracker();
  const { name: tenantId, spaceId } = useParams();
  const location = useLocation();
  const pageState = location?.state as ISpacePageState;

  const [tab, setTab] = useState(
    pageState?.defaultTab == undefined ? 2 : pageState?.defaultTab
  );
  const variables = {
    spaceInput: {
      id: {
        _EQ: spaceId,
      },
    },
    pointsInput: {},
  };

  const [getSpaceWithPoints, { loading, data }] =
    useLazyQuery<ISubSpacesWithPoints>(GET_SPACE, {
      context: {
        headers: {
          "x-tenant-id": tenantId,
        },
      },
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-only",
    });

  const space = data?.space;
  const spaces = data?.space?.children?.nodes;
  const points = data?.space?.points?.nodes;

  // Key based on the URL, we use it to find saved filters
  const sessionKey =
    typeof spaceId === "string"
      ? spaceId
      : typeof tenantId === "string"
      ? tenantId
      : "";

  const { filters, onSearch, onFilter, onFiltersReset } = useFilters<IFilters>(
    initialFilters,
    filteringSettings,
    sessionKey,
    variables,
    "name",
    getSpaceWithPoints,
    lastActiveNormalizer
  );

  const hideSearch = !spaces?.length && !spaceSearch && !loading;
  const spaceTabFilters = !!spaceSearch;
  const pointsTabFilters = !allPropsEmpty(filters);

  const searchWithTracking = async (params: object) => {
    await trackEvent({ action: "searchSpace" });
    await getSpaceWithPoints(params);
  };

  const { debounced: debouncedSearch } = useDebounce(searchWithTracking, 500);

  const onTabChange = (event: React.SyntheticEvent | any) => {
    if (event.target.innerText === "MANAGE SPACE") {
      setTab(2);
      trackEvent({ action: "clickedManageSpace" });
    }
    if (event.target.innerText === "MANAGE TENANT") {
      setTab(2);
      setState({ run: true, stepIndex: 3 });
      trackEvent({ action: "clickedManageTenant" });
    } else if (event.target.innerText === "POINTS") {
      setTab(0);
      setState({ run: true, stepIndex: 1 });
    } else if (event.target.innerText === "SPACES") {
      setTab(1);
      setState({ run: true, stepIndex: 2 });
    }
  };

  useEffect(() => {
    trackPageView({ documentTitle: "Tenant's page" });
    if (tab === 2) {
      setTab(2);
      setState({ run: true, stepIndex: 3 });
      trackEvent({ action: "clickedManageTenant" });
    } else if (tab === 0) {
      setTab(0);
      setState({ run: true, stepIndex: 1 });
    } else if (tab === 1) {
      setTab(1);
      setState({ run: true, stepIndex: 2 });
    }
  }, [tab]);
  const ifEmptySearch = (value: string, path: object) =>
    value.length
      ? path
      : {
          variables: {
            spaceInput: { id: { _EQ: spaceId } },
            childrenInput: {},
          },
        };

  const onSpaceSearch = (value: string) => {
    setSpaceSearch(value);
    const params = ifEmptySearch(value, {
      variables: {
        spaceInput: { id: { _EQ: spaceId } },
        childrenInput: { name: { _EQ: value } },
      },
    });
    value.length === 0 ? searchWithTracking(params) : debouncedSearch(params);
  };

  // Persist the space's name to prevent flickering during re-render
  useEffect(() => {
    if (typeof space?.name === "string" && space?.name !== spaceName)
      setSpaceName(space?.name);
  }, [space?.name]);

  useEffect(() => {
    trackPageView({ documentTitle: "Space's page" });
  }, [spaceId]);

  return (
    <Layout>
      <StyledHeader>
        <StyledHeadline>{spaceName}</StyledHeadline>
        <StyledButtons>
          <IconButton
            onClick={() => setModals({ ...modals, createSpace: true })}
            variant="primary"
            style={{ margin: "0 24px 0 0" }}
          >
            <AddIcon />
            Create space
          </IconButton>
          <IconButton
            onClick={() => setModals({ ...modals, createPoint: true })}
            variant="primary"
          >
            <AddIcon />
            Create point
          </IconButton>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dimensionfour.io/resources/user-guide/quickstart"
            style={{ textDecoration: "none", marginLeft: "28px" }}
          >
            <Document />
          </a>
          <NavLink style={{ marginLeft: "28px" }} to="/profile/settings">
            <ProfileImage />
          </NavLink>
        </StyledButtons>
      </StyledHeader>
      <TabsPanel defaultValue={tab} panels={TABS} onChange={onTabChange}>
        <div>
          <SearchWithFilters
            onSearch={onSearch}
            onFilter={onFilter}
            onFiltersReset={onFiltersReset}
            length={points?.length}
            values={filters}
            loading={loading}
          />
          <PointsTable
            points={points}
            loading={loading}
            onCreatePoint={() => setModals({ ...modals, createPoint: true })}
            hasActiveFilters={pointsTabFilters}
          />
        </div>
        <div>
          {!hideSearch && (
            <StyledSearch
              value={spaceSearch}
              size="small"
              onChange={onSpaceSearch}
              placeholder="Search by space name"
            />
          )}
          <SpacesList
            spaces={spaces}
            panels={TABS}
            loading={loading}
            onCreateSpace={() => setModals({ ...modals, createSpace: true })}
            hasActiveFilters={spaceTabFilters}
          />
        </div>
        <ManageSpace
          metadata={JSON.stringify(space?.metadata || {})}
          name={space?.name || ""}
          tenantId={tenantId || ""}
          spaceId={space?.id || ""}
        />
      </TabsPanel>
      <CreateSpaceModal
        open={modals.createSpace}
        onClose={() => setModals({ ...modals, createSpace: false })}
        tenantId={tenantId}
      />
      <CreatePointModal
        open={modals.createPoint}
        onClose={() => setModals({ ...modals, createPoint: false })}
        tenantId={tenantId}
      />
    </Layout>
  );
}

const StyledHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 36px 0;
`;

const StyledHeadline = styled("h1")`
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  color: black;
`;

const StyledButtons = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

const StyledSearch = styled(Search)`
  width: 320px;
  margin: 40px 0 0 0;
`;
export default SpacesPage;
