import React from "react";
import { styled, SvgIconProps, Box } from "@mui/material";
import { ReactComponent as Home } from "common/assets/overview/home.svg";
import { ReactComponent as HomeIcon } from "common/assets/overview/home-3.svg";

type Variant = "TENANT" | "SPACE" | "POINT";

interface INoDataBox {
  variant: Variant;
  userName?: string;
  onClick: (() => void) | undefined;
  sx?: React.CSSProperties;
}

interface IIcon extends SvgIconProps {
  variant: Variant;
}

function NoDataBox({ variant, onClick, userName = "", sx }: INoDataBox) {
  const CONTENT = {
    TENANT: (
      <Content>
        <StyledTitle>Welcome {userName}!</StyledTitle>
        You don’t have any tenants yet. Start by{" "}
        <StyledAction onClick={onClick}>adding your first tenant.</StyledAction>
      </Content>
    ),
    SPACE: (
      <Wrapper style={sx} className="welcome">
        <StyledIcon2 variant={variant} />
        <Content>
          <Box sx={{ width: 1 }}>
            <Box>
              <StyledLabel>
                Your tenant does’t have any spaces yet. Create the structure for
                your tenant to keep
                <br />
                your points and signals structured and easily accessible.
                <br /> <br />
                Read more about spaces in our
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dimensionfour.io/resources/concepts/space"
                  style={{ textDecoration: "none" }}
                >
                  <StyledAction>&nbsp;documentation .</StyledAction>
                </a>
              </StyledLabel>
              <button
                type="submit"
                className="btn large2 yellow"
                onClick={onClick}
              >
                Create space
              </button>
            </Box>
          </Box>
        </Content>
      </Wrapper>
    ),
    POINT: (
      <Wrapper style={sx} className="welcome">
        <StyledIcon2 variant={variant} />
        <Content>
          <Box sx={{ width: 1 }}>
            <Box>
              <StyledLabel>
                This space does’t have any points yet. Start by adding your
                point, connect it and start
                <br />
                to receive signals.
                <br /> <br />
                Read more about points in our
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dimensionfour.io/resources/concepts/point"
                  style={{ textDecoration: "none" }}
                >
                  <StyledAction>&nbsp;documentation .</StyledAction>
                </a>
              </StyledLabel>
              <button
                type="submit"
                className="btn large2 yellow"
                onClick={onClick}
              >
                Create point
              </button>
            </Box>
          </Box>
        </Content>
      </Wrapper>
    ),
  }[variant];

  return <>{CONTENT}</>;
}

const StyledLabel = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 80%;
  &.iconLabel {
    margin-left: 10px;
  }
  &.body {
    color: var(--dark-blue-color);
    margin-left: 0;
    max-width: 100%;
  }
`;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 32px;
  background: linear-gradient(90deg, #010032 0%, #0c116b 100%);
  border-radius: 20px;
  box-shadow: 3px 4px 25px rgba(30, 26, 44, 0.02);
  border-radius: 20px;
  &.overview {
    cursor: pointer;
  }
`;

const Content = styled("div")`
  padding: 0 0 0 120px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.common.grey[5]};
  width: 100%;
  &.iconLabel {
    margin-left: 32px;
  }
`;

const StyledAction = styled("span")`
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: "pointer !important";
`;

const StyledTitle = styled("h4")`
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 16px 0;
`;

const StyledIcon = styled(Home)<IIcon>`
  flex: none;
  width: 20%;
`;
const StyledIcon2 = styled(HomeIcon)<IIcon>`
  flex: none;
`;

export default NoDataBox;
