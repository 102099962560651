import React, { ReactNode } from "react";
import { Grid, Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const FormPageContainer = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        pb: { xs: 5, md: 0 },
        height: { xs: "100vh", md: "100vh" },
        background: "#010032",
        overflow: "auto",
      }}
    >
      <Grid
        className="image-container"
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {location?.pathname == "/" ? (
          <a
            href="https://dimensionfour.io/"
            rel="noreferrer"
            style={{ width: "100%" }}
          >
            <img className="logo" src="/logo.svg" alt="D4 logo" />
          </a>
        ) : (
          <img className="logo" src="/logo.svg" alt="D4 logo" />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            p: { xs: "32px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              maxWidth: "540px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormPageContainer;
