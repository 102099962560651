import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useVerifyInvitationTenant } from "../../hooks/useVerifyTenantInvitation";

import { ReactComponent as ErrorIcon } from "../../common/assets/error-icon.svg";
import { ReactComponent as SuccessIcon } from "../../common/assets/success-icon.svg";

import "./verifyTenantInvitation.page.css";
import Spinner from "../../common/assets/spinner/spinner";

const VerifyTenantInvitation: React.FC = (props) => {
  const { search } = useLocation();
  const token = search.match(/token=(.*)/)?.[1];
  const [response, setResponse] = useState(0);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  const verifyAccount = useVerifyInvitationTenant({ token });

  useEffect(() => {
    verifyAccount({ token })
      .then((response) => {
        if (response?.errors) {
          setError(response?.errors);
          setResponse(2);
        } else {
          setResponse(1);
          setTimeout(() => {
            navigate("/dashboard", { replace: true });
          }, 5000);
        }
      })
      .catch((e) => {
        setResponse(2);
      });
  }, []);

  useEffect(() => {
    trackPageView({ documentTitle: "Verify tenant's invitation page" });
  }, []);

  if (response === 0) {
    return (
      <div className="verify-container-spinner">
        <Spinner />
      </div>
    );
  } else if (response === 1) {
    return (
      <div className="verify-container">
        <SuccessIcon />
        <h1>Invitation Verified</h1>
        <p>Invitation accepted, redirecting to dashboard.</p>
      </div>
    );
  } else {
    return (
      <div className="verify-container">
        <ErrorIcon />
        <h1>Error</h1>
        <p>Something went wrong.</p>
        <p>{error}</p>
      </div>
    );
  }
};

export default VerifyTenantInvitation;
