import { useState, useEffect, useCallback } from "react";
import { useApolloClient, makeVar, useReactiveVar } from "@apollo/client";
import { useLocation, Location, useParams } from "react-router-dom";
import { ITenant } from "hooks/useTenant";
import { NavbarItem, Space, Tenant } from "./navbar.interface";
import { GET_SPACES } from "../../../common/graphql/space.gql";
import { isNil } from "../../../common/utils";
import { ISpace, ISpaces } from "../../../pages/tenant/tenant.interface";

// temporary solution, we need it to trigger tree re-render after cleaning the session storage
export const shouldUpdateTree = makeVar(false);

const initialMenu: NavbarItem[] = [
  {
    id: "home",
    name: "Home",
    to: "/dashboard",
    parent: null,
    children: [],
    expanded: false,
  },
];

const getMenuItem = (url: string, data: Tenant | Space): NavbarItem => ({
  id: data.id,
  name: data.name,
  to: url,
  parent: null,
  children: [],
  expanded: false,
});

const getNavBar = (spaces: ISpace[]) =>
  spaces?.map((space) => ({
    node: {
      id: space.id,
      name: space.name,
      normalizedName: space.normalizedName,
      parent: !isNil(space.parent) ? { id: space.parent?.id } : space.parent,
    },
  }));

const useNavbar = (tenant: ITenant | undefined) => {
  const [menu, setMenu] = useState([] as NavbarItem[]);
  const [selectedItem, setSelectedItem] = useState("");
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const location = useLocation();
  const { name: tenantId, spaceId } = useParams();
  const needUpdate = useReactiveVar(shouldUpdateTree);

  const getSpaces = useCallback(
    async (tenantId: string) =>
      await client.query<ISpaces>({
        query: GET_SPACES,
        fetchPolicy: "cache-first",
        context: {
          headers: {
            "x-tenant-id": tenantId,
          },
        },
      }),
    [client]
  );

  const selectMenu = async (data?: any) => {
    const navigation = data || menu;

    const getSelectedItem = (
      navItems: NavbarItem[],
      location: Location
    ): any => {
      return navItems.reduce((current, next) => {
        if (!current && location.pathname === "/dashboard")
          return initialMenu[0];
        if (
          !current &&
          (location.pathname === next.to ||
            `/t/${tenantId}/s/${spaceId}` === next.to ||
            `/overview/${tenantId}` === location.pathname)
        ) {
          return next;
        }

        if (!current && next.children.length) {
          return getSelectedItem(next.children, location);
        }
        return current;
      }, null);
    };

    const expandNodes = (node: NavbarItem): any => {
      if (selectedItem === node?.id) {
        if (node?.expanded != undefined) node.expanded = !node.expanded;
      } else {
        if (node?.expanded != undefined) node.expanded = true;
      }

      if (node?.parent === null) {
        return node;
      }

      const expandParents = (node: NavbarItem): any => {
        if (node?.expanded != undefined) node.expanded = true;
        if (node == null || node?.parent === null) return node;
        return expandParents(node?.parent);
      };

      return expandParents(node?.parent);
    };

    const updateTree = (node: NavbarItem, tree: NavbarItem[]) =>
      tree.map((item) => (item.id === node?.id ? node : item));

    // mark path from root to selected menu item
    const node = getSelectedItem(navigation, location);
    const expanded = expandNodes(node);
    const updatedNav = updateTree(expanded, navigation);
    setMenu(updatedNav);
    setSelectedItem(node?.id);
  };

  useEffect(() => {
    const makeNavigationTree = async (tenant: ITenant) => {
      setLoading(true);
      const ROOT = "<ROOT>";
      const nodes = new Map<string, NavbarItem>();
      const parents = new Map<string, NavbarItem[]>();
      const tenantMenuItem = getMenuItem(`/t/${tenant.id}`, tenant);

      const spaceOptions = await getSpaces(tenant.id);
      const spaces = getNavBar(spaceOptions.data.spaces.nodes);

      // step 1. create all space menu items and maps them to corresponding parents
      for (const item of spaces) {
        const space = item.node;
        const spaceMenuItem = getMenuItem(
          `/t/${tenant.id}/s/${space.id}`,
          space
        );
        const parentId = space.parent?.id ?? ROOT;
        nodes.set(space.id, spaceMenuItem);

        parents.has(parentId)
          ? parents.get(parentId)!.push(spaceMenuItem)
          : parents.set(parentId, [spaceMenuItem]);
      }

      // step 2. restore parent-child relationships between menu items
      for (const key of parents.keys()) {
        if (key === ROOT) {
          continue;
        }

        const parent = nodes.get(key)!;
        const children = parents.get(key) ?? [];
        parent.children = children;
        children.forEach((c) => (c.parent = parent));
      }

      // step 3. link root nodes and add all menu items to the menu
      tenantMenuItem.children = parents.get(ROOT) ?? [];
      tenantMenuItem.children.forEach((c) => (c.parent = tenantMenuItem));
      selectMenu([tenantMenuItem]);
      setLoading(false);
    };
    if (tenant) {
      makeNavigationTree(tenant);
    } else {
      selectMenu(initialMenu);
    }
  }, [tenant, needUpdate]);

  // highlight selected item and expand children on route change
  useEffect(() => {
    selectMenu();
  }, [location, client]);

  return { menu: menu, selected: selectedItem, loading };
};

export default useNavbar;
