import React, { useState } from "react";
import AddTokenForm from "./addTokenForm.cmp";
import { ICreateDataState } from "./addToken.interface";
import Modal from "lib/organisms/Modal";
import { Container } from "common/styles/material-ui-styles";
import AddTokenSuccess from "./addTokenSuccess.cmp";

interface IAddTokenProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
}

const defaultValues = {
  name: "",
  signal: "",
  spaceNpoint: "",
  token: "",
};

function AddTokenModal({ open, onClose, tenantId }: IAddTokenProps) {
  const [values, setValues] = useState<ICreateDataState>(defaultValues);
  const success = values.token;

  const onCancel = () => {
    onClose();
    setValues(defaultValues);
  };

  return (
    <Modal
      open={open}
      title={success ? "Token created!" : "Create token"}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        {success ? (
          <AddTokenSuccess
            onCancel={onCancel}
            name={values.name}
            value={values.token}
          />
        ) : (
          <AddTokenForm
            tenantId={tenantId}
            setValues={setValues}
            values={values}
            onCancel={onCancel}
          />
        )}
      </Container>
    </Modal>
  );
}

export default AddTokenModal;
