import { styled } from "@mui/material/styles";
import { LabelVariant } from "@mui/material/styles/createTypography";
import { useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";

interface LabelProps {
  children: React.ReactNode | React.ReactNode[];
  variant: LabelVariant;
  tag?: keyof JSX.IntrinsicElements;
  style?: React.CSSProperties;
}

const Label: React.FC<LabelProps> = ({ children, variant, tag, style }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fontStyle = theme.typography.custom[variant];
  const fontSize = isMobile ? fontStyle.sizeMobile : fontStyle.sizeDesktop;
  const lineHeight = fontStyle.lineHeight;
  const fontWeight = fontStyle.fontWeight;
  const color =
    variant === "SubheadingL"
      ? theme.palette.text.secondary
      : theme.palette.text.primary;

  tag = tag || "div";

  const Component = styled(tag)(({ theme }) => ({
    fontSize,
    fontWeight,
    lineHeight,
    color,
    fontFamily: theme.typography.fontFamily,
    ...style,
  }));

  return <Component>{children}</Component>;
};

export default Label;
