import React, { ChangeEvent } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "lib/atoms/TextField/TextField";

type TSize = "medium" | "small";

interface IProps {
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  size?: TSize;
  className?: string;
  sx?: SxProps<Theme>;
}

function SearchCmp({
  value,
  placeholder,
  onChange,
  className,
  size = "medium",
  sx,
}: IProps) {
  const onUpdate = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = e.target;
    onChange(inputValue);
  };

  return (
    <TextField
      id="outlined-required"
      size={size}
      label="Search"
      placeholder={placeholder}
      value={value}
      className={className}
      onChange={onUpdate}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        sx,
      }}
    />
  );
}

export default SearchCmp;
