import React, { useState } from "react";
import { styled } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Button from "lib/atoms/Button/Button";

interface IAddTokenSuccessProps {
  name: string;
  value: string;
  onCancel: () => void;
}

function AddTokenSuccess({ name, value, onCancel }: IAddTokenSuccessProps) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const onCopyToken = () => {
    navigator.clipboard.writeText(value);
  };

  const handleTooltipClose = () => setShowTooltip(false);

  const handleTooltipOpen = () => {
    setShowTooltip(true);
    onCopyToken();
  };

  return (
    <div>
      <StyledDescription>
        Please copy the token. It&apos;s not retrievable at a later stage.
      </StyledDescription>
      <StyledLabel>Name</StyledLabel>
      <StyledText style={{ margin: "0 0 24px 0" }}>{name}</StyledText>
      <StyledLabel>Value</StyledLabel>
      <StyledText>{value}</StyledText>
      <StyledFooter>
        <StyledButton
          onClick={onCancel}
          style={{ marginRight: "10%" }}
          variant="neutral"
        >
          Close
        </StyledButton>
        <Tooltip
          placement="top-end"
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={showTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Token copied!"
        >
          <div style={{ width: "45%" }}>
            <StyledButton
              onMouseLeave={handleTooltipClose}
              onClick={handleTooltipOpen}
              variant="primary"
              style={{ width: "100%" }}
            >
              Copy token
            </StyledButton>
          </div>
        </Tooltip>
      </StyledFooter>
    </div>
  );
}

const StyledDescription = styled("p")`
  margin: 0 0 24px 0;
`;

const StyledLabel = styled("span")`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.common.grey[4]};
`;

const StyledText = styled("p")`
  line-height: 24px;
`;

const StyledFooter = styled("div")`
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
`;

const StyledButton = styled(Button)`
  width: 45%;
`;

export default AddTokenSuccess;
