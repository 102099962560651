import React from "react";
import { render } from "react-dom";
import MultiRouteWrapper from "Wrapper";
import App from "./cmp/app/app.cmp";
const container = document.getElementById("root") as HTMLDivElement;

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);
