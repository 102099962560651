import * as R from "ramda";
import { IWebhook, TAuth, TField } from "./webhooks.interface";
import { EMPTY_STRING } from "common/utils";

export const EMPTY_ARRAY: [] = [];

export const REQUEST_TYPES = [
  { label: "POST", value: "POST" },
  { label: "GET", value: "GET" },
  { label: "PUT", value: "PUT" },
  { label: "DELETE", value: "DELETE" },
  { label: "PATCH", value: "PATCH" },
];

export const ENTITY_TYPES = [
  { label: "ALL", value: "ALL" },
  { label: "SIGNAL", value: "SIGNAL" },
  { label: "POINT", value: "POINT" },
  { label: "SPACE", value: "SPACE" },
];

export const EVENT_TYPES = [
  { label: "UPDATE", value: "UPDATE" },
  { label: "DELETE", value: "DELETE" },
  { label: "CREATE", value: "CREATE" },
  { label: "ALL", value: "ALL" },
];

export const AUTH_TYPES = [
  { label: "-", value: "NONE" },
  { label: "AuthApiKey", value: "apiKey" },
  { label: "AuthOAuth2", value: "oauth2" },
  { label: "AuthToken", value: "token" },
  { label: "AuthBasic", value: "basic" },
];

export const WEBHOOK_AUTH_TYPES = [
  { label: "NONE", value: "NONE" },
  { label: "BASIC", value: "BASIC" },
  { label: "API_KEY", value: "API_KEY" },
  { label: "TOKEN", value: "TOKEN" },
  { label: "OAUTH_2", value: "OAUTH_2" },
];

export const WEBHOOK_API_KEYS = [
  { label: "HEADER", value: "HEADER" },
  { label: "QUERY_STRING", value: "QUERY_STRING" },
];

const TYPE_FIELD: TField = {
  name: "type",
  label: "Type",
  type: "SELECT",
  placeholder: "Auth type",
  options: WEBHOOK_AUTH_TYPES,
};

const KEY_FIELD: TField = {
  name: "key",
  label: "Key",
  type: "INPUT",
  placeholder: "Add key",
};

const VALUE_FIELD: TField = {
  name: "value",
  label: "Value",
  type: "INPUT",
  placeholder: "Add value",
};

const TOKEN_FIELD: TField = {
  name: "token",
  label: "Token",
  type: "INPUT",
  placeholder: "Add token",
};

const USERNAME_FIELD: TField = {
  name: "username",
  label: "Username",
  type: "INPUT",
  placeholder: "Your name",
};

const PASSWORD_FIELD: TField = {
  name: "password",
  label: "Password",
  type: "INPUT",
  placeholder: "Your password",
};

const ADD_TO_FIELD: TField = {
  name: "addTo",
  label: "AddTo",
  type: "SELECT",
  placeholder: "Choose the API key",
  options: WEBHOOK_API_KEYS,
};

export const AUTH_FIELDS: Record<any, TField[] | []> = {
  apiKey: [KEY_FIELD, VALUE_FIELD, ADD_TO_FIELD],
  oauth2: [TOKEN_FIELD, ADD_TO_FIELD],
  token: [TOKEN_FIELD],
  basic: [USERNAME_FIELD, PASSWORD_FIELD],
  NONE: EMPTY_ARRAY,
};

export const AUTH_FIELDS_KEYS: Record<any, keyof typeof AUTH_FIELDS> = {
  API_KEY: "apiKey",
  OAUTH_2: "oauth2",
  TOKEN: "token",
  BASIC: "basic",
  NONE: "NONE",
};

export const initialState = {
  name: "",
  endpoint: "",
  requestType: "",
  entityType: "ALL",
  eventType: "ALL",
  auth: {
    type: "NONE",
  } as TAuth,
};

export const invalidAuth = R.pipe(R.values, R.any(R.isEmpty));

export const invalidValues = R.ifElse(
  R.propEq("auth", EMPTY_STRING),
  R.pipe(R.dissoc("auth"), R.values, R.any(R.isEmpty)),
  R.always(false)
);

export const normalizeFormData = (
  formState: Omit<IWebhook, "createdAt" | "updatedAt" | "id" | "active">,
  additionalFieldsState: TAuth
) => {
  return R.ifElse(
    R.pathEq(["auth", "type"], "NONE"),
    R.pipe(R.assoc("auth", {})),
    R.assoc("auth", {
      [formState.auth.type]: R.omit(
        ["__typename", "type"],
        additionalFieldsState
      ),
    })
  )(formState);
};
