import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { IResetPasswordWithTokenInput } from "../common/interfaces/resetPassword.interface";

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($input: ResetPasswordInput!) {
    account {
      resetPassword(input: $input) {
        success
      }
    }
  }
`;

export const useResetPasswordWithToken = (
  input: IResetPasswordWithTokenInput
): ((input: IResetPasswordWithTokenInput) => Promise<any>) => {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  return async (input) => resetPassword({ variables: { input } });
};
