import { useEffect, useState } from "react";
import { OperationVariables } from "@apollo/client";
import useTracker from "hooks/useTracker";
import useDebounce from "hooks/useDebounce";
import { IOption } from "common/interfaces/shared.interface";
import { getSessionItem, pipe, setSessionItem } from "common/utils";
import { getOptionValue, allPropsEmpty } from "../../tenant.utils";

type TFilterType = "_EQ" | "_GT";
type TVariables = Partial<OperationVariables>;

function UseFilters<T extends object>(
  initialState: T,
  filteringSettings: Record<keyof T, TFilterType>,
  sessionStorageKey: string,
  variables: TVariables,
  searchName: keyof T,
  onChange: (value?: TVariables | undefined) => void,
  dataNormalizer?: (sessionData: T) => T
) {
  const { trackEvent } = useTracker();
  const [filters, setFilters] = useState(initialState);

  // at the moment we use this search only in point's section
  const searchWithTracking = async (params: object) => {
    await trackEvent({ action: "searchPoint" });
    await onChange(params);
  };

  const { debounced: debouncedOnChange } = useDebounce(searchWithTracking, 500);

  const getFilterType = (key: keyof T) => filteringSettings[key];

  const removeEmptyProps = (state: T) =>
    Object.fromEntries(
      Object.entries(state).filter(([key, value]) => value !== "")
    );

  const combineFilters = (data: T) =>
    Object.entries(data).map(([key, value]) => ({
      [key]: { [getFilterType(key as keyof T)]: getOptionValue(value) },
    }));

  const createParams = (state: T, variables: TVariables) => {
    if (allPropsEmpty(state)) return { variables };

    // optimize it !!! "pointsFilter" should be dynamic parameter, _AND as well
    const filters = pipe(removeEmptyProps, combineFilters)(state);
    return {
      variables: {
        ...variables,
        pointsInput: {
          _AND: filters,
        },
      },
    };
  };

  const onFilterChange = (key: keyof T, value: IOption | string | null) => {
    const newState = { ...filters, [key]: value };
    setFilters(newState);
    if (sessionStorageKey) setSessionItem(sessionStorageKey, newState);
    return newState;
  };

  const onSearch = (value: string) => {
    const params = createParams(onFilterChange(searchName, value), variables);
    // We need it to prevent flickering in filters section when "no results"
    value.length === 0 ? searchWithTracking(params) : debouncedOnChange(params);
  };

  const onFilter = (key: keyof T, value: IOption | string | null) =>
    onChange(createParams(onFilterChange(key, value), variables));

  const onFiltersReset = () => {
    if (sessionStorageKey) sessionStorage.removeItem(sessionStorageKey);
    setFilters(initialState);
    onChange(createParams(initialState, variables));
  };

  // Get filters from the session storage
  useEffect(() => {
    if (sessionStorageKey) {
      const sessionData = getSessionItem(sessionStorageKey);
      if (sessionData) {
        const result = dataNormalizer
          ? dataNormalizer(sessionData)
          : sessionData;
        setFilters(result);
        onChange(createParams(result, variables));
      } else {
        if (sessionStorageKey) sessionStorage.removeItem(sessionStorageKey);
        setFilters(initialState);
        onChange(createParams(initialState, variables)); // fetch without params
      }
    }
  }, [sessionStorageKey]);

  return { filters, onSearch, onFilter, onFiltersReset };
}

export default UseFilters;
