import { useState } from "react";

type Modes = "view" | "edit";

function useFieldMode<T extends readonly string[], U extends string>(
  fieldNames: T
) {
  const initialState: Record<U, Modes> = fieldNames.reduce((obj, fieldName) => {
    return {
      ...obj,
      [fieldName]: "view",
    };
  }, {} as Record<U, Modes>);

  const [fieldMode, setFieldMode] = useState(initialState);

  const setEditMode = (fieldName: string) =>
    setFieldMode({ ...fieldMode, [fieldName]: "edit" });

  const setViewMode = (fieldName: string) =>
    setFieldMode({ ...fieldMode, [fieldName]: "view" });

  return { fieldMode, setEditMode, setViewMode };
}

export default useFieldMode;
