import React from "react";
import { styled } from "@mui/material";
import TextField from "lib/atoms/TextField/TextField";

function Metadata(props) {
  return <StyledMetadata {...props} />;
}

const StyledMetadata = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding: ${({ InputProps }) =>
      InputProps?.readOnly ? "16.5px 14px" : "0"};
    & textarea {
      width: 100%;
      padding: ${({ InputProps }) =>
        InputProps?.readOnly ? "8px" : "24.5px 22px"};
      background: #f7f8fc;
      border-radius: 4px 0 0 4px;
      border: ${({ theme }) => `1px solid ${theme.palette.common.blue[2]}`};
      font-family: "Roboto Mono, Helvetica, Arial", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.palette.common.grey[4]};
    }
  }
`;

export default Metadata;
