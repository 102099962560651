import React, { useState, useContext } from "react";
import { SxProps, Theme, Box, styled } from "@mui/material";
import Label from "../../atoms/Label/Label";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TreeViewContext from "./TreeViewContext";

interface TreeItemProps {
  sx?: SxProps<Theme> | undefined;
  children?: React.ReactNode | React.ReactNode[];
  labelIcon?: boolean;
  labelText: string;
  nodeId: string;
  level?: number;
  to?: string;
  expanded?: boolean;
  path?: string;
}

const TreeItemContainer = styled(Box)<{ level?: number }>(
  ({ theme, level = 0 }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `8px 0 8px ${16 * level}px`,
    cursor: "pointer",
    backgroundColor: level > 1 ? "#EBF1FA" : theme.palette.common.white,

    "&:hover": {
      backgroundColor:
        level === 1
          ? theme.palette.common.blue[2]
          : theme.palette.common.grey[1],
    },

    "&.active": {
      backgroundColor: theme.palette.common.blue[2],
    },
  })
);

const TreeItemIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "4px",
}));

const LabelText = styled("div")(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const TreeItem: React.FC<TreeItemProps> = ({
  sx,
  children,
  labelIcon = true,
  labelText,
  nodeId,
  level,
  to,
  expanded,
  path,
}) => {
  const treeViewContext = useContext(TreeViewContext);
  const [isExpanded, setIsExpanded] = useState(expanded ?? false);

  const handleExpand = () => {
    const newValue = !isExpanded;
    // Prevent collapsing on the first click when switching between spaces
    if (treeViewContext.selected !== nodeId && treeViewContext.selectItem) {
      treeViewContext.selectItem(nodeId);
      setIsExpanded(true);
    } else {
      setIsExpanded(newValue);
    }

    if (treeViewContext.handleMenuClick) {
      treeViewContext.handleMenuClick(to);
    }
  };

  return (
    <div>
      <TreeItemContainer
        sx={sx}
        level={level}
        onClick={handleExpand}
        className={treeViewContext.selected === nodeId ? "active" : ""}
      >
        <Label
          variant="BodyM"
          style={{
            display: "flex",
            alignItems: "center",
            width: "82%",
            userSelect: "none",
            fontWeight: treeViewContext.selected === nodeId ? "500" : "400",
          }}
        >
          {labelIcon && (
            <TreeItemIcon>
              <FolderOutlinedIcon />
            </TreeItemIcon>
          )}
          <LabelText>{labelText}</LabelText>
        </Label>

        {children && isExpanded && (
          <KeyboardArrowUpIcon
            sx={{
              paddingRight: "8px",
              width: "1.3em",
              height: "1.3em",
              color: "#010032",
            }}
          />
        )}

        {children && !isExpanded && (
          <KeyboardArrowRightIcon
            sx={{
              paddingRight: "8px",
              width: "1.3em",
              height: "1.3em",
              color: "#010032",
            }}
          />
        )}
      </TreeItemContainer>
      {isExpanded && children}
    </div>
  );
};

export default TreeItem;
