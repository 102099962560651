import { gql } from "@apollo/client";
import { LoginParamsInput } from "../common/interfaces/login.interface";
import { useMutation } from "@apollo/client";

export const LOGIN = gql`
  mutation LOGIN($input: LoginInput!) {
    account {
      login(input: $input) {
        user {
          id
          firstname
          lastname
          emails {
            address
          }
        }
      }
    }
  }
`;

export const useLogin = (
  input: LoginParamsInput
): ((input: LoginParamsInput) => Promise<any>) => {
  const [login] = useMutation(LOGIN);
  return async (input) => login({ variables: { input } });
};
