import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import MaterialIconButton from "lib/atoms/Button/MaterialIconButton";
import { StyledTableRow } from "common/styles/material-ui-styles";
import {
  StyledHeader,
  StyledHeadline,
  StyledTable,
  StyledTableHead,
  StyledContainer,
} from "./manageTenant.cmp";
import useToggle from "hooks/useToggle";
import { convertISO } from "../../tenant.utils";
import UpdateTenantModal from "../updateTenantModal/updateTenantModal.cmp";
import IconButton from "lib/atoms/Button/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Paragraph, theme } from "@gilbarbara/components";

type TTenantInfo = {
  id: string | undefined;
  name: string | undefined;
  createdAt: string | undefined;
  updatedAt: string | undefined;
};

interface IProps {
  data: TTenantInfo;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "500px",
    width: "100%",
    background: "#010032",
    boxShadow: "3px 4px 25px rgba(30, 26, 44, 0.02)",
    borderRadius: "8px",
    padding: 24,
    transform: "translate(-23px, 0) !important",
    cursor: "pointer !important",
  },
  "& .MuiTooltip-arrow": {
    color: "#010032",
    width: "7em",
    height: "2em",
    transform: "translate(16px, -12px) !important",
  },
  [`& .${tooltipClasses.popper}`]: {
    transform: "translate(394px, 442px) !important",
    cursor: "pointer !important",
  },
});
function TenantInfo({ data }: IProps) {
  const [openTool, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const { open, toggle } = useToggle();
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeadline>
          Tenant &nbsp;
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomWidthTooltip
                open={openTool}
                title={
                  <>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "25px",
                      }}
                    >
                      Tenant
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        marginTop: 8,
                      }}
                    >
                      Dimension Four is a multi-tenant SaaS service. Each tenant
                      has its own set of spaces, points, signals, etc. A
                      business typically has one tenant, but larger
                      organizations can have many, e.g. one for each region.
                      <br />
                      <br />
                      Learn more about tenants in our&nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://dimensionfour.io/resources/concepts/tenant"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "#ffa654",
                          cursor: "pointer",
                        }}
                      >
                        Documentation
                      </a>
                    </Paragraph>
                  </>
                }
                placement="bottom-start"
                arrow
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  variant="neutral"
                  style={{ border: "none" }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#3237B8",
                      width: "18px !important",
                      height: "18px !important",
                    }}
                  />
                </IconButton>
              </CustomWidthTooltip>
            </div>
          </ClickAwayListener>
        </StyledHeadline>
      </StyledHeader>
      <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>{""}</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          <StyledTableRow key={`${data?.createdAt}${data?.updatedAt}`}>
            <TableCell component="th" scope="row">
              {data?.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {data?.id}
            </TableCell>
            <TableCell component="th" scope="row">
              {convertISO(data?.createdAt)}
            </TableCell>
            <TableCell component="th" scope="row">
              {convertISO(data?.updatedAt)}
            </TableCell>
            <TableCell
              sx={{ textAlign: "right", padding: "12px 42px 12px 12px" }}
            >
              <MaterialIconButton size="small" color="primary" onClick={toggle}>
                <EditIcon />
              </MaterialIconButton>
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </StyledTable>
      <UpdateTenantModal open={open} tenantId={data?.id} onClose={toggle} />
    </StyledContainer>
  );
}

export default TenantInfo;
