import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import { UPDATE_POINT } from "common/graphql/point.gql";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Slider, MenuItem, TextField, ClickAwayListener } from "@mui/material";
import IconButton from "lib/atoms/Button/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CREATE_SIGNAL } from "./updateSignals.gql";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import Modal from "lib/organisms/Modal/Modal";
import { Container } from "common/styles/material-ui-styles";
import { StyledHeadline } from "../manageTenant/manageTenant.cmp";
import { Paragraph } from "@gilbarbara/components";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface ICreateSpaceProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
  pointId: string | undefined;
  metaData: IState;
}

interface IState {
  id: string;
  name: string;
  externalId: string;
  spaceId: string;
  metadata: string;
  createdAt: string;
  lastActive: string;
  signalTypes?: string[];
}
const units = [
  {
    value: "",
    label: "",
  },
  {
    value: "UNKNOWN",
    label: "UNKNOWN",
  },
  {
    value: "PERCENTS",
    label: "PERCENTS",
  },
  {
    value: "DEGREES",
    label: "DEGREES",
  },
  {
    value: "GENERIC",
    label: "GENERIC",
  },
  {
    value: "NTU",
    label: "NTU",
  },
  {
    value: "LUX",
    label: "LUX",
  },
  {
    value: "MILLIVOLTS",
    label: "MILLIVOLTS",
  },
  {
    value: "VOLTS",
    label: "VOLTS",
  },
  {
    value: "MILLIAMPERES",
    label: "MILLIAMPERES",
  },
  {
    value: "AMPERES",
    label: "AMPERES",
  },
  {
    value: "PASCAL",
    label: "PASCAL",
  },
  {
    value: "GRAMS_PER_CUBIC_METER",
    label: "GRAMS_PER_CUBIC_METER",
  },
  {
    value: "KILOGRAMS_PER_HOUR",
    label: "KILOGRAMS_PER_HOUR",
  },
  {
    value: "LITERS_PER_HOUR",
    label: "LITERS_PER_HOUR",
  },
  {
    value: "LITERS_PER_SECOND",
    label: "LITERS_PER_SECOND",
  },
  {
    value: "CUBIC_METERS_PER_HOUR",
    label: "CUBIC_METERS_PER_HOUR",
  },
  {
    value: "CUBIC_METERS_PER_SECOND",
    label: "CUBIC_METERS_PER_SECOND",
  },
  {
    value: "CUBIC_METERS",
    label: "CUBIC_METERS",
  },
  {
    value: "LITERS",
    label: "LITERS",
  },
  {
    value: "KILOGRAMS",
    label: "KILOGRAMS",
  },
  {
    value: "GRAMS",
    label: "GRAMS",
  },
  {
    value: "BITS_PER_SECOND",
    label: "BITS_PER_SECOND",
  },
  {
    value: "BITS",
    label: "BITS",
  },
  {
    value: "MILLISECONDS",
    label: "MILLISECONDS",
  },
  {
    value: "SECONDS",
    label: "SECONDS",
  },
  {
    value: "MILLIMETERS",
    label: "MILLIMETERS",
  },

  {
    value: "CENTIMETERS",
    label: "CENTIMETERS",
  },
  {
    value: "KILOMETERS",
    label: "KILOMETERS",
  },

  {
    value: "METERS",
    label: "METERS",
  },
  {
    value: "METERS_PER_SECOND_SQUARED",
    label: "METERS_PER_SECOND_SQUARED",
  },

  {
    value: "KILOMETERS_PER_HOUR",
    label: "KILOMETERS_PER_HOUR",
  },
  {
    value: "METERS_PER_SECOND",
    label: "METERS_PER_SECOND",
  },
  {
    value: "LONGITUDE_DEGREES",
    label: "LONGITUDE_DEGREES",
  },
  {
    value: "BEATS_PER_MINUTE",
    label: "BEATS_PER_MINUTE",
  },
  {
    value: "DECIBELS",
    label: "DECIBELS",
  },
  {
    value: "FAHRENHEIT_DEGREES",
    label: "FAHRENHEIT_DEGREES",
  },
  {
    value: "KELVINS",
    label: "KELVINS",
  },
];

function CreateSignalsModal({
  open,
  onClose,
  tenantId,
  pointId,
  metaData,
}: ICreateSpaceProps) {
  const [openTooltip, setOpen] = React.useState(false);
  const [success, isSuccess] = React.useState(false);

  const [metaObject, seMetaObject] = React.useState(
    JSON.parse(metaData.metadata)
  );
  useEffect(() => {
    seMetaObject(JSON.parse(metaData.metadata));
  }, [metaData]);
  const [isInterval, setIsInterval] = React.useState<any | null>(
    metaObject?.datagenerator?.isInterval
      ? metaObject?.datagenerator?.isInterval
      : false
  );
  const [intervalValueManual, setIntervalValueManual] = React.useState<
    any | null
  >(
    metaObject?.datagenerator?.id == `dg-a-${pointId}`
      ? 1
      : metaObject?.interval
      ? metaObject?.interval
      : 1
  );
  const [formFieldsManual, setFormFieldsManual] = useState<any>(
    metaObject?.datagenerator?.id !== `dg-m-${pointId}`
      ? [{ type: "", value: 0, unit: "-", metadata: { isDataGenerator: true } }]
      : metaObject?.datagenerator?.data
      ? metaObject?.datagenerator?.data
      : [{ type: "", value: 0, unit: "-", metadata: { isDataGenerator: true } }]
  );

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const context = {
    headers: {
      "x-tenant-id": tenantId,
    },
  };

  const [createSignal] = useMutation(CREATE_SIGNAL, {
    context,
  });
  const [updatePoint] = useMutation(UPDATE_POINT, {
    context,
  });
  const handleFormChangeManual = (event: any, index: any) => {
    const data = [...formFieldsManual];
    data[index][event.target.name] = event.target.value.toString();
    setFormFieldsManual(data);
  };

  const submitManual = async (e: any) => {
    e.preventDefault();
    const a = Object.assign({}, metaObject);
    a.datagenerator = {
      id: `dg-m-${pointId}`,
      isInterval: isInterval,
      interval: intervalValueManual,
      data: formFieldsManual,
    };
    await updatePoint({
      variables: {
        input: {
          id: pointId,
          point: {
            metadata: a,
          },
        },
      },
    });
    const signal = await createSignal({
      variables: {
        input: {
          pointId: pointId,
          signals: formFieldsManual,
        },
      },
    });
    isSuccess(signal?.data ? true : false);
    setTimeout(function () {
      isSuccess(false);
    }, 3000);
  };
  const addFields = () => {
    const object = {
      type: "",
      value: "0",
      unit: "-",
      metadata: { isDataGenerator: true },
    };
    setFormFieldsManual([...formFieldsManual, object]);
  };

  const removeFields = (index: any) => {
    const data = [...formFieldsManual];
    data.splice(index, 1);
    setFormFieldsManual(data);
  };
  function valuetext(value: number) {
    return `${value}`;
  }

  const closeModal = async (e: any) => {
    e.preventDefault();
    setIsInterval(false);
    const a = Object.assign({}, metaObject);
    a.datagenerator = {
      id: `dg-m-${pointId}`,
      isInterval: false,
      interval: intervalValueManual,
      data: formFieldsManual,
    };
    await updatePoint({
      variables: {
        input: {
          id: pointId,
          point: {
            metadata: a,
          },
        },
      },
    });
    onClose();
  };

  return (
    <Modal open={open} style={{ width: "695px" }}>
      <Container>
        <p>
          <span
            onClick={closeModal}
            style={{
              fontFamily: "Poppins",
              fontWeight: 900,
              fontSize: "16px",
              color: "#010032",
              cursor: "pointer",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            X
          </span>
        </p>
        <Paragraph
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "43px",
            color: "#010032",
            textAlign: "center",
          }}
        >
          Data generator
        </Paragraph>
        <br />
        <StyledHeadline
          style={{
            color: " #0C116B",
            fontWeight: "500",
            borderBottom: "3px solid #0C116B",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          Generate Data
        </StyledHeadline>
        <div style={{ margin: "16px 0" }}>
          <StyledHeadline>
            Intervals &nbsp;
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <CustomWidthTooltip
                  open={openTooltip}
                  title={
                    <>
                      <Paragraph
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "25px",
                        }}
                      >
                        Intervals
                      </Paragraph>
                      <Paragraph
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "25px",
                          marginTop: 8,
                        }}
                      >
                        Generated data will be sent to your point at set
                        intervals.
                      </Paragraph>
                    </>
                  }
                  placement="bottom-start"
                  arrow
                >
                  <IconButton
                    onClick={handleTooltipOpen}
                    variant="neutral"
                    style={{ border: "none" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#3237B8",
                        width: "18px !important",
                        height: "18px !important",
                      }}
                    />
                  </IconButton>
                </CustomWidthTooltip>
              </div>
            </ClickAwayListener>
          </StyledHeadline>
          <TextField
            id="outlined-number"
            label="Time i sec"
            type="number"
            disabled={!isInterval}
            defaultValue={1}
            onChange={(e) => {
              setIntervalValueManual(e.currentTarget.value);
            }}
            value={intervalValueManual}
            style={{ margin: "16px 0" }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: 1 } }}
          />
          <FormControlLabel
            style={{ margin: "16px 32px" }}
            control={
              <Switch
                checked={isInterval}
                onChange={() => {
                  setIsInterval(!isInterval);
                }}
                style={{ accentColor: "#3237B8" }}
              />
            }
            label={!isInterval ? "Manual" : "Automatic"}
          />
          <StyledHeadline>Data </StyledHeadline>{" "}
          <form onSubmit={submitManual}>
            {formFieldsManual?.map((form: any, index: any) => {
              return (
                <div key={index} style={{ display: "flex" }}>
                  <TextField
                    id="outlined-number"
                    label="Type"
                    name="type"
                    required
                    placeholder="Temperature"
                    type="text"
                    onChange={(event) => handleFormChangeManual(event, index)}
                    value={form.type}
                    style={{ margin: "16px 0" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Slider
                    aria-label="Always visible"
                    name="value"
                    getAriaValueText={valuetext}
                    step={10}
                    onChange={(event) => handleFormChangeManual(event, index)}
                    value={form.value}
                    max={300}
                    style={{ margin: "16px 0 16px 32px" }}
                    sx={{
                      maxWidth: "216px",
                      height: "auto",
                      margin: "13px 15px",
                      "& .MuiSlider-rail": {
                        backgroundColor: "#0C116B",
                        borderRadius: "3px",
                        padding: "4px",
                        border: "1px solid #797992",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "#0C116B",
                        border: "none",
                      },
                      "& .MuiSlider-thumb": {
                        backgroundColor: "#3237B8",
                        borderRadius: "50% 50% 50% 50% / 60% 60% 60% 60%",
                        padding: "8px 12px",
                        height: "40px",
                        width: "48px",
                        border: "1px solid #D5D4DD",
                      },
                      "& .MuiSlider-valueLabelOpen": {
                        backgroundColor: "transparent",
                        transform: " translateY(20%) scale(1)!important",
                        padding: "8px 12px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "18px",
                      },
                      "& .MuiSlider-valueLabelLabel": {
                        color: "#FFFFFF",
                      },
                    }}
                    valueLabelDisplay="on"
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="unit"
                    label="Unit"
                    onChange={(event) => handleFormChangeManual(event, index)}
                    value={form.unit}
                    style={{ width: "84px", margin: "13px 0 0 32px" }}
                  >
                    {units.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <button onClick={() => removeFields(index)}>
                    <p>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: 900,
                          fontSize: "16px",
                          color: "#010032",
                          cursor: "pointer",
                          display: "flex",
                          marginLeft: "16px",
                          justifyContent: "flex-end",
                        }}
                      >
                        X
                      </span>
                    </p>
                  </button>
                </div>
              );
            })}{" "}
            <button
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "26px",
                color: "#3237B8",
              }}
              onClick={addFields}
            >
              + Add new signal type
            </button>
            {!isInterval ? (
              <StyledLabel className="body">
                Data will be generated and sent to your point with your chosen
                values, every time you click the button below
              </StyledLabel>
            ) : (
              <StyledLabel className="body">
                Data will be generated and sent to your point with your chosen
                values, every {intervalValueManual} sek
              </StyledLabel>
            )}
            <button
              type="submit"
              className="btn super-large yellow auth-submit-button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                maxWidth: "371px",
                background: "#3237B8",
                color: "#FFFF",
              }}
            >
              Send data
            </button>
          </form>
        </div>
        {!!success && (
          <StyledNotification severity="success">
            Data Generated Succesfully
          </StyledNotification>
        )}
      </Container>
    </Modal>
  );
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "500px",
    width: "100%",
    background: "#010032",
    boxShadow: "3px 4px 25px rgba(30, 26, 44, 0.02)",
    borderRadius: "8px",
    padding: 24,
    transform: "translate(-23px, 0) !important",
    cursor: "pointer !important",
  },
  "& .MuiTooltip-arrow": {
    color: "#010032",
    width: "7em",
    height: "2em",
    transform: "translate(16px, -12px) !important",
  },
  [`& .${tooltipClasses.popper}`]: {
    transform: "translate(394px, 442px) !important",
    cursor: "pointer !important",
  },
});
const StyledLabel = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 100%;
  &.iconLabel {
    margin-left: 10px;
  }

  &.iconLabel2 {
    margin-right: 12px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    svg {
      align-items: center;
    }
  }
  &.iconLabel3 {
    margin-right: 12px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    color: #010032;
    justify-content: space-between;
  }
  &.body {
    color: var(--dark-blue-color);
    display: flex;
    margin: 64px 0 16px;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;
const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;
export default CreateSignalsModal;
