import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material";
import useTracker from "hooks/useTracker";
import Modal from "lib/organisms/Modal";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import TextField from "lib/atoms/TextField/TextField";
import { Container } from "common/styles/material-ui-styles";
import Spinner from "common/assets/spinner/spinner";
import { GET_TENANT, ADD_TENANT_MEMBER } from "common/graphql/tenant.gql";
import { ROLES } from "../../tenant.utils";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";

interface IAddTenantMemberProps {
  open: boolean;
  action: Dispatch<SetStateAction<boolean>>;
  tenantId: string | undefined;
}

interface IAddTenantMember {
  email: string;
  role: string;
}

const defaultValues = {
  email: "",
  role: "",
};

function AddTenantMemberModal({
  open,
  action,
  tenantId,
}: IAddTenantMemberProps) {
  const { trackEvent } = useTracker();
  const {
    formState,
    getValues,
    control,
    handleSubmit,
    clearErrors,
    reset: resetForm,
  } = useForm<IAddTenantMember>({
    delayError: 2000,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  const [addMember, { error, loading, reset: resetMember }] = useMutation(
    ADD_TENANT_MEMBER,
    {
      context: {
        headers: {
          "x-tenant-id": tenantId,
        },
      },
      refetchQueries: [GET_TENANT],
    }
  );

  const onSubmit = async () => {
    trackEvent({ action: "clickedCreateMember" });
    const values = getValues();
    await addMember({
      variables: { input: values },
    });
    action(false);
  };

  useEffect(() => {
    resetForm();
    resetMember();
  }, [open, resetForm, resetMember]);

  return (
    <Modal
      open={open}
      title="Add member"
      footer={{
        okText: "Add member",
        onCancel: () => action(false),
        okButtonProps: {
          type: "submit",
          form: "addTenantMember",
          disabled: !formState.isDirty || !formState.isValid,
        },
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <Description>
          A confirmation email to the new member will be sent to the email you
          provide.
        </Description>
        <form id="addTenantMember" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="outlined-required"
                label="Email*"
                placeholder="Email"
                onChange={onChange}
                onFocus={() => clearErrors("email")}
                value={value}
                error={!!error}
                helperText={error?.message}
                sx={{
                  marginTop: "12px",
                }}
                style={{ width: "100%" }}
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            rules={{ required: "Role is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DefaultDropdown
                variant="outlined"
                label="Role*"
                placeholder="Role"
                onChange={onChange}
                value={value}
                error={!!error}
                helperText={error?.message}
                sx={{
                  marginTop: "12px",
                }}
                style={{ width: "100%" }}
                options={ROLES}
                select
              />
            )}
          />
        </form>
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const Description = styled("p")`
  margin: 0 0 32px 0;
  line-height: 24px;
`;

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default AddTenantMemberModal;
