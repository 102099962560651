import { styled } from "@mui/system";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { ReactComponent as SuccessIcon } from "../../common/assets/success-icon.svg";
import "./successSignUp.page.css";

const VerifyOuter = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

const VerifyMessage = styled("p")({
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "19px",
  textAlign: "center",
});

const VerifyTitleMessage = styled("h1")({
  fontSize: "32px",
  fontStyle: "normal",
  lineHeight: "38px",
  letterSpacing: "0em",
  textAlign: "center",
  marginBottom: "16px",
  marginTop: "16px",
  fontWeight: "700",
});

const SuccessSignUp: React.FC = () => {
  const navigate = useNavigate();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 5000);
  });

  useEffect(() => {
    trackPageView({ documentTitle: "Successful SignUp page" });
  }, []);

  return (
    <VerifyOuter>
      <SuccessIcon />
      <VerifyTitleMessage>Registration Successful</VerifyTitleMessage>
      <VerifyMessage>
        An email with link has been sent. Please check your inbox.
      </VerifyMessage>
    </VerifyOuter>
  );
};

export default SuccessSignUp;
