import React, { useMemo, useState } from "react";
import { styled } from "@mui/material";
import ExpandableButton from "lib/atoms/Button/ExpandableButton";

const MAX_LENGTH = 3;
const showExpandButton = (data: any) => data.length > MAX_LENGTH;

interface ITableCollapse<T> {
  data: T[];
  expandText: string;
  collapseText: string;
}

function UseTableCollapse<T>({
  data,
  expandText,
  collapseText,
}: ITableCollapse<T>) {
  const [showMore, setShowMore] = useState(false);
  const tableData = !showMore ? data?.slice(0, MAX_LENGTH) : data;

  const expandButton = useMemo(
    () =>
      showExpandButton(data) ? (
        <StyledExpandableButton
          variant="primary"
          onExpanded={() => setShowMore((prevState) => !prevState)}
        >
          {showMore ? collapseText : expandText}
        </StyledExpandableButton>
      ) : null,
    [collapseText, data, expandText, showMore]
  );

  return { tableData, expandButton };
}

const StyledExpandableButton = styled(ExpandableButton)`
  margin: 16px;
`;

export default UseTableCollapse;
