import React, { useContext, useMemo } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Box, styled, useTheme } from "@mui/material";
import useTracker from "hooks/useTracker";
import { AuthContext, initialUserState } from "context/auth.context";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import IconButton from "../../atoms/Button/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type TMenuItem = {
  id: string;
  label: string;
  to: string;
};

interface INavItem {
  active?: boolean;
}

const MENU = [
  { id: "settings", label: "Member profile", to: "/profile/settings" },
  { id: "tenant-access", label: "Tenant access", to: "/profile/tenant-access" },
];

function ProfileNavbar() {
  const { trackEvent } = useTracker();
  const client = useApolloClient();
  const theme = useTheme();
  const { section } = useParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const APP_VERSION = "0.1.0";

  const logout = async () => {
    trackEvent({ category: "profile", action: "clickedLogout" });
    authContext?.handlerLogoutUser(initialUserState);
    navigate("/");
    await client.resetStore();
  };

  const onNavChange = (item: TMenuItem) => {
    const action =
      item.id === "settings"
        ? "clickedManageProfile"
        : item.id === "tenant-access"
        ? "clickedManageTenants"
        : "";
    trackEvent({ category: "profile", action });
  };

  const navigation = useMemo(
    () =>
      MENU.map((item) => (
        <NavLink
          style={{ textDecoration: "none" }}
          to={item.to}
          key={item.id}
          onClick={() => onNavChange(item)}
        >
          <StyledNavItem active={section === item.id}>
            {item.label}
          </StyledNavItem>
        </NavLink>
      )),
    [section]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        minHeight: "100vh",
        minWidth: "250px",
        border: `1px solid ${theme.palette.common.blue[1]}`,
        backgroundColor: theme.palette.common.white,
      }}
    >
      <div>
        <Box sx={{ pt: 3, pb: 6, pl: 3 }}>
          <NavLink to="/dashboard">
            <LogoIcon />
          </NavLink>
        </Box>
        <Box
          sx={{
            padding: "24px 24px 24px 16px",
            borderTop: `1px solid ${theme.palette.common.blue[1]}`,
          }}
        >
          <NavLink style={{ textDecoration: "none" }} to="/dashboard">
            <IconButton variant="neutral" size="small">
              <ArrowBackIcon />
              Back to dashboard
            </IconButton>
          </NavLink>
        </Box>
        <Box>{navigation}</Box>
      </div>

      <div>
        <Box
          sx={{
            padding: "24px 24px 24px 16px",
            borderTop: `1px solid ${theme.palette.common.blue[1]}`,
          }}
        >
          <IconButton variant="primary" size="small" onClick={logout}>
            <ArrowForwardIcon />
            Log out
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: "24px 24px 24px 16px",
            borderTop: `1px solid ${theme.palette.common.blue[1]}`,
          }}
        >
          <AppVersion style={{}}>Version {APP_VERSION}</AppVersion>
        </Box>
      </div>
    </Box>
  );
}

const AppVersion = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "12px",
  color: theme.palette.common.grey[4],
}));

const StyledNavItem = styled("div")<INavItem>`
  margin: 0 0 16px 0;
  padding: 12px 16px 12px 32px;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  color: ${({ theme }) => theme.palette.common.grey[5]};
  background: ${({ theme, active }) =>
    active ? theme.palette.common.blue[2] : null};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.palette.common.blue[2] : theme.palette.common.blue[1]};
  }
`;

export default ProfileNavbar;
