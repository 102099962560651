import { gql } from "@apollo/client";

export const GET_SPACES = gql`
  query GET_SPACES($spaceInput: SpaceFilterInput) {
    spaces(
      where: $spaceInput
      paginate: { last: 100 }
      sort: { field: "id", order: ASC }
    ) {
      nodes {
        name
        normalizedName
        createdAt
        updatedAt
        id
        parent {
          id
          name
        }
      }
    }
  }
`;

export const GET_SPACE = gql`
  query GET_SPACE(
    $spaceInput: SpaceFilterInput!
    $childrenInput: SpaceFilterInput
    $pointsInput: PointFilterInput
  ) {
    space(input: $spaceInput) {
      name
      metadata
      id
      children(where: $childrenInput) {
        nodes {
          name
          normalizedName
          createdAt
          updatedAt
          id
        }
      }
      points(where: $pointsInput) {
        nodes {
          name
          externalId
          id
          createdAt
          lastActive
          space {
            name
            id
          }
        }
      }
    }
  }
`;
