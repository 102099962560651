import { gql } from "@apollo/client";

export const CREATE_POINT = gql`
  mutation CREATE_POINT($input: CreatePointInput!) {
    point {
      create(input: $input) {
        __typename
        createdAt
        name
        externalId
        id
        lastActive
        space {
          __typename
          name
          id
        }
      }
    }
  }
`;
