import React from "react";
import { Link, useParams } from "react-router-dom";
import { styled } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { ISpace } from "../../tenant.interface";
import useTracker from "hooks/useTracker";
import Card from "lib/molecules/Card/Card";
import CardTitle from "lib/molecules/Card/CardTitle";
import CardMedia from "lib/molecules/Card/CardMedia";
import Spinner from "common/assets/spinner/spinner";
import NoDataBox from "../noDataBox/noDataBox";

type Panels = readonly [string?, string?, string?, string?, string?, string?];

interface ISpaceListProps {
  spaces: ISpace[] | undefined;
  panels: Panels;
  loading: boolean;
  onCreateSpace?: () => void;
  hasActiveFilters: boolean;
}

const isSpace = (panel: string | undefined) => panel === "SPACES";
const getIndex = (index: number) => (index > 0 ? index : 0);
const getDefaultTab = (panels: Panels) => getIndex(panels?.findIndex(isSpace));

function SpacesList({
  spaces,
  panels,
  loading,
  onCreateSpace,
  hasActiveFilters,
}: ISpaceListProps) {
  const { trackEvent } = useTracker();
  const { name } = useParams();
  const defaultTab = getDefaultTab(panels);
  const hasData = spaces?.length;

  if (loading) return <Spinner />;

  if (!hasData && !hasActiveFilters && !loading) {
    return (
      <StyledLayout>
        <NoDataBox
          sx={{ margin: "48px 0" }}
          variant="SPACE"
          onClick={onCreateSpace}
        />
      </StyledLayout>
    );
  }

  if (hasData) {
    return (
      <StyledLayout>
        <StyledCards>
          {spaces.map((space) => (
            <StyledLink
              onClick={() => trackEvent({ action: "clickedOpenSpace" })}
              to={`/t/${name}/s/${space.id}`}
              key={space.id}
              state={{ defaultTab }}
            >
              <StyledCard variant="space">
                <CardMedia>
                  <FolderOpenIcon />
                </CardMedia>
                <CardTitle>{space.name}</CardTitle>
              </StyledCard>
            </StyledLink>
          ))}
        </StyledCards>
      </StyledLayout>
    );
  }

  return null;
}

const StyledLayout = styled("div")`
  margin: 28px 0;
`;

const StyledCards = styled("div")`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

const StyledCard = styled(Card)`
  max-width: unset;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  margin: 0 32px 24px 0;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export default SpacesList;
