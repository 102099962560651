import * as React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";

interface ThemeProps {
  children: React.ReactNode | React.ReactNode[];
}

const theme = createTheme({
  spacing: [0, 4, 8, 16, 24, 32, 48, 64],
  palette: {
    common: {
      purple: {
        1: "#D4C7E9",
        2: "#A98ED3",
        3: "#531DA7",
        4: "#3E167D",
        5: "#270E50",
      },
      green: {
        1: "#DEF4F1",
        2: "#BCEAE3",
        3: "#7AD4C7",
        4: "#5C9F95",
        5: "#3D6A64",
      },
      grey: {
        1: "#F7F8FC",
        2: "#D5D4DD",
        3: "#797992",
        4: "#403F63",
        5: "#010032",
      },
      blue: {
        1: "#EBF1FA",
        2: "#D7E2FB",
        3: "#3237B8",
        4: "#0C116B",
        5: "#0C116B",
      },
    },
    text: {
      primary: "#000000",
      secondary: "#6C6C6C",
    },
    primary: {
      main: "#531DA7",
      light: "#F0F0F0",
      dark: "#270E50",
    },
    secondary: {
      main: "#7AD4C7",
      light: "#DEF4F1",
      dark: "#3D6A64",
    },
    grey: {
      50: "#FAFAFA",
      100: "#FAFAFA",
      200: "#EDF2F7",
      300: "#EDF2F7",
      400: "#CBD5E0",
      500: "#CBD5E0",
      600: "#4A5568",
      700: "#4A5568",
      800: "#4A5568",
      900: "#1E1A2C",
      A100: "#FAFAFA",
      A200: "#EDF2F7",
      A400: "#CBD5E0",
      A700: "#4A5568",
    },

    success: {
      main: "#488C6E",
      light: "#F0FAF5",
      dark: "#488C6E",
    },
    warning: {
      main: "#F7936F",
      light: "#FEF4F1",
      dark: "#F7936F",
    },
    error: {
      main: "#F16063",
      light: "#FEEFEF",
      dark: "#F16063",
    },
    info: {
      main: "#15B1D0",
      light: "#E8F7FA",
      dark: "#15B1D0",
    },
  },
  typography: {
    fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif,Sora",
    custom: {
      HeadingXL: {
        tag: "h1",
        sizeDesktop: "64px",
        sizeMobile: "48px",
        lineHeight: "100%",
        fontWeight: 500,
      },
      HeadingL: {
        tag: "h2",
        sizeDesktop: "36px",
        sizeMobile: "32px",
        lineHeight: "100%",
        fontWeight: 500,
      },
      HeadingM: {
        tag: "h3",
        sizeDesktop: "24px",
        sizeMobile: "24px",
        lineHeight: "120%",
        fontWeight: 500,
      },
      HeadingS: {
        tag: "h4",
        sizeDesktop: "20px",
        sizeMobile: "20px",
        lineHeight: "125%",
        fontWeight: 500,
      },
      HeadingXS: {
        tag: "h5",
        sizeDesktop: "16px",
        sizeMobile: "16px",
        lineHeight: "125%",
        fontWeight: 500,
      },
      SubheadingL: {
        tag: "h6",
        sizeDesktop: "24px",
        sizeMobile: "24px",
        lineHeight: "120%",
        fontWeight: 400,
      },
      BodyL: {
        tag: "div",
        sizeDesktop: "18px",
        sizeMobile: "18px",
        lineHeight: "140%",
        fontWeight: 400,
      },
      BodyM: {
        tag: "div",
        sizeDesktop: "16px",
        sizeMobile: "16px",
        lineHeight: "150%",
        fontWeight: 400,
      },
      BodyS: {
        tag: "div",
        sizeDesktop: "14px",
        sizeMobile: "14px",
        lineHeight: "150%",
        fontWeight: 400,
      },
    },
  },
});

const ThemeProvider: React.FC<ThemeProps> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
