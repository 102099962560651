import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { UPDATE_TENANT } from "./updateTenant.gql";
import Modal from "lib/organisms/Modal/Modal";
import { shouldUpdateTree } from "lib/organisms/Navbar/useNavbar";
import TextField from "lib/atoms/TextField/TextField";
import { Container } from "common/styles/material-ui-styles";

interface IProps {
  open: boolean;
  tenantId: string | undefined;
  onClose: () => void;
}

function UpdateTenantModal({ open, tenantId, onClose }: IProps) {
  const [name, setName] = useState("");
  const [updateTenant] = useMutation(UPDATE_TENANT, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    refetchQueries: [GET_TENANT],
  });

  const onCancel = () => {
    onClose();
    setName("");
  };

  const onSubmit = async () => {
    await updateTenant({
      variables: { input: { id: tenantId, data: { name } } },
    });
    sessionStorage.removeItem("navigation");
    shouldUpdateTree(!shouldUpdateTree());
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Update tenant name"
      footer={{
        okText: "Save",
        onOk: onSubmit,
        onCancel,
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <StyledDescription>
          The new name will not make any changes in the tenant Id
        </StyledDescription>
        <TextField
          id="outlined-required"
          label="New name"
          placeholder="e.g. Company name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{
            marginTop: "16px",
          }}
          style={{ width: "100%" }}
          autoFocus
        />
        <TextField
          id="outlined-required"
          label="Id (for API call)"
          placeholder="your-api-id"
          value={tenantId}
          sx={{
            marginTop: "16px",
          }}
          style={{ width: "100%" }}
          disabled
        />
      </Container>
    </Modal>
  );
}

const StyledDescription = styled("p")`
  margin: 0 0 24px 0;
`;

export default UpdateTenantModal;
