import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { DELETE_TOKEN } from "./deleteToken.gql";
import Modal from "lib/organisms/Modal/Modal";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import { Container } from "common/styles/material-ui-styles";
import Spinner from "common/assets/spinner/spinner";

interface IProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
  token: {
    name: string;
    id: string;
  };
}

function DeleteTokenModal({ open, onClose, tenantId, token }: IProps) {
  const [deleteToken, { error, loading }] = useMutation(DELETE_TOKEN, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    refetchQueries: [GET_TENANT],
  });

  const onSubmit = async () => {
    await deleteToken({
      variables: { input: { id: token.id } },
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      title="Delete Token"
      footer={{
        okText: "Delete token",
        onOk: onSubmit,
        onCancel: onClose,
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <Description>
          Are you sure you want to delete the <Name>“{token.name}”</Name> token?
        </Description>
        <Description>
          The access token cannot be recovered after deletion.
        </Description>
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const Description = styled("p")`
  margin: 0 0 32px 0;
  line-height: 24px;
`;

const Name = styled("span")`
  word-break: break-word;
`;

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default DeleteTokenModal;
