import React, { ReactNode } from "react";
import Navbar from "../../lib/organisms/Navbar/Navbar";
import ProfileNavbar from "../../lib/organisms/Navbar/ProfileNavbar";

import "./layoutTemplate.cmp.css";
import { useLocation } from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";

type ILayoutProps = {
  noPadding?: boolean;
  children: ReactNode;
};

const Layout: React.FC<ILayoutProps> = (props) => {
  const location = useLocation();
  return (
    <div className="outer">
      <GlobalStyles
        styles={{
          '.MuiAutocomplete-option[aria-selected="true"]': {
            background: "#FFF !important",
          },
          '.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            background: "#F7F8FC !important",
          },
        }}
      />
      <div className="nav-container">
        {location.pathname.startsWith("/profile") ? (
          <ProfileNavbar />
        ) : (
          <Navbar />
        )}
      </div>
      <div
        className={
          props.noPadding ? "body-container-noPadding" : "body-container"
        }
      >
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
