import React, { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import * as R from "ramda";
import { IWebhook, IWebhooks, TWebhookAuth } from "./webhooks.interface";
import { IOption } from "common/interfaces/shared.interface";
import {
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  GET_WEBHOOKS,
  UPDATE_WEBHOOK,
} from "./webhooks.gql";
import { AUTH_FIELDS, initialState } from "./webhooks.utils";

function UseWebhookModal(tenantId: string) {
  const [values, setValues] = useState(initialState);
  const context = {
    headers: {
      "x-tenant-id": tenantId,
    },
  };

  const authFields = useMemo(
    () => AUTH_FIELDS[values.auth.type],
    [values.auth]
  );

  const onChange = (fieldName: string, value: string | IOption | boolean) => {
    if (fieldName === "auth") {
      setValues((prevState) => ({
        ...prevState,
        auth: { type: value as TWebhookAuth },
      }));
    } else {
      setValues((prevState) => ({ ...prevState, [fieldName]: value }));
    }
  };

  const onClearForm = () => setValues(initialState);

  const [createWebhook] = useMutation(CREATE_WEBHOOK, {
    context,
    update: (store, { data }) => {
      const webhooksList = store.readQuery<IWebhooks>({
        query: GET_WEBHOOKS,
      });
      const createWebhookData = data.webhook.create;
      store.writeQuery<any>({
        query: GET_WEBHOOKS,
        data: {
          webhooks:
            !R.isNil(webhooksList) && Array.isArray(webhooksList.webhooks)
              ? [...webhooksList.webhooks, createWebhookData]
              : [createWebhookData],
        },
      });
    },
  });

  const [deleteWebhook] = useMutation(DELETE_WEBHOOK, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    update: (store, { data }) => {
      const webhooksData = store.readQuery<IWebhooks>({ query: GET_WEBHOOKS });
      const deletedWebhookId = data.webhook.delete.id;

      store.writeQuery<any>({
        query: GET_WEBHOOKS,
        data: {
          webhooks:
            !R.isNil(webhooksData) && Array.isArray(webhooksData.webhooks)
              ? webhooksData.webhooks.filter(
                  (webhook) => webhook.id !== deletedWebhookId
                )
              : [],
        },
      });
    },
  });

  const [updateWebhook] = useMutation(UPDATE_WEBHOOK, {
    context,
    update: (store, { data }) => {
      const webhooksData = store.readQuery<IWebhooks>({ query: GET_WEBHOOKS });
      const updateWebhookData: IWebhook = data.webhook.update;

      if (!R.isNil(webhooksData) && "webhooks" in webhooksData) {
        store.writeQuery<IWebhooks>({
          query: GET_WEBHOOKS,
          data: {
            webhooks: webhooksData.webhooks.map((webhook) =>
              webhook.id === updateWebhookData.id ? updateWebhookData : webhook
            ),
          },
        });
      }
    },
  });

  return {
    createWebhook,
    deleteWebhook,
    updateWebhook,
    values,
    authFields,
    onChange,
    onClearForm,
    setValues,
  };
}

export default UseWebhookModal;
