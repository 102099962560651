import { gql } from "@apollo/client";

export const DELETE_SPACE = gql`
  mutation DELETE_SPACE($input: DeleteSpaceInput!) {
    space {
      delete(input: $input) {
        name
        normalizedName
        createdAt
        updatedAt
        id
        __typename
        parent {
          id
          name
        }
      }
    }
  }
`;
