import React, { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { NavLink, useNavigate } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/system";
import Button from "lib/atoms/Button/Button";
import TextField from "lib/atoms/TextFieldSimple/TextField";
import InputLabel from "@mui/material/InputLabel";

const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($input: VerifyEmailInput!) {
    account {
      sendResetPasswordEmail(input: $input) {
        success
      }
    }
  }
`;

function ResetPassword() {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const navigate = useNavigate();
  const { getValues, control, handleSubmit, clearErrors } = useForm({
    delayError: 2000,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { trackPageView } = useMatomo();

  const onSubmit = async () => {
    const values = getValues();
    try {
      await resetPassword({
        variables: { input: values },
      });
      navigate("/404", { state: "link_sent", replace: true });
    } catch (e) {
      navigate("/404", { state: "sign_up_error", replace: true });
    }
  };

  useEffect(() => {
    trackPageView({ documentTitle: "Reset password page" });
  }, []);

  return (
    <ResetOuter>
      <ResetLeft>
        <NavLink to="/" style={{ width: "100%" }}>
          <img className="logo" src="/logo.svg" alt="D4 logo" />
        </NavLink>
        <IconographyOuter>
          <ResetForm>
            <H1>Reset Password</H1>
            <form id="addTenantMember" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <InputLabel
                      className="input-label"
                      shrink
                      htmlFor="bootstrap-input"
                      sx={{
                        marginTop: "16px",
                      }}
                    >
                      Email*
                    </InputLabel>
                    <TextField
                      id="outlined-required"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      onFocus={() => clearErrors("email")}
                      helperText={error?.message}
                      type="Email"
                      sx={{
                        marginTop: "16px",
                        marginBottom: "16px",
                        width: "100%",
                      }}
                    />
                  </>
                )}
              />
              <button
                type="submit"
                className="btn super-large yellow auth-submit-button"
              >
                Reset
              </button>
            </form>
          </ResetForm>
        </IconographyOuter>
      </ResetLeft>
    </ResetOuter>
  );
}

const ResetOuter = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "100vh",
  background: "#010032",
});
const H1 = styled("h1")({
  color: "#FFFFFF",
  marginBottom: 32,
});

const ResetLeft = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundImage: "url('/home-page-background.svg')",
  objectFit: "contain",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "bottom",
});

const ResetForm = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});

const IconographyOuter = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  maxWidth: "520px",
  margin: "0 auto",
});

export default ResetPassword;
