import React from "react";
import useWebhookModal from "./useWebhookModal";
import useNotification from "hooks/useNotification";
import { Container } from "common/styles/material-ui-styles";
import Modal from "lib/organisms/Modal";

type TWebhook = {
  id: string;
  name: string;
};

interface IProps {
  open: boolean;
  tenantId: string;
  webhook: TWebhook;
  onClose: () => void;
}

function DeleteWebhookModal({ open, tenantId, webhook, onClose }: IProps) {
  const { notify } = useNotification();
  const { deleteWebhook } = useWebhookModal(tenantId);

  const onSubmit = async () => {
    try {
      await deleteWebhook({ variables: { input: { id: webhook.id } } });
      notify.success(`${webhook.name} has been deleted!`);
      onClose();
    } catch (e: any) {
      notify.error(e.message);
    }
  };

  return (
    <Modal
      open={open}
      title="Delete webhook"
      footer={{
        okText: "Delete",
        onOk: onSubmit,
        onCancel: onClose,
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <p>Are you sure you want to delete the “{webhook.name}”?</p>
      </Container>
    </Modal>
  );
}

export default DeleteWebhookModal;
