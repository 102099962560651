import * as React from "react";
import { Grid, Box } from "@mui/material";
import { CartType } from "./Card";

interface CardMediaProps {
  children: React.ReactNode;
  variant?: CartType;
}

export const CardMedia: React.FC<CardMediaProps> = ({
  children,
  variant = "default",
}) => {
  if (variant === "space") {
    return (
      <Box
        sx={{
          mr: 1,
          "& svg": {
            width: "24px",
            height: "auto",
          },
        }}
        style={{ marginRight: "8px" }}
      >
        {children}
      </Box>
    );
  } else {
    return (
      <Grid item md={3} sx={{ display: { xs: "none", md: "block" } }}>
        {children}
      </Grid>
    );
  }
};

export default CardMedia;
