import React, { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import { IOption } from "common/interfaces/shared.interface";
import { TAuth, TField } from "./webhooks.interface";
import { EMPTY_STRING } from "common/utils";
import TextField from "lib/atoms/TextField/TextField";
import {
  StyledAutocomplete,
  Container,
} from "common/styles/material-ui-styles";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";

const renderFormElement = (
  field: TField,
  values: any,
  onChange: (fieldName: string, value: string | IOption) => void
) => {
  const props = R.omit(["options, type"], field);

  switch (field.type) {
    case "INPUT":
      return (
        <TextField
          key={`${field.name}_INPUT`}
          style={{ width: "100%", marginTop: "16px" }}
          value={values?.[field.name]}
          onChange={(event) => onChange(field.name, event.target.value)}
          {...props}
        />
      );

    case "SELECT":
      return (
        <DefaultDropdown
          key={`${field.name}_SELECT`}
          options={field.options || []}
          variant="outlined"
          sx={{
            marginTop: "12px",
          }}
          style={{ width: "100%" }}
          value={values?.[field.name]}
          onChange={(event: any) => onChange(field.name, event.target.value)}
          {...props}
        />
      );

    case "AUTOCOMPLETE":
      return (
        <StyledAutocomplete
          key={`${field.name}_AUTOCOMPLETE`}
          options={field.options || []}
          value={values?.[field.name]}
          onChange={(event: any, option: any) => onChange(field.name, option)}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={field.placeholder}
              label={field.label}
              size="small"
            />
          )}
        />
      );
  }
};

function UseFormBuilder(
  fields: TField[],
  key: string,
  initialState?: TAuth,
  fieldsTouched?: boolean
) {
  const [values, setValues] = useState({} as TAuth);
  const shouldSetDefaultValues = !fieldsTouched && initialState;

  const onChange = (fieldName: string, value: string | IOption) =>
    setValues((prevState) => ({ ...prevState, [fieldName]: value }));

  const formElements = useMemo(
    () => (
      <Container key={key}>
        {fields?.length
          ? fields.map((field) => renderFormElement(field, values, onChange))
          : null}
      </Container>
    ),
    [values] // ignore the "fields" to calculate memo after the useEffect is done
  );

  useEffect(() => {
    const getInitialState = (data: TField[]) =>
      data?.reduce(
        (prev, current) => ({ ...prev, [current.name]: EMPTY_STRING }),
        {} as TAuth
      );

    if (shouldSetDefaultValues) {
      setValues(initialState);
    } else {
      setValues(getInitialState(fields));
    }
  }, [key]);

  return { values, formElements };
}

export default UseFormBuilder;
