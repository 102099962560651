import { useCallback, useState } from "react";

function useToggle() {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen((prevState) => !prevState), []);

  return {
    open,
    toggle,
    setOpen,
  };
}

export default useToggle;
