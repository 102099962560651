import { useEffect, useCallback } from "react";
import debounce from "lodash.debounce";

function useDebounce(callback: (args: any) => void, wait = 300) {
  // Prevent performance issues and save the function between re-renders
  const debounced = useCallback(debounce(callback, wait), []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return { debounced };
}

export default useDebounce;
