import { createInstance } from "@jonkoops/matomo-tracker-react";

export const instance = createInstance({
  urlBase: "https://dimensionfour.matomo.cloud",
  trackerUrl: "https://dimensionfour.matomo.cloud/matomo.php",
  srcUrl: "https://cdn.matomo.cloud/dimensionfour.matomo.cloud/matomo.js",
  siteId: 3,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});
