import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { IVerifyTenantTokenInput } from "../common/interfaces/verify.interface";
import logger from "../common/logger";
export const VERIFY_TOKEN = gql`
  mutation ACCEPT_MEMBERSHIP($input: AcceptMemberInput!) {
    member {
      accept(input: $input) {
        id
        email
        userId
        role
      }
    }
  }
`;

export const useVerifyInvitationTenant = (
  input: IVerifyTenantTokenInput
): ((input: IVerifyTenantTokenInput) => Promise<any>) => {
  logger("input", input);
  const [verifyTenantInvitation] = useMutation(VERIFY_TOKEN, {
    onCompleted: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
  return async (input) => verifyTenantInvitation({ variables: { input } });
};
