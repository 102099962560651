import React from "react";
import Auth from "../../cmp/auth/auth.cmp";
import FormPageContainer from "lib/organisms/FormPageContainer/FormPageContainer";

const Home = () => {
  return (
    <FormPageContainer>
      <Auth />
    </FormPageContainer>
  );
};

export default Home;
