import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { IVerifyAccountInput } from "../common/interfaces/verify.interface";
export const GET_PINCODE = gql`
  mutation VERIFY_ACC($input: VerifyActivationLinkInput!) {
    account {
      verifyActivationLink(input: $input) {
        pincode
        email
      }
    }
  }
`;

export const VERIFY_ACC = gql`
  mutation VERIFY_ACC($input: VerifyAccountInput!) {
    account {
      verifyAccount(input: $input) {
        success
      }
    }
  }
`;

export const useVerifyAccount = (
  input: IVerifyAccountInput
): ((input: IVerifyAccountInput) => Promise<any>) => {
  const [pinCodeReq] = useMutation(GET_PINCODE, {
    onCompleted: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
  const [verifyAcc] = useMutation(VERIFY_ACC, {
    onCompleted: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
  return async (input) => {
    const queueQueries = async (input: IVerifyAccountInput) => {
      const pinCodeEmailRes = await pinCodeReq({
        variables: { input },
      });

      const { pincode, email } =
        pinCodeEmailRes?.data?.account?.verifyActivationLink || "";

      const responseVerifi = await verifyAcc({
        variables: { input: { pincode, email } },
      });

      return responseVerifi;
    };

    return queueQueries(input);
  };
};
