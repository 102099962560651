import React, { ChangeEventHandler, KeyboardEvent, MouseEvent } from "react";
import { RegisterParamsInput } from "../../common/interfaces/register.interface";
import Spinner from "../../common/assets/spinner/spinner";
import { ServerError } from "../../common/interfaces/serverError.interface";
import TextField from "lib/atoms/TextFieldSimple/TextField";
import InputLabel from "@mui/material/InputLabel";
import { Divider } from "@mui/material";

export interface RegisterFormProps {
  loading: boolean;
  serverError: ServerError;
  registerForm: RegisterParamsInput;
  setRegisterForm: React.Dispatch<React.SetStateAction<RegisterParamsInput>>;
  onSwitchView: React.DispatchWithoutAction;
  registerHandler: React.DispatchWithoutAction;
}

const RegisterForm: React.FC<RegisterFormProps> = (props) => {
  const {
    loading,
    serverError,
    registerForm,
    setRegisterForm,
    onSwitchView,
    registerHandler,
  } = props;

  const changeFormHandler = (_: MouseEvent<HTMLButtonElement>) => {
    if (onSwitchView) {
      onSwitchView();
    }
  };

  const onEnterPressHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      registerHandler();
    }
  };

  const firstNameHandlerRegister: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { lastname, email, password } = registerForm;
    setRegisterForm({
      firstname: event.target.value,
      lastname,
      email,
      password,
    });
  };

  const lastNameHandlerRegister: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { firstname, email, password } = registerForm;
    setRegisterForm({
      lastname: event.target.value,
      firstname,
      email,
      password,
    });
  };

  const emailHandlerRegister: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { firstname, lastname, password } = registerForm;
    setRegisterForm({
      email: event.target.value,
      firstname,
      lastname,
      password,
    });
  };

  const passwordNameHandlerRegister: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { firstname, lastname, email } = registerForm;
    setRegisterForm({
      password: event.target.value,
      firstname,
      lastname,
      email,
    });
  };

  return (
    <>
      {loading ? <Spinner /> : null}
      <div className="auth-container">
        <div className="auth-wrapper">
          <p className="auth-title">Get started with Dimension Four</p>
          <p className="auth-description">No credit card required</p>
        </div>
        <div className="auth-input-login-container">
          <InputLabel className="input-label" shrink htmlFor="bootstrap-input">
            First Name
          </InputLabel>
          <TextField
            id="outlined-required"
            value={registerForm.firstname}
            onChange={firstNameHandlerRegister}
            onKeyPress={onEnterPressHandler}
            type="text"
            sx={{
              marginTop: "8px",
            }}
          />
          <InputLabel
            className="input-label"
            shrink
            htmlFor="bootstrap-input"
            sx={{
              marginTop: "16px",
            }}
          >
            Last Name
          </InputLabel>
          <TextField
            id="outlined-required"
            value={registerForm.lastname}
            onChange={lastNameHandlerRegister}
            onKeyPress={onEnterPressHandler}
            type="text"
            sx={{
              marginTop: "8px",
            }}
          />
          <InputLabel
            className="input-label"
            shrink
            htmlFor="bootstrap-input"
            sx={{
              marginTop: "16px",
            }}
          >
            Email*
          </InputLabel>
          <TextField
            id="outlined-required"
            value={registerForm.email}
            onChange={emailHandlerRegister}
            onKeyPress={onEnterPressHandler}
            type="email"
            required
            sx={{
              marginTop: "8px",
            }}
          />
          <InputLabel
            className="input-label"
            shrink
            htmlFor="bootstrap-input"
            sx={{
              marginTop: "16px",
            }}
          >
            Password*
          </InputLabel>
          <TextField
            id="outlined-required"
            type="password"
            value={registerForm.password}
            onChange={passwordNameHandlerRegister}
            onKeyPress={onEnterPressHandler}
            required
            sx={{
              marginTop: "8px",
            }}
          />
          <InputLabel
            className="input-label"
            shrink
            htmlFor="bootstrap-input"
            sx={{
              marginTop: "16px",
            }}
          >
            Confirm Password*
          </InputLabel>
          <TextField
            id="outlined-required"
            type="password"
            onKeyPress={onEnterPressHandler}
            required
            sx={{
              marginTop: "8px",
              marginBottom: "10px",
            }}
          />
        </div>
        <div className="auth-redirect"></div>
        <Divider className="divider" orientation="horizontal" />
        <button
          onClick={registerHandler}
          className="btn super-large yellow auth-submit-button"
        >
          Get Started
        </button>
        {serverError.showError ? (
          <div className="auth-error-message">
            Error: {serverError.errorMessage}
          </div>
        ) : null}
        <div className="auth-row">
          <p>
            Already have an account?&nbsp;&nbsp;
            <button className="auth-switch-button" onClick={changeFormHandler}>
              Login instead
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
