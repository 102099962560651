import React, { useEffect, useMemo } from "react";
import { styled } from "@mui/system";
import useTenant from "hooks/useTenant";
import useTracker from "hooks/useTracker";
import Layout from "cmp/templates/layoutTemplate.cmp";
import { ReactComponent as ProfileImage } from "common/assets/profile.svg";
import { NavLink, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import Spinner from "common/assets/spinner/spinner";
import HomeDataBox from "./components/homeDataBox/homeDataBox";
import { ReactComponent as Document } from "common/assets/overview/document.svg";
import { useNavigate } from "react-router-dom";

const Overview: React.FC = () => {
  const { trackEvent, trackPageView } = useTracker();
  const navigate = useNavigate();
  const { name: tenantId } = useParams();
  const client = useApolloClient();
  const {
    createTenantButton,
    createTenantModal,
    tenants,
    loading,
    toggleTenant,
  } = useTenant();

  const tenant = useMemo(
    () => tenants.find((tenant) => tenant.id === tenantId),
    [tenantId, tenants]
  );
  useEffect(() => {
    const computeData = async () => {
      if (tenantId == undefined && tenants?.[0]?.id) {
        await client.resetStore();
        navigate(`/overview/${tenants?.[0]?.id}`, {
          replace: true,
        });
      }
    };
    computeData();
  }, [tenants, loading]);
  const tenantPlan: string = tenant?.billing?.currentPlanPrice
    ? tenant?.billing?.currentPlanPrice
    : "";

  const tenantName = tenant?.name;
  const pricePlan = {
    BASIC_FREE: "Free plan",
  }[tenantPlan];
  useEffect(() => {
    trackPageView({ documentTitle: "Overview page" });
  }, []);
  return (
    <Layout>
      <Container>
        <Header>
          {tenantId == undefined && !loading && (
            <TitleContainer>
              <Title>Home</Title>
              <TenantTitle>Free plan</TenantTitle>
            </TitleContainer>
          )}
          {tenantId != undefined && !loading && (
            <TitleContainer>
              <Title>{tenantName}&nbsp;overview</Title>
              <TenantTitle>{pricePlan}</TenantTitle>
            </TitleContainer>
          )}
          <Buttons>
            <ButtonContainer>{createTenantButton}</ButtonContainer>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dimensionfour.io/resources/user-guide/quickstart"
              style={{ textDecoration: "none", marginLeft: "28px" }}
            >
              <Document />
            </a>
            <NavLink
              onClick={() =>
                trackEvent({
                  category: "tenant",
                  action: "clickedManageProfile",
                })
              }
              style={{ marginLeft: "28px" }}
              to="/profile/settings"
            >
              <ProfileImage />
            </NavLink>
          </Buttons>
        </Header>
        {loading && <Spinner />}
        {tenantId !== undefined ? (
          <StyledLayout>
            <HomeDataBox
              variant="Overview"
              space={tenant?.totalSpaces}
              point={tenant?.totalPoints}
              tenant={tenant}
              userName={tenantName}
              onClick={toggleTenant}
              token={[]}
            />
          </StyledLayout>
        ) : (
          <StyledLayout>
            <HomeDataBox
              variant="TENANT"
              space={undefined}
              point={undefined}
              tenant={undefined}
              userName={tenantName}
              onClick={toggleTenant}
              token={[]}
            />
          </StyledLayout>
        )}
      </Container>
      {createTenantModal}
    </Layout>
  );
};
const StyledLayout = styled("div")`
  margin: 48px 0;
`;
const Container = styled("div")({});

const ButtonContainer = styled("div")({});

const TitleContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Title = styled("h2")({
  fontSize: "36px",
  lineHeight: "100%",
  fontWeight: 500,
});

const TenantTitle = styled("div")({
  marginTop: "6px",
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "100%",
  color: "var(--dark-grey-bg-color)",
});

const Buttons = styled("div")({
  display: "flex",
  alignItems: "baseline",
});

export default Overview;
