import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ISpaces, IPoints } from "./tenant.interface";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { GET_SPACES } from "common/graphql/space.gql";
import { GET_POINTS } from "common/graphql/point.gql";
import useDebounce from "hooks/useDebounce";
import useTracker from "hooks/useTracker";
import TabsPanel from "lib/molecules/TabsPanel/TabsPanel";
import IconButton from "lib/atoms/Button/IconButton";
import { Container } from "common/styles/material-ui-styles";
import { allPropsEmpty, lastActiveNormalizer } from "./tenant.utils";
import ManageTenant from "./components/manageTenant/manageTenant.cmp";
import SpacesList from "./components/spacesList/spacesList.cmp";
import TenantsTable from "./components/pointsTable/pointsTable.cmp";
import CreateSpaceModal from "./components/createSpaceModal/createSpaceModal.cmp";
import Search from "./components/search/search.cmp";
import SearchWithFilters from "./components/searchWithFilters/searchWithFilters.cmp";
import Layout from "cmp/templates/layoutTemplate.cmp";
import useFilters from "./components/searchWithFilters/useFilters";
import { IFilters } from "./spaces/spaces.page";
import { ReactComponent as ProfileImage } from "common/assets/profile.svg";
import { ReactComponent as Document } from "common/assets/overview/document.svg";
import { useAppContext } from "context";

interface ITenantPageState {
  id?: string;
  defaultTab?: number;
}

const TABS = ["POINTS", "SPACES", "MANAGE TENANT"] as const;

const initialFilters = {
  name: "",
  spaceId: "",
  updatedAt: "",
};

const filteringSettings: Record<keyof IFilters, "_EQ" | "_GT"> = {
  name: "_EQ",
  spaceId: "_EQ",
  updatedAt: "_GT",
};

const TenantPage: React.FC = () => {
  const {
    setState,
    state: { run, stepIndex, steps, continuous },
  } = useAppContext();
  const [createSpaceModal, setCreateSpaceModal] = useState<boolean>(false);
  const [spaceSearch, setSpaceSearch] = useState("");
  const { trackPageView, trackEvent } = useTracker();
  const { name: tenantId, spaceId } = useParams();
  const location = useLocation();
  const pageState = location?.state as ITenantPageState;

  const [tab, setTab] = useState(
    pageState?.defaultTab == undefined ? 2 : pageState?.defaultTab
  );
  const options = {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
  };

  /* !!! TEMPORARY SOLUTION.
  THIS QUERY DUPLICATES IN manageTenants !!! */
  const { data: tenantData, loading: tenantLoading } = useQuery(GET_TENANT, {
    ...options,
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  const {
    data: spacesData,
    loading: spacesLoading,
    refetch: refetchSpaces,
  } = useQuery<ISpaces>(GET_SPACES, {
    ...options,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  const [getPoints, { data: pointsData, loading: pointsLoading }] =
    useLazyQuery<IPoints>(GET_POINTS, {
      ...options,
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-only",
    });

  const { debounced: debouncedSearch } = useDebounce(refetchSpaces, 500);

  const ifEmptySearch = (value: string, path: object) =>
    value.length ? path : { spaceInput: {} };

  const onSpaceSearch = (value: string) => {
    setSpaceSearch(value);
    const params = ifEmptySearch(value, {
      spaceInput: { name: { _EQ: value } },
    });
    value.length === 0 ? refetchSpaces(params) : debouncedSearch(params);
  };

  const tenantPlan: string = tenantData?.tenant?.billing?.currentPlanPrice;
  const tenantName = tenantData?.tenant?.name;
  const spaces = spacesData?.spaces?.nodes;
  const points = pointsData?.points?.nodes;

  // Key based on the URL, we use it to find saved filters
  const sessionKey =
    typeof spaceId === "string"
      ? spaceId
      : typeof tenantId === "string"
      ? tenantId
      : "";

  const variables = { pointsInput: {} };

  const { filters, onSearch, onFilter, onFiltersReset } = useFilters<IFilters>(
    initialFilters,
    filteringSettings,
    sessionKey,
    variables,
    "name",
    getPoints,
    lastActiveNormalizer
  );

  const hideSearch = !spaces?.length && !spaceSearch && !spacesLoading;
  const spaceTabFilters = !!spaceSearch;
  const pointsTabFilters = !allPropsEmpty(filters);

  const pricePlan = {
    BASIC_FREE: "Free plan",
  }[tenantPlan];

  const onTabChange = (event: React.SyntheticEvent | any) => {
    if (event.target.innerText === "MANAGE TENANT") {
      setTab(2);
      setState({ run: true, stepIndex: 1, tourActive: false });
      trackEvent({ action: "clickedManageTenant" });
    } else if (event.target.innerText === "POINTS") {
      setTab(0);
      setState({ run: true, stepIndex: 3, tourActive: false });
    } else if (event.target.innerText === "SPACES") {
      setTab(1);
      setState({ run: true, stepIndex: 2, tourActive: false });
    }
  };

  useEffect(() => {
    trackPageView({ documentTitle: "Tenant's page" });
    if (tab === 2) {
      setTab(2);
      setState({ run: true, stepIndex: 1 });
      trackEvent({ action: "clickedManageTenant" });
    } else if (tab === 0) {
      setTab(0);
      setState({ run: true, stepIndex: 3 });
    } else if (tab === 1) {
      setTab(1);
      setState({ run: true, stepIndex: 2 });
    }
  }, [tab]);

  return (
    <Layout>
      <StyledHeader>
        <Container>
          <StyledHeadline>{tenantName}</StyledHeadline>
          <StyledPlan>{pricePlan}</StyledPlan>
        </Container>
        <StyledButtons>
          <IconButton
            onClick={() => setCreateSpaceModal(true)}
            variant="primary"
          >
            <AddIcon />
            Create space
          </IconButton>{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dimensionfour.io/resources/user-guide/quickstart"
            style={{ textDecoration: "none", marginLeft: "28px" }}
          >
            <Document />
          </a>
          <NavLink style={{ marginLeft: "28px" }} to="/profile/settings">
            <ProfileImage />
          </NavLink>
        </StyledButtons>
      </StyledHeader>
      <TabsPanel onChange={onTabChange} panels={TABS} defaultValue={tab}>
        <div>
          {!hideSearch && (
            <SearchWithFilters
              onFilter={onFilter}
              onFiltersReset={onFiltersReset}
              onSearch={onSearch}
              length={points?.length}
              values={filters}
              loading={pointsLoading}
            />
          )}
          <TenantsTable
            hasActiveFilters={pointsTabFilters}
            points={points}
            loading={pointsLoading}
          />
        </div>
        <div>
          {!hideSearch && (
            <StyledSearch
              value={spaceSearch}
              size="small"
              onChange={onSpaceSearch}
              placeholder="Search by space name"
            />
          )}
          <SpacesList
            spaces={spaces}
            panels={TABS}
            loading={spacesLoading}
            onCreateSpace={() => setCreateSpaceModal(true)}
            hasActiveFilters={spaceTabFilters}
          />
        </div>
        <ManageTenant />
      </TabsPanel>
      <CreateSpaceModal
        open={createSpaceModal}
        onClose={() => setCreateSpaceModal(false)}
        tenantId={tenantId}
      />
    </Layout>
  );
};

const StyledHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 36px 0;
`;

const StyledButtons = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

const StyledHeadline = styled("h2")`
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  color: black;
`;

const StyledPlan = styled("div")`
  display: block;
  margin: 6px 0 0 0;
  color: #6c6c6c;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
`;

const StyledSearch = styled(Search)`
  width: 320px;
  margin: 40px 0 0 0;
`;

export default TenantPage;
