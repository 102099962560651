import { gql } from "@apollo/client";

export const GET_WEBHOOKS = gql`
  query GET_WEBHOOKS {
    webhooks {
      id
      createdAt
      updatedAt
      name
      endpoint
      requestType
      entityType
      eventType
      active
      auth {
        ... on AuthApiKey {
          type
          key
          value
          addTo
        }
        ... on AuthOAuth2 {
          type
          token
          addTo
        }
        ... on AuthToken {
          type
          token
        }
        ... on AuthBasic {
          type
          username
          password
        }
        ... on AuthNone {
          type
        }
      }
    }
  }
`;

export const CREATE_WEBHOOK = gql`
  mutation CREATE_WEBHOOK($input: CreateWebhookInput!) {
    webhook {
      create(input: $input) {
        id
        createdAt
        updatedAt
        name
        endpoint
        requestType
        entityType
        eventType
        active
        auth {
          ... on AuthApiKey {
            type
            key
            value
            addTo
          }
          ... on AuthOAuth2 {
            type
            token
            addTo
          }
          ... on AuthToken {
            type
            token
          }
          ... on AuthBasic {
            type
            username
            password
          }
          ... on AuthNone {
            type
          }
        }
      }
    }
  }
`;

export const DELETE_WEBHOOK = gql`
  mutation DELETE_WEBHOOK($input: DeleteWebhookInput!) {
    webhook {
      delete(input: $input) {
        id
      }
    }
  }
`;

export const UPDATE_WEBHOOK = gql`
  mutation UPDATE_WEBHOOK($input: UpdateWebhookInput!) {
    webhook {
      update(input: $input) {
        id
        createdAt
        updatedAt
        name
        endpoint
        requestType
        entityType
        eventType
        active
        auth {
          ... on AuthApiKey {
            type
            key
            value
            addTo
          }
          ... on AuthOAuth2 {
            type
            token
            addTo
          }
          ... on AuthToken {
            type
            token
          }
          ... on AuthBasic {
            type
            username
            password
          }
          ... on AuthNone {
            type
          }
        }
      }
    }
  }
`;
