import React, { ReactNode } from "react";
import IconButton, { IconButtonVariant } from "./IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface ExpandableButtonProps {
  variant?: IconButtonVariant;
  expanded?: boolean;
  className?: string;
  onExpanded?: (expanded: boolean) => void;
  children: ReactNode;
}

const ExpandableButton = ({
  variant = "neutral",
  expanded,
  className,
  children,
  onExpanded,
  ...props
}: ExpandableButtonProps) => {
  const defaultIcon = expanded ? (
    <KeyboardArrowUpIcon />
  ) : (
    <KeyboardArrowDownIcon />
  );
  const [isExpanded, setIsExpanded] = React.useState(expanded ?? false);
  const [icon, setIcon] = React.useState(defaultIcon);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const expanded = !isExpanded;
    setIsExpanded(expanded);
    setIcon(expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />);
    onExpanded && onExpanded(expanded);
  };

  return (
    <IconButton
      className={className}
      onClick={handleClick}
      iconAtEnd={true}
      variant={variant}
      size="small"
    >
      {children} {icon}
    </IconButton>
  );
};

export default ExpandableButton;
