import { gql } from "@apollo/client";

export const UPDATE_TENANT = gql`
  mutation UPDATE_TENANT($input: UpdateTenantInput!) {
    tenant {
      update(input: $input) {
        id
        name
      }
    }
  }
`;
