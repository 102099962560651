import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import logger from "../../common/logger";

export interface ProtectedRouteProps {
  children?: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const authContext = JSON.parse(localStorage.getItem("userData") || "{}");

  const location = useLocation();
  const token = location?.search?.match(/token=(.*)/)?.[1];

  if (authContext?.isAuth) {
    logger("authContext", authContext);
    return <>{children}</>;
  } else if (token) {
    logger("token", token);
    return <Navigate replace to={`/home?verifyTenantToken=${token}`} />;
  } else {
    return <Navigate replace to="/" />;
  }
};
