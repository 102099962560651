import React from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import useTracker from "hooks/useTracker";
import useNotification from "hooks/useNotification";
import { ITenants } from "hooks/useTenant";
import { ISpaces } from "../../tenant.interface";
import { GET_TENANTS } from "common/graphql/tenant.gql";
import { GET_SPACES } from "common/graphql/space.gql";
import { DELETE_SPACE } from "./deleteSpace.gql";
import Modal from "lib/organisms/Modal";
import { shouldUpdateTree } from "lib/organisms/Navbar/useNavbar";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import Spinner from "common/assets/spinner/spinner";

interface IDeleteSpaceProps {
  open: boolean;
  tenantId: string;
  spaceName: string;
  spaceId: string;
  onCancel: () => void;
}

function DeleteSpaceModal({
  open,
  tenantId,
  spaceName,
  spaceId,
  onCancel,
}: IDeleteSpaceProps) {
  const { trackEvent } = useTracker();
  const [deleteSpace, { loading, error }] = useMutation(DELETE_SPACE, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    update: (store, { data }) => {
      const tenantsData = store.readQuery<ITenants>({ query: GET_TENANTS });
      const spacesData = store.readQuery<ISpaces>({ query: GET_SPACES });
      const deletedSpaceId = data.space.delete.id;

      if (spacesData && "spaces" in spacesData) {
        store.writeQuery<ISpaces>({
          query: GET_SPACES,
          data: {
            spaces: {
              nodes: spacesData.spaces.nodes.filter(
                (node) => node.id !== deletedSpaceId
              ),
            },
          },
        });
      }

      if (tenantsData && "tenants" in tenantsData) {
        store.writeQuery<ITenants>({
          query: GET_TENANTS,
          data: {
            tenants: tenantsData.tenants.map((tenant) =>
              tenant.id !== tenantId
                ? tenant
                : { ...tenant, totalSpaces: tenant.totalSpaces - 1 }
            ),
          },
        });
      }
    },
  });

  const navigate = useNavigate();
  const { notify } = useNotification();

  const onSubmit = async () => {
    await deleteSpace({
      variables: {
        input: { id: spaceId },
      },
    });
    trackEvent({ action: "clickedDeleteSpace" });
    notify.success(`Space "${spaceName}" has been successfully deleted!`);
    navigate(`/t/${tenantId}`, { replace: true });
    sessionStorage.removeItem("navigation");
    shouldUpdateTree(!shouldUpdateTree());
  };

  return (
    <>
      <Modal
        open={open}
        title={`Are you sure you want to delete the space “${spaceName}”?`}
        footer={{
          okText: "Confirm",
          onOk: onSubmit,
          onCancel: onCancel,
        }}
        style={{ maxWidth: "467px" }}
      >
        <Container>
          <StyledText>
            The space cannot be recovered after deletion and all points
            connected to the space will be deleted.
          </StyledText>
          {!!error && (
            <StyledNotification severity="error">
              {error?.message}
            </StyledNotification>
          )}
          {loading ? <Spinner /> : null}
        </Container>
      </Modal>
    </>
  );
}

const Container = styled("div")``;

const StyledText = styled("p")`
  margin: 0 0 32px 0;
  line-height: 24px;
`;

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default DeleteSpaceModal;
