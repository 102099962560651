import React, { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { IWebhooks, TAuth } from "./webhooks.interface";
import { GET_WEBHOOKS } from "./webhooks.gql";
import { isNil } from "common/utils";
import { convertISO, modalsFactory } from "../../../tenant.utils";
import MaterialIconButton from "lib/atoms/Button/MaterialIconButton";
import IconButton from "lib/atoms/Button/IconButton";
import { StyledTableRow } from "common/styles/material-ui-styles";
import {
  StyledHeader,
  StyledHeadline,
  StyledSubHeadline,
  StyledTable,
  StyledTableHead,
  StyledContainer,
} from "../manageTenant.cmp";
import CreateWebhookModal from "./createWebhookModal.cmp";
import DeleteWebhookModal from "./deleteWebhookModal.cmp";
import EditWebhookModal from "./editWebhookModal.cmp";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Paragraph, theme } from "@gilbarbara/components";
import styled from "@emotion/styled";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "500px",
    width: "100%",
    background: "#010032",
    boxShadow: "3px 4px 25px rgba(30, 26, 44, 0.02)",
    borderRadius: "8px",
    padding: 24,
    transform: "translate(-23px, 0) !important",
    cursor: "pointer !important",
  },
  "& .MuiTooltip-arrow": {
    color: "#010032",
    width: "7em",
    height: "2em",
    transform: "translate(16px, -12px) !important",
  },
  [`& .${tooltipClasses.popper}`]: {
    transform: "translate(394px, 442px) !important",
    cursor: "pointer !important",
  },
});
const initialState = {
  addWebhook: {
    visible: false,
  },
  editWebhook: {
    visible: false,
    webhook: {
      id: "",
      createdAt: "",
      updatedAt: "",
      active: true,
      name: "",
      endpoint: "",
      requestType: "",
      entityType: "ALL",
      eventType: "ALL",
      auth: {
        type: "NONE",
      } as TAuth,
    },
  },
  deleteWebhook: {
    visible: false,
    id: "",
    name: "",
  },
};

type TModalState = typeof initialState;
type ModalSetter = Dispatch<SetStateAction<TModalState>>;

function Webhooks() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const { name: tenantId } = useParams();
  const { data } = useQuery<IWebhooks | null>(GET_WEBHOOKS, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
  });

  const [modals, setModals] = useState(initialState);
  const setModal = modalsFactory<TModalState, ModalSetter>(modals, setModals);

  const addWebhook = setModal("addWebhook");
  const editWebhook = setModal("editWebhook");
  const deleteWebhook = setModal("deleteWebhook");

  const hasNoWebhooks = isNil(data) || !data?.webhooks?.length;

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeadline>
          Webhooks&nbsp;
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomWidthTooltip
                open={open}
                title={
                  <>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "25px",
                      }}
                    >
                      Webhooks
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        marginTop: 8,
                      }}
                    >
                      A webhook is an automated message sent from Dimension Four
                      to another API whenever a specific event occurs.
                      <br />
                      <br />
                      Learn more about Webhooks in our &nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://dimensionfour.io/resources/user-guide/create-a-webhook"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "#ffa654",
                          cursor: "pointer",
                        }}
                      >
                        Documentation.
                      </a>
                    </Paragraph>
                  </>
                }
                placement="bottom-start"
                arrow
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  variant="neutral"
                  style={{ border: "none" }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#3237B8",
                      width: "18px !important",
                      height: "18px !important",
                    }}
                  />
                </IconButton>
              </CustomWidthTooltip>
            </div>
          </ClickAwayListener>
        </StyledHeadline>
        <IconButton variant="primary" onClick={addWebhook.showModal}>
          <AddIcon />
          Add webhook
        </IconButton>
      </StyledHeader>
      {hasNoWebhooks ? (
        <StyledSubHeadline>No webhook added</StyledSubHeadline>
      ) : (
        <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {data?.webhooks.map((webhook) => (
              <StyledTableRow key={webhook.id}>
                <TableCell component="th" scope="row">
                  {webhook.name}
                </TableCell>
                <TableCell>{webhook.id}</TableCell>
                <TableCell>{convertISO(webhook.createdAt) || "-"}</TableCell>
                <TableCell>{convertISO(webhook.updatedAt) || "-"}</TableCell>
                <TableCell sx={{ textAlign: "right", padding: "0" }}>
                  <MaterialIconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                      editWebhook.withParams({ webhook, visible: true })
                    }
                  >
                    <EditIcon />
                  </MaterialIconButton>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "right",
                    padding: "12px 42px 12px 12px",
                    width: 100,
                  }}
                >
                  <MaterialIconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                      deleteWebhook.withParams({
                        name: webhook.name,
                        id: webhook.id,
                        visible: true,
                      })
                    }
                  >
                    <ClearIcon />
                  </MaterialIconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
      <CreateWebhookModal
        open={modals.addWebhook.visible}
        onClose={addWebhook.hideModal}
      />
      <DeleteWebhookModal
        open={modals.deleteWebhook.visible}
        tenantId={tenantId || ""}
        webhook={{
          name: modals.deleteWebhook.name,
          id: modals.deleteWebhook.id,
        }}
        onClose={deleteWebhook.hideModal}
      />
      {modals.editWebhook.visible && (
        <EditWebhookModal
          open={modals.editWebhook.visible}
          onClose={editWebhook.hideModal}
          webhook={modals.editWebhook.webhook}
        />
      )}
    </StyledContainer>
  );
}

export default Webhooks;
