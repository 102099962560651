import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import useTracker from "hooks/useTracker";
import Modal from "lib/organisms/Modal";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import { shouldUpdateTree } from "lib/organisms/Navbar/useNavbar";
import { DELETE_TENANT, GET_TENANTS } from "common/graphql/tenant.gql";
import TextField from "lib/atoms/TextField/TextField";
import Spinner from "common/assets/spinner/spinner";

interface IDeleteTenantModalProps {
  open: boolean;
  onClose: () => void; // Take a look into "useToggle" hook Types, mb change to react.dispatch
  tenantId: string | undefined;
}

function DeleteTenantModal({
  open,
  onClose,
  tenantId,
}: IDeleteTenantModalProps) {
  const { trackEvent } = useTracker();
  const [tenantInput, setTenantInput] = useState<string>("");
  const navigate = useNavigate();
  const client = useApolloClient();
  const buttonDisabled = tenantInput !== tenantId;

  const [deleteTenant, { loading, error }] = useMutation(DELETE_TENANT, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    refetchQueries: [GET_TENANTS],
  });

  const onSubmit = async () => {
    trackEvent({ action: "clickedDeleteTenant" });
    await deleteTenant({
      variables: {
        input: { id: tenantId },
      },
    });
    await client.resetStore();
    navigate("/dashboard", { replace: true });
    sessionStorage.removeItem("navigation");
    shouldUpdateTree(!shouldUpdateTree());
    onClose();
  };

  return (
    <Modal
      open={open}
      title={`Are you sure you want to delete the tenant “${tenantId}”?`}
      footer={{
        okText: "Confirm",
        onOk: onSubmit,
        onCancel: onClose,
        okButtonProps: {
          disabled: buttonDisabled,
        },
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <StyledText>
          The tenant cannot be recovered after deletion and all spaces and
          points connected to the tenant will be deleted.
        </StyledText>
        <StyledInput
          id="outlined-required"
          label="Tenant Id*"
          placeholder="Confirm tenant id"
          value={tenantInput}
          onChange={(e) => setTenantInput(e.target.value)}
        />
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const Container = styled("div")``;

const StyledText = styled("p")`
  margin: 0 0 32px 0;
  line-height: 24px;
`;

const StyledInput = styled(TextField)`
  width: 100%;
`;

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default DeleteTenantModal;
