import React, { useEffect } from "react";
import { styled } from "@mui/system";
import { useLocation, NavLink } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Grid, Box } from "@mui/material";
import email from "common/assets/email.png";
import astronautSuccess from "common/assets/success_astronaut.png";
import astronautError from "common/assets/404_astronaut.png";
import astronautThinking from "common/assets/Astronaut_thinking.png";
import notfound from "common/assets/not-found.png";
import { ReactComponent as Logo } from "common/assets/brand-icon.svg";

type TStatus =
  | "email_verified"
  | "password_updated"
  | "sign_up_error"
  | "sign_up_expired"
  | "not_found"
  | undefined;

const STATUS_MESSAGES = {
  email_verified: "Email verified page",
  password_updated: "Password updated page",
  sign_up_error: "SignUp error page",
  sign_up_expired: "SignUp expired page",
  not_found: "Not found page",
};

function StatusPage() {
  const { trackPageView } = useMatomo();
  const location = useLocation();
  const status = location?.state as TStatus;

  const variants = {
    email_verified: {
      title: <Title>Email verified</Title>,
      text: (
        <Text>
          Your email address was successfully verified.&nbsp;
          <NavLink to="/">Log in to get started!</NavLink>
        </Text>
      ),
      image: <Image alt="email verified" src={astronautSuccess} />,
    },
    password_updated: {
      title: <Title>Your password has been updated</Title>,
      text: (
        <Text>
          <NavLink to="/">Login to your account</NavLink>
        </Text>
      ),
      image: <Image alt="password updated" src={astronautSuccess} />,
    },
    link_sent: {
      title: <Title>You got mail!</Title>,
      text: (
        <Text>
          Click the link in the email to reset password.&nbsp;
          <NavLink to="/">Go back to log in.</NavLink>
        </Text>
      ),
      image: <Image alt="link sent" src={email} />,
    },
    sign_up_error: {
      title: <Title>Woops! Something went wrong</Title>,
      text: (
        <Text>
          Your email address could not be verified.&nbsp;
          <NavLink to="/">Try a different email address</NavLink>
        </Text>
      ),
      image: <Image alt="sign up error" src={astronautError} />,
    },
    sign_up_expired: {
      title: <Title>This link is lost in space</Title>,
      text: (
        <Text>
          This link has expired. Please request a new invitation from the owner
          or admin of the tenant.
        </Text>
      ),
      image: <Image alt="link expired" src={astronautThinking} />,
    },
    not_found: {
      title: <Title>This page seems to be lost in space</Title>,
      text: (
        <Text>
          Navigate back to a <NavLink to="/">safe dimension</NavLink>
        </Text>
      ),
      image: <Image alt="not found" src={notfound} />,
    },
  }[status || "not_found"];

  useEffect(() => {
    trackPageView({ documentTitle: STATUS_MESSAGES[status || "not_found"] });
  }, []);

  return (
    <Container>
      <NavLink to="/">
        <LogoImage />
      </NavLink>
      <Grid
        className="image-container"
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          width: "100%;",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            p: { xs: "32px" },
          }}
        >
          {variants.title}
          {variants.text}
          {variants.image}
        </Box>
      </Grid>
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #010032;
`;

const Title = styled("h1")`
  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  font-family: Sora, Poppins, Roboto, Helvetica, Arial, sans-serif;
  color: #ffffff;
  line-height: 120%;
  text-align: center;
`;

const Text = styled("div")`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
  color: ${({ theme }) => theme.palette.common.grey[5]};
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  margin: 16px 0;
  a {
    color: #ffa654;
  }
`;

const Image = styled("img")`
  margin: 56px 0;
`;

const LogoImage = styled(Logo)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 40px 0px 0px 40px;
  align-self: flex-start;
  width: 32px;
`;

export default StatusPage;
