import React, { useState, useEffect } from "react";
import { SxProps, Theme, Box } from "@mui/material";
import TreeViewContext from "./TreeViewContext";

interface TreeViewProps {
  sx?: SxProps<Theme> | undefined;
  children: React.ReactNode | React.ReactNode[] | undefined;
  selectedNodeId?: string;
  handleMenuClick?: (to?: string) => void;
}

const TreeView: React.FC<TreeViewProps> = ({
  sx,
  children,
  selectedNodeId,
  handleMenuClick,
}) => {
  const [selected, setSelected] = useState(selectedNodeId ?? "");

  useEffect(() => {
    setSelected(selectedNodeId ?? "");
  }, [selectedNodeId]);

  const styles = {
    width: "100%",
    ...sx,
  };
  return (
    <TreeViewContext.Provider
      value={{
        selected,
        handleMenuClick,
        selectItem: setSelected,
      }}
    >
      <Box sx={styles}>{children}</Box>
    </TreeViewContext.Provider>
  );
};

export default TreeView;
