import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import useToggle from "hooks/useToggle";
import {
  IAccessTokenType,
  ITenantCredentials,
  PlanPriceType,
  TMember,
} from "common/interfaces/tenant.interface";
import { GET_TENANTS } from "common/graphql/tenant.gql";
import { GET_CARDS } from "common/graphql/card.gql";
import Modal from "lib/organisms/Modal";
import CreateTenatModal from "lib/molecules/CreateTenantModal/CreateTenantModal";
import IconButton, { IconButtonSize } from "lib/atoms/Button/IconButton";

interface IModal {
  open: boolean;
  onClose: () => void;
}

interface IButton {
  onClick: () => void;
  size?: IconButtonSize;
}

export interface ITenant {
  id: string;
  name: string;
  totalPoints: number;
  totalSpaces: number;
  createdAt: string;
  billing: {
    currentPlanPrice: PlanPriceType;
  };
  members: TMember[];
  accessTokens: IAccessTokenType[];
}

type TCardAddress = {
  id: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  line: string;
  line2: string;
};

interface ICard {
  id: string;
  name: string;
  brand: string;
  lastFourDigit: string;
  currency: string;
  isDefault: boolean;
  address: TCardAddress;
  expMonth: number;
  expYear: number;
}

export interface ITenants {
  tenants: ITenant[];
}

interface ICards {
  cards: ICard[];
}

const PRICE_PLANS = [
  { label: "BASIC_FREE", value: "BASIC_FREE", disabled: false },
  { label: "STAR_MONTHLY", value: "STAR_MONTHLY", disabled: false },
  { label: "STARTER_MONTHLY", value: "STARTER_MONTHLY", disabled: false },
  { label: "STARTER_ANNUALLY", value: "STARTER_ANNUALLY", disabled: false },
  { label: "MEDIUM_MONTHLY", value: "MEDIUM_MONTHLY", disabled: false },
  { label: "MEDIUM_ANNUALLY", value: "MEDIUM_ANNUALLY", disabled: false },
  { label: "LARGE_MONTHLY", value: "LARGE_MONTHLY", disabled: false },
  { label: "LARGE_ANNUALLY", value: "LARGE_ANNUALLY", disabled: false },
];

function CreateTenantButton({ onClick, size = "default" }: IButton) {
  return (
    <IconButton variant="primary" size={size} onClick={onClick}>
      <AddIcon />
      Create Tenant
    </IconButton>
  );
}

function LimitModal({ open, onClose }: IModal) {
  return (
    <StyledLimitModal
      open={open}
      title="Limit reached"
      footer={{
        cancelText: null,
        okText: "Ok",
        onOk: onClose,
      }}
    >
      <LimitText>
        Your plan only allows one free tenant. Please contact Dimension Four to
        update your plan to be able to create more tenants.
      </LimitText>
    </StyledLimitModal>
  );
}

function useTenant() {
  const { open, toggle } = useToggle();
  const { data: tenantsData, loading } = useQuery<ITenants>(GET_TENANTS);
  const { data: cardsData } = useQuery<ICards>(GET_CARDS);
  const tenants = tenantsData?.tenants ? tenantsData?.tenants : [];
  const cards = cardsData?.cards ? cardsData?.cards : [];

  const hasFreeTenant = useMemo(
    () =>
      !!tenants?.filter(
        (tenant: ITenantCredentials) =>
          tenant.billing.currentPlanPrice === "BASIC_FREE"
      ).length,
    [tenants]
  );

  const hasActiveCard = !!cards.length;
  const shouldUpgradePlan = hasFreeTenant && !hasActiveCard;
  // Even if you have a paid account, you can't have more than one free tenant
  const disableFreePlanOption = hasFreeTenant && hasActiveCard;
  const disablePaidPlans = !hasFreeTenant && !hasActiveCard;

  const options = useMemo(
    () =>
      disablePaidPlans
        ? PRICE_PLANS.map((plan, index) =>
            index !== 0 ? { ...plan, disabled: true } : plan
          )
        : disableFreePlanOption
        ? PRICE_PLANS.map((plan, index) =>
            index === 0 ? { ...plan, disabled: true } : plan
          )
        : PRICE_PLANS,
    [disableFreePlanOption, disablePaidPlans]
  );

  const action = shouldUpgradePlan ? "limited" : "canCreate";

  const createTenantModal = useMemo(
    () =>
      ({
        canCreate: (
          <CreateTenatModal open={open} onClose={toggle} options={options} />
        ),
        limited: <LimitModal open={open} onClose={toggle} />,
      }[action]),
    [open, toggle]
  );

  const createTenantButton = useMemo(
    () => <CreateTenantButton onClick={toggle} />,
    [toggle]
  );

  return {
    tenants,
    loading,
    createTenantButton,
    createTenantModal,
    toggleTenant: toggle,
  };
}

const StyledLimitModal = styled(Modal)`
  max-width: 467px;
`;

const LimitText = styled("p")`
  line-height: 24px;
`;

export default useTenant;
