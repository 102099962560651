import { gql } from "@apollo/client";

export const SIGNAL_ADDED_FORWARDING = gql`
  subscription SIGNAL_ADDED_FORWARDING($where: SignalSubscriptionFilterInput) {
    signalAdded(where: $where) {
      id
      unit
      type
      createdAt
      data {
        rawValue
        numericValue
      }
      metadata
    }
  }
`;

export const GET_SIGNALS = gql`
  query GET_SIGNALS($where: SignalFilterInput) {
    signals(where: $where, paginate: { last: 10 }) {
      nodes {
        id
        type
        createdAt
        unit
        data {
          numericValue
          rawValue
        }
        metadata
      }
    }
  }
`;
