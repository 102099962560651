import { useLocation, useParams } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import {
  TrackPageViewParams,
  TrackEventParams,
} from "@jonkoops/matomo-tracker/src/types";

type TCategory = "tenant" | "space" | "point" | "profile" | "unknown";

// We generate the "category" based on the URL inside the hook,
// but if you want to overwrite it, just pass it with the "params"
interface IEventParams extends Omit<TrackEventParams, "category"> {
  category?: string;
}

// You can pass the "debug" param if you want to see the requests in the dev mode
function UseTracker(debug?: boolean) {
  const { trackEvent: matomoTrackEvent, trackPageView: matomoTrackView } =
    useMatomo();
  const { name: tenantId, spaceId, pointId } = useParams();
  const location = useLocation();
  const devMode = process.env.REACT_APP_NODE_ENV === "development";
  const ignoreTracker = devMode || (devMode && !debug);

  const category: TCategory = pointId
    ? "point"
    : spaceId
    ? "space"
    : tenantId
    ? "tenant"
    : location.pathname === "/profile"
    ? "profile"
    : "unknown";

  const trackEvent = (params: IEventParams) => {
    const hasCategory = "category" in params;
    const parameters = hasCategory
      ? (params as TrackEventParams)
      : { ...params, category };
    if (!ignoreTracker) matomoTrackEvent(parameters);
  };

  const trackPageView = (params: TrackPageViewParams) => {
    if (!ignoreTracker) matomoTrackView(params);
  };

  return { trackEvent, trackPageView };
}

export default UseTracker;
