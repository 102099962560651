import * as React from "react";
import {
  TextField as MuiTextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import { inputColors } from "../../../common/styles/material-ui-styles";

const StyledTextField = styled(MuiTextField)<TextFieldProps>(
  ({ theme, InputProps }) => ({
    color: theme.palette.common.grey[5],

    // default styles
    "& .MuiFormControl-root": {
      border: `1px solid ${theme.palette.common.grey[3]}`,
      boxShadow: "none",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: InputProps?.readOnly
        ? "none"
        : `1px solid ${theme.palette.common.grey[4]}`,
    },

    "& .MuiOutlinedInput-input": {
      color: theme.palette.common.grey[5],
    },

    "& .MuiFormLabel-root": {
      color: theme.palette.common.grey[3],
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.common.grey[5],
    },

    "& .MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.common.grey[3],
    },

    "& .MuiFormLabel-filled": {
      color: theme.palette.common.grey[5],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.common.grey[5],
    },

    "& .MuiFormHelperText-root": {
      color: theme.palette.common.grey[5],
    },

    // success
    "& .MuiOutlinedInput-root.MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline":
      {
        border: `1px solid ${theme.palette.success.dark}`,
      },
    "& .MuiInputLabel-root.Mui-focused.MuiFormLabel-colorSuccess": {
      color: theme.palette.common.grey[5],
    },
    "& .MuiFormLabel-colorSuccess": {
      color: theme.palette.common.grey[5],
    },

    // disabled styles
    "& .MuiOutlinedInput-root.Mui-disabled": {
      backgroundColor: theme.palette.common.grey[1],
      ".MuiInputLabel-shrink": { color: theme.palette.common.grey[3] },
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.common.grey[2]}`,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiFormLabel-filled": {
      color: theme.palette.common.grey[4],
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiFormHelperText-root": {
      color: theme.palette.common.grey[3],
    },

    // error
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
      color: theme.palette.error.dark,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.error.dark}`,
    },
    "& .Mui-error.MuiFormLabel-filled": {
      color: theme.palette.error.dark,
    },
    "& .Mui-error.MuiInputLabel-shrink": {
      color: theme.palette.error.dark,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiFormHelperText-root": {
      color: theme.palette.error.dark,
    },

    // hover
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `${inputColors.inactiveOutlineBorder}`,
        border: InputProps?.readOnly && "none",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.green[3],
        border: InputProps?.readOnly && "none",
      },
      "& svg": {
        display: InputProps?.readOnly && "none",
      },
    },
  })
);

const TextField = ({ onChange, ...props }: TextFieldProps) => {
  return <StyledTextField {...props} variant="outlined" onChange={onChange} />;
};

export default TextField;
