import React from "react";
import { useParams, Link } from "react-router-dom";
import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { isNil } from "common/utils";
import { convertISO } from "../../tenant.utils";
import { IPoint } from "../../tenant.interface";
import useTracker from "hooks/useTracker";
import NoDataBox from "../noDataBox/noDataBox";
import Spinner from "common/assets/spinner/spinner";

interface IPointsTableProps {
  points: IPoint[] | undefined;
  loading: boolean;
  onCreatePoint?: () => void;
}

function TenantsTable({ points, loading, onCreatePoint }: IPointsTableProps) {
  const { trackEvent } = useTracker();
  const { name } = useParams();
  const hasData = points?.length;
  const empty = !hasData && onCreatePoint;

  if (loading) return <Spinner />;

  if (empty) {
    return (
      <StyledLayout>
        <StyledLayout>
          <NoDataBox
            sx={{ margin: "48px 0" }}
            variant="POINT"
            onClick={onCreatePoint}
          />
        </StyledLayout>
      </StyledLayout>
    );
  }

  if (hasData) {
    return (
      <StyledLayout>
        <StyledTable
          sx={{ maxWidth: 800, tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <StyledTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Space</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>External id</TableCell>
              <TableCell>Last active</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {points.map((point) =>
              // Backend cache invalidation issue workaround
              !isNil(point?.space) ? (
                <TableRow
                  onClick={() => trackEvent({ action: "clickedOpenPoint" })}
                  key={point.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  component={StyledLink}
                  to={`/t/${name}/s/${point.space.id}/p/${point.id}`}
                >
                  <StyledTableCell component="th" scope="row">
                    {point.name}
                  </StyledTableCell>
                  <StyledTableCell>{point.space.name}</StyledTableCell>
                  <StyledTableCell>{point.id}</StyledTableCell>
                  <StyledTableCell>{point.externalId}</StyledTableCell>
                  <TableCell>{convertISO(point.lastActive) || "-"}</TableCell>
                </TableRow>
              ) : null
            )}
          </TableBody>
        </StyledTable>
      </StyledLayout>
    );
  }

  return null;
}

const StyledLayout = styled("div")`
  margin: 28px 0;
`;

const StyledTable = styled(Table)`
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: none;
  border-radius: 0;
`;

const StyledTableHead = styled(TableHead)`
  background: ${({ theme }) => theme.palette.common.white};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 320px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default TenantsTable;
