import * as React from "react";
import { Box } from "@mui/material";
import Label from "../../atoms/Label/Label";
import { CartType } from "./Card";

interface CardTitleProps {
  children: React.ReactNode;
  variant?: CartType;
}

export const CardTitle: React.FC<CardTitleProps> = ({
  children,
  variant = "default",
}) => {
  const labelVariant = variant === "space" ? "HeadingXS" : "HeadingM";
  const mb = variant === "space" ? 1 : 3;
  return (
    <Box sx={{ mb: mb }}>
      <Label style={{ wordBreak: "break-word" }} variant={labelVariant}>
        {children}
      </Label>
    </Box>
  );
};

export default CardTitle;
