import { gql } from "@apollo/client";

export const CREATE_SPACE = gql`
  mutation CREATE_SPACE($input: CreateSpaceInput!) {
    space {
      create(input: $input) {
        name
        normalizedName
        createdAt
        updatedAt
        id
        __typename
        parent {
          id
          name
        }
      }
    }
  }
`;
