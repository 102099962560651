import React from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { DELETE_MEMBER } from "./deleteMember.gql";
import Modal from "lib/organisms/Modal/Modal";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import { Container } from "common/styles/material-ui-styles";
import Spinner from "common/assets/spinner/spinner";

interface IProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
  member: {
    id: string;
    displayName: string;
  };
}

function DeleteMemberModal({ open, onClose, tenantId, member }: IProps) {
  const [deleteMember, { error, loading }] = useMutation(DELETE_MEMBER, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    refetchQueries: [GET_TENANT],
  });

  const onSubmit = async () => {
    await deleteMember({
      variables: { input: { id: member.id } },
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      title="Delete member"
      footer={{
        okText: "Delete member",
        onOk: onSubmit,
        onCancel: onClose,
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <p>
          Are you sure you want to delete the “{member.displayName}” member?
        </p>
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default DeleteMemberModal;
