import React, { ReactNode } from "react";

import "./Modal.modals.cmp.css";

type IModalProps = {
  width?: number;
  height?: number;
  type: "basic" | "withBg" | "spinner";
  children: ReactNode;
};

const Modal: React.FC<IModalProps> = (props) => {
  const { width, height, type, children } = props;

  const styles: { [key: string]: React.CSSProperties } = {
    basic: {
      position: "fixed",
      zIndex: 500,
      backgroundColor: "white",
      width: `${width}px`,
      height: `${height}px`,
      padding: 0,
      marginTop: `-${height ? height / 2 : 100}px`,
      marginLeft: `-${width ? width / 2 : 100}px`,
      left: "50%",
      top: `50%`,
      boxSizing: "border-box",
      transition: "all 0.3s ease-out",
      borderRadius: 20,
    },
    withBg: {
      position: "fixed",
      zIndex: 1000,
      backgroundColor: "white",
      width: `${width}px`,
      height: `${height}px`,
      padding: 0,
      marginTop: `-${height ? height / 2 : 100}px`,
      marginLeft: `-${width ? width / 2 : 100}px`,
      left: "50%",
      top: `50%`,
      boxSizing: "border-box",
      transition: "all 0.3s ease-out",
      borderRadius: 20,
    },
    spinner: {
      position: "fixed",
      zIndex: 1000,
      backgroundColor: "transparent",
      width: `${width}px`,
      height: `${height}px`,
      padding: 0,
      marginTop: `-${height ? height / 2 : 100}px`,
      marginLeft: `-${width ? width / 2 : 100}px`,
      left: "50%",
      top: `50%`,
      boxSizing: "border-box",
      transition: "all 0.3s ease-out",
      borderRadius: 20,
    },
  };

  const renderType = {
    basic: <div style={styles.basic}>{children}</div>,
    withBg: (
      <div className="outer-modal-with-bg">
        <div style={styles.withBg}>{children}</div>
      </div>
    ),
    spinner: (
      <div className="outer-modal-spinner">
        <div style={styles.spinner}>{children}</div>
      </div>
    ),
  };

  return renderType[type] ? renderType[type] : null;
};

export default Modal;
