import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import useSpaceOptions from "hooks/useSpaceOptions";
import useToggle from "hooks/useToggle";
import useNotification from "hooks/useNotification";
import useFieldMode from "hooks/useFieldMode";
import { ISpaces, ISpaceWithPoints } from "../../tenant.interface";
import { Dictionary, IOption } from "common/interfaces/shared.interface";
import { UPDATE_SPACE } from "./manageSpace.gql";
import { GET_SPACE, GET_SPACES } from "common/graphql/space.gql";
import {
  Container,
  StyledAutocomplete,
} from "common/styles/material-ui-styles";
import { shouldUpdateTree } from "lib/organisms/Navbar/useNavbar";
import IconButton from "lib/atoms/Button/IconButton";
import TextField from "lib/atoms/TextField/TextField";
import Metadata from "lib/atoms/Metadata/Metadata.cmp";
import FieldModeButtons from "../fieldModeButtons/fieldModeButtons.cmp";
import DeleteSpaceModal from "../deleteSpaceModal/deleteSpaceModal.cmp";

interface IManageSpaceProps {
  name: string;
  metadata: string;
  tenantId: string;
  spaceId: string;
}

const LABELS: Dictionary = {
  name: "Space name",
  metadata: "Metadata",
  parent: "Parent Space",
};

const FIELD_NAMES = ["parent", "name", "metadata"] as const;
type GenericField = typeof FIELD_NAMES;
type UnionField = typeof FIELD_NAMES[number];
type TParent = { label: string; value: string } | null;

interface IState {
  parent: TParent;
  name: string;
  metadata: string;
}

const getParentId = (value: string | undefined) =>
  value?.length ? value : null; // pass null if empty string (MUI lib limitations)

// temporary solution
const normalizeData = (fieldName: keyof IState, value: any) => {
  switch (fieldName) {
    case "parent":
      return { parentId: getParentId(value?.value) };

    case "metadata":
      return { metadata: JSON.parse(value) };

    default:
      return { [fieldName]: value };
  }
};

function ManageSpace({ metadata, name, tenantId, spaceId }: IManageSpaceProps) {
  const [values, setValues] = useState<IState>({
    parent: {
      label: "",
      value: "",
    },
    name: "",
    metadata: "{}",
  });

  const [updateSpace] = useMutation(UPDATE_SPACE, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    update: (store, { data }) => {
      const spacesData = store.readQuery<ISpaces>({ query: GET_SPACES });
      const updateSpaceData = data.space.update;
      const currentSpace = store.readQuery<ISpaceWithPoints>({
        query: GET_SPACE,
        variables: {
          spaceInput: { id: { _EQ: spaceId } },
          pointsInput: {},
        },
      });

      if (currentSpace && "space" in currentSpace) {
        store.writeQuery<ISpaceWithPoints>({
          query: GET_SPACE,
          variables: {
            spaceInput: { id: { _EQ: spaceId } },
            pointsInput: {},
          },
          data: {
            space: {
              children: currentSpace.space.children,
              id: currentSpace.space.id,
              points: currentSpace.space.points,
              metadata: updateSpaceData.metadata,
              name: updateSpaceData.name,
            },
          },
        });
      }

      if (spacesData && "spaces" in spacesData) {
        store.writeQuery<ISpaces>({
          query: GET_SPACES,
          data: {
            spaces: {
              __typename: "SpacesConnection",
              nodes: spacesData.spaces.nodes.map((node) =>
                node.id === updateSpaceData.id ? updateSpaceData : node
              ),
            },
          },
        });
      }
    },
  });

  const { parentOptions, defaultOption } = useSpaceOptions(tenantId, "PARENT");
  const { open: showModal, toggle: toggleModal } = useToggle();
  const { notify } = useNotification();
  const { fieldMode, setEditMode, setViewMode } = useFieldMode<
    GenericField,
    UnionField
  >(FIELD_NAMES);

  const onChange = (fieldName: string, value: string | IOption) =>
    setValues((prevState) => ({ ...prevState, [fieldName]: value }));

  const setPreviousValue = (fieldName: string) => {
    const props: Dictionary<IOption> = {
      metadata,
      name,
      parent: defaultOption,
    };
    onChange(fieldName, props[fieldName]);
  };

  const onCancel = (fieldName: string) => {
    setViewMode(fieldName);
    setPreviousValue(fieldName);
  };

  const onSave = async (fieldName: keyof IState, id: string) => {
    const value = values[fieldName];
    const data = normalizeData(fieldName, value);
    try {
      await updateSpace({
        variables: { input: { data, id } },
      });
      setViewMode(fieldName);
      notify.success(`"${LABELS[fieldName]}" has been successfully updated`);
      sessionStorage.removeItem("navigation");
      shouldUpdateTree(!shouldUpdateTree());
    } catch (e: any) {
      notify.error(e?.message);
    }
  };

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      name: name,
      metadata: metadata,
    }));
  }, [name, metadata]);

  useEffect(() => {
    setValues((prevState) => ({ ...prevState, parent: defaultOption }));
  }, [defaultOption.value]);

  return (
    <StyledContainer>
      <StyledRow>
        <StyledColumn style={{ minWidth: "382px" }}>
          <StyledAutocomplete
            id="outlined-required"
            placeholder="e.g. Oslo kommune"
            options={parentOptions}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            onChange={(event: any, option: any) =>
              setValues({ ...values, parent: option })
            }
            value={values.parent}
            sx={{
              mt: 2,
            }}
            style={{ width: "100%" }}
            readOnly={fieldMode.parent === "view"}
            renderInput={(params) => (
              <TextField {...params} label={LABELS.parent} />
            )}
          />
        </StyledColumn>
        <StyledColumn style={{ margin: "12px 0px 0px 16px" }}>
          <FieldModeButtons
            mode={fieldMode.parent}
            fieldName="parent"
            id={spaceId}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
          />
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn style={{ minWidth: "382px" }}>
          <TextField
            id="outlined-required"
            label={LABELS.name}
            type="text"
            style={{ width: "100%" }}
            value={values.name}
            onChange={(e) => onChange("name", e.target.value)}
            InputProps={{
              readOnly: fieldMode.name === "view",
            }}
          />
        </StyledColumn>
        <StyledColumn style={{ margin: "6px 0px 0px 16px" }}>
          <FieldModeButtons
            mode={fieldMode.name}
            fieldName="name"
            id={spaceId}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
          />
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn style={{ minWidth: "382px" }}>
          <Metadata
            id="outlined-required"
            label={LABELS.metadata}
            type="text"
            style={{ width: "100%" }}
            value={values.metadata}
            onChange={(e: any) => onChange("metadata", e.target.value)}
            InputProps={{
              readOnly: fieldMode.metadata === "view",
            }}
            minRows={18}
            maxRows={18}
            multiline
          />
        </StyledColumn>
        <StyledColumn style={{ margin: "12px 0px 0px 16px" }}>
          <FieldModeButtons
            mode={fieldMode.metadata}
            fieldName="metadata"
            id={spaceId}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
          />
        </StyledColumn>
      </StyledRow>
      <StyledRow style={{ justifyContent: "flex-end", margin: "32px 0 0 0" }}>
        <IconButton variant="neutral" onClick={toggleModal}>
          <ClearIcon />
          Delete space
        </IconButton>
      </StyledRow>
      <DeleteSpaceModal
        open={showModal}
        tenantId={tenantId}
        spaceName={name}
        spaceId={spaceId}
        onCancel={toggleModal}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  padding: 16px 32px 32px 32px;
  margin: 48px 0;
`;

const StyledRow = styled("div")`
  display: flex;
  margin: 32px 0 0 0;
`;

const StyledColumn = styled("div")`
  display: inline-block;
`;

export default ManageSpace;
