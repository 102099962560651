import { gql } from "@apollo/client";

export const CREATE_SIGNAL = gql`
  mutation CREATE_SIGNAL($input: CreateSignalInput!) {
    signal {
      create(input: $input) {
        timestamp
        type
        unit
        id
        createdAt
        pointId
      }
    }
  }
`;
