import React from "react";
import { OutlinedTextFieldProps, MenuItem, styled } from "@mui/material";
import { IOption } from "common/interfaces/shared.interface";
import { getOptionValue } from "pages/tenant/tenant.utils";
import TextField from "lib/atoms/TextField/TextField";

interface Option {
  label: string;
  value: string | string[] | number;
  key?: string;
  disabled?: boolean;
}

interface IDefaultDropdownProps extends OutlinedTextFieldProps {
  options: Array<Option>;
  value?: IOption | string | string[];
}

function DefaultDropdown({ options, value, ...props }: IDefaultDropdownProps) {
  return (
    <TextField {...props} select value={getOptionValue(value)}>
      {options.map((option) => (
        <StyledMenu
          disabled={option.disabled || false}
          value={option.value}
          key={option.key || `${option.value}`}
        >
          {option.label}
        </StyledMenu>
      ))}
    </TextField>
  );
}

const StyledMenu = styled(MenuItem)`
  // need to figure out how to change the .selected color
  &:hover {
    background: #f7f8fc;
  }
`;

export default DefaultDropdown;
