import { gql } from "@apollo/client";

export const GET_POINTS = gql`
  query GET_POINTS($pointsInput: PointFilterInput) {
    points(
      where: $pointsInput
      paginate: { first: 100 }
      sort: { field: "id", order: ASC }
    ) {
      nodes {
        name
        externalId
        id
        createdAt
        lastActive
        space {
          name
          id
        }
      }
    }
  }
`;

export const GET_POINT = gql`
  query GET_POINT($input: PointFilterInput!) {
    point(input: $input) {
      name
      id
      externalId
      metadata
      createdAt
      lastActive
      spaceId
      existingSignalTypes
    }
  }
`;

export const UPDATE_POINT = gql`
  mutation UPDATE_POINT($input: UpdatePointInput!) {
    point {
      update(input: $input) {
        __typename
        name
        id
        externalId
        metadata
        createdAt
        lastActive
        spaceId
        existingSignalTypes
        space {
          __typename
          name
          id
        }
      }
    }
  }
`;

export const DELETE_POINT = gql`
  mutation DELETE_POINT($input: DeletePointInput!) {
    point {
      delete(input: $input) {
        id
      }
    }
  }
`;
