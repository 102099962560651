import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import * as R from "ramda";
import useWebhookModal from "./useWebhookModal";
import useFormBuilder from "./useFormBuilder";
import useNotification from "hooks/useNotification";
import { TAuth, TFormState } from "./webhooks.interface";
import {
  invalidValues,
  invalidAuth,
  normalizeFormData,
} from "./webhooks.utils";
import Modal from "lib/organisms/Modal";
import WebhookModalForm from "./webhookForm.cmp";

interface IProps {
  open: boolean;
  onClose: () => void;
}

function CreateWebhookModal({ open, onClose }: IProps) {
  const { trackEvent } = useMatomo();
  const { notify } = useNotification();
  const { name: tenantId } = useParams();
  const { createWebhook, values, authFields, onChange, onClearForm } =
    useWebhookModal(tenantId || "");

  const onSubmit = async (
    formState: TFormState,
    additionalFieldsState: TAuth
  ) => {
    trackEvent({ category: "tenant", action: "clickedCreateWebhook" });
    const input = normalizeFormData(formState, additionalFieldsState);

    try {
      await createWebhook({ variables: { input } });
      notify.success("Webhook has been successfully created");
      onClose();
    } catch (e: any) {
      notify.error(e?.message);
    }
  };

  const { values: formValues, formElements } = useFormBuilder(
    authFields,
    values.auth.type
  );

  const disabledOkButton = R.or(invalidValues(values), invalidAuth(formValues));

  useEffect(() => onClearForm(), [open]);

  return (
    <Modal
      open={open}
      title="Create Webhook"
      footer={{
        okText: "Connect",
        onOk: () => onSubmit(values, formValues),
        onCancel: onClose,
        okButtonProps: {
          disabled: disabledOkButton,
        },
      }}
      style={{ width: "467px" }}
    >
      <>
        <div>
          Create a webhook to be notified, when an important event happened.
        </div>
        <WebhookModalForm values={values} onChange={onChange}>
          {formElements}
        </WebhookModalForm>
      </>
    </Modal>
  );
}

export default CreateWebhookModal;
