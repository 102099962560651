import React, { useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material";
import { paramCase } from "change-case";
import useTracker from "hooks/useTracker";
import { isEmptyObject } from "common/utils";
import { CREATE_TENANT } from "./createTenant.gql";
import { GET_TENANTS, GET_TENANT } from "common/graphql/tenant.gql";
import { PlanPriceType } from "common/interfaces/tenant.interface";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import Modal from "lib/organisms/Modal/Modal";
import TextField from "lib/atoms/TextField/TextField";
import Spinner from "common/assets/spinner/spinner";
import { shouldUpdateTree } from "../../organisms/Navbar/useNavbar";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";
import { useNavigate } from "react-router-dom";
import useNotification from "hooks/useNotification";
import { Paragraph, theme } from "@gilbarbara/components";

interface Option {
  label: string;
  value: string | string[] | number;
  key?: string;
  disabled?: boolean;
}

interface ICreateTenant {
  name: string;
  planPriceId: PlanPriceType;
  id?: string;
}

interface ICreateTenantProps {
  open: boolean;
  onClose: () => void;
  options: Option[];
}

const defaultValues = {
  name: "",
  planPriceId: "" as PlanPriceType,
  id: "",
};

function CreateTenatModal({ open, onClose, options }: ICreateTenantProps) {
  const { trackEvent } = useTracker();
  const navigate = useNavigate();
  const { notify } = useNotification();
  const client = useApolloClient();
  const {
    formState,
    control,
    setValue,
    getValues,
    handleSubmit,
    reset: resetForm,
  } = useForm<ICreateTenant>({
    mode: "onChange",
    defaultValues,
  });

  const [createTenant, { loading, error, reset: resetTenant }] = useMutation(
    CREATE_TENANT,
    {
      refetchQueries: [GET_TENANTS, GET_TENANT],
    }
  );

  const onSubmit = async () => {
    const validation = isEmptyObject(formState.errors);
    if (validation) {
      trackEvent({ category: "tenant", action: "clickedCreateTenant" });
      const values = getValues();
      const tenant = await createTenant({
        variables: { input: values },
      });
      shouldUpdateTree(!shouldUpdateTree());
      notify.success(
        `Tenant "${tenant?.data?.tenant?.create?.id}" has been successfully Created!`
      );
      onClose();
      if (tenant?.data) {
        await client.resetStore();
        navigate(`/overview/${tenant?.data?.tenant?.create?.id}`, {
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    resetForm();
    resetTenant();
  }, [open, resetForm, resetTenant]);

  return (
    <Modal
      open={open}
      title=""
      footer={{
        okText: "Create Tenant",
        onCancel: onClose,
        okButtonProps: {
          type: "submit",
          form: "createTenant",
          disabled: !formState.isDirty || !formState.isValid,
        },
      }}
      style={{ maxWidth: "467px" }}
    >
      <>
        <Paragraph
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "43px",
            color: "#010032",
          }}
        >
          Create tenant
        </Paragraph>
        <Paragraph
          style={{
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "25px",
            marginTop: 8,
            color: "#010032",
          }}
        >
          The tenant can be considered your home folder. After creating a tenant
          you can start adding spaces and points with the structure you need.{" "}
          <br />
          <br />
          For more information about how to create a tenant by using our API,
          read our
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dimensionfour.io/resources/user-guide/set-up-your-tenant"
            style={{
              textDecoration: "underline",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
              color: "#010032",
            }}
          >
            &nbsp;documentation.
          </a>
        </Paragraph>
        <br />
        <form id="createTenant" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            rules={{ required: "Name is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledInput
                id="outlined-required"
                label="Name*"
                placeholder="e.g. Company name"
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  onChange(value);
                  setValue("id", paramCase(value));
                }}
                value={value}
                error={!!error}
                helperText={error?.message ?? " "}
                sx={{
                  mt: 2,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="id"
            render={({ field: { value } }) => (
              <StyledInput
                id="outlined-required"
                label="Id (for API call)"
                placeholder="your-api-id"
                value={value}
                sx={{
                  mt: 2,
                }}
                disabled
              />
            )}
          />
          <Controller
            control={control}
            name="planPriceId"
            render={({ field: { value, onChange } }) => (
              <DefaultDropdown
                variant="outlined"
                id="outlined-required"
                label="Pricing Plan*"
                placeholder="Choose the pricing plan"
                options={options}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  onChange(value);
                }}
                value={value}
                sx={{
                  mt: 5,
                  width: "100%",
                }}
              />
            )}
          />
        </form>
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </>
    </Modal>
  );
}

const StyledInput = styled(TextField)`
  width: 100%;
`;

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default CreateTenatModal;
