import * as React from "react";
import {
  TextField as MuiTextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor:
      theme.palette.mode === "light"
        ? "transparent"
        : theme.palette.primary.main,
    border: "0.1rem solid rgba(82, 88, 235, 0.5)",
    fontSize: 16,
    padding: "10px 12px",
    color: "#FFFFFF",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["poppins"].join(","),
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: `${alpha("#FFA654", 1)} 0 0 0 0.1rem`,
      borderColor: "#FFA654",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
}));

const TextField = ({ onChange, ...props }: TextFieldProps) => {
  return <StyledTextField {...props} onChange={onChange} />;
};

export default TextField;
