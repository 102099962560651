import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { IProfile } from "common/interfaces/shared.interface";
import { GET_PROFILE } from "common/graphql/profile.gql";
import Layout from "cmp/templates/layoutTemplate.cmp";
import TenantAccess from "./components/tenantAccess/tenantAccess.cmp";
import MemberProfile from "./components/memberProfile/memberProfile.cmp";

function ProfilePage() {
  const { section } = useParams();
  const {
    data: { profile } = {
      profile: { firstname: "", lastname: "", id: "", primaryEmail: "" },
    },
  } = useQuery<IProfile>(GET_PROFILE);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: "Profile page" });
  }, []);

  return (
    <Layout>
      {section === "settings" && <MemberProfile user={profile} />}
      {section === "tenant-access" && <TenantAccess user={profile} />}
    </Layout>
  );
}

export default ProfilePage;
