import React, { useEffect, useRef } from "react";
import { SelectChangeEvent, styled } from "@mui/material";
import { IOption } from "common/interfaces/shared.interface";
import {
  AUTH_TYPES,
  ENTITY_TYPES,
  EVENT_TYPES,
  initialState,
  REQUEST_TYPES,
} from "./webhooks.utils";
import TextField from "lib/atoms/TextField/TextField";
import { Container } from "common/styles/material-ui-styles";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";

function WebhookModalForm({
  values,
  onChange,
  children,
}: {
  values: typeof initialState;
  onChange: (fieldName: string, value: string | IOption | boolean) => void;
  children: React.ReactElement;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerScrollHeight = containerRef?.current?.scrollHeight;

  useEffect(() => {
    containerRef?.current?.scrollTo({
      left: 0,
      top: containerScrollHeight,
      behavior: "smooth",
    });
  }, [children.key]); // workaround to scroll after the "formElements" render

  return (
    <StyledContainer ref={containerRef} key="container">
      <TextField
        id="outlined-required"
        label="Name"
        placeholder="e.g. Name of your Webhook"
        onChange={({ target: { value } }) => onChange("name", value)}
        value={values.name}
        sx={{
          mt: 2,
        }}
        style={{ width: "100%", marginTop: "16px" }}
        autoFocus
      />
      <TextField
        id="outlined-required"
        label="Endpoint"
        placeholder="e.g. Name of your Endpoint"
        onChange={({ target: { value } }) => onChange("endpoint", value)}
        value={values.endpoint}
        sx={{
          mt: 2,
        }}
        style={{ width: "100%", marginTop: "16px" }}
      />
      <DefaultDropdown
        variant="outlined"
        label="RequestType"
        placeholder="RequestType"
        onChange={({ target: { value } }) => onChange("requestType", value)}
        value={values.requestType}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
        options={REQUEST_TYPES}
        select
      />
      <DefaultDropdown
        variant="outlined"
        label="EntityType"
        placeholder="EntityType"
        onChange={({ target: { value } }) => onChange("entityType", value)}
        value={values.entityType}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
        options={ENTITY_TYPES}
        select
      />
      <DefaultDropdown
        variant="outlined"
        label="EventType"
        placeholder="EventType"
        onChange={({ target: { value } }) => onChange("eventType", value)}
        value={values.eventType}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
        options={EVENT_TYPES}
        select
      />
      <DefaultDropdown
        variant="outlined"
        label="Auth"
        placeholder="Auth"
        SelectProps={{
          onChange: (e: SelectChangeEvent<any>, child) =>
            onChange("auth", e.target.value),
          displayEmpty: true,
        }}
        InputLabelProps={{ shrink: true }}
        value={values.auth.type}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
        options={AUTH_TYPES}
        select
      />
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  max-height: 600px;
  overflow-y: scroll;
`;

export default WebhookModalForm;
