import React, { ChangeEvent, useEffect, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import useTracker from "hooks/useTracker";
import { CREATE_TOKEN } from "./addToken.gql";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { ICreateDataState } from "./addToken.interface";
import Button from "lib/atoms/Button/Button";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import TextField from "lib/atoms/TextField/TextField";
import Spinner from "common/assets/spinner/spinner";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";

interface IAddTokenFormProps {
  tenantId: string | undefined;
  setValues: Dispatch<SetStateAction<ICreateDataState>>;
  values: ICreateDataState;
  onCancel: () => void;
}

const options = {
  spaceNpoint: [
    { label: "Read", value: ["READ_SPACE", "READ_POINT"] },
    {
      label: "Read / Write",
      value: [
        "READ_SPACE",
        "READ_POINT",
        "CREATE_SPACE",
        "CREATE_POINT",
        "DELETE_SPACE",
        "DELETE_POINT",
        "UPDATE_SPACE",
        "UPDATE_POINT",
      ],
    },
    { label: "No Access", value: [""] },
  ],
  signal: [
    { label: "Read", value: ["READ_SIGNAL"] },
    {
      label: "Read / Write",
      value: ["READ_SIGNAL", "CREATE_SIGNAL", "DELETE_SIGNAL"],
    },
    { label: "No Access", value: [""] },
  ],
};

function AddTokenForm({
  tenantId,
  setValues,
  values,
  onCancel,
}: IAddTokenFormProps) {
  const { trackEvent } = useTracker();
  const [createToken, { error, loading, reset: resetToken }] = useMutation(
    CREATE_TOKEN,
    {
      context: {
        headers: {
          "x-tenant-id": tenantId,
        },
      },
      refetchQueries: [GET_TENANT],
    }
  );

  const buttonDisabled = !values.name || !values.signal || !values.spaceNpoint;

  const onChange = (
    name: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = async () => {
    trackEvent({ action: "clickedCreateAccessToken" });
    const { name, spaceNpoint, signal } = values;
    // Normalizing data before sending to the Backend
    // Removing empty strings ('No Access').
    const scopes =
      Array.isArray(spaceNpoint) &&
      Array.isArray(signal) &&
      [...spaceNpoint, ...signal].filter((e) => e);
    const { data } = await createToken({
      variables: { input: { name, scopes } },
    });
    const { token } = data.accessToken.create;
    setValues({ ...values, token });
  };

  useEffect(() => {
    resetToken();
  }, [resetToken]);

  return (
    <>
      <StyledText style={{ margin: "0 0 24px 0" }}>
        With the token a user can access the rights you select from the grid
        below.
      </StyledText>
      <TextField
        id="outlined-required"
        label="Token name*"
        placeholder="e.g. name of token receiver"
        value={values.name}
        onChange={(e) => onChange("name", e)}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
      />
      <StyledHeadline>Permissions</StyledHeadline>
      <DefaultDropdown
        variant="outlined"
        label="Space / Point*"
        placeholder="Space / Point"
        onChange={(e) => onChange("spaceNpoint", e)}
        value={values.spaceNpoint}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
        options={options.spaceNpoint}
        select
      />
      <DefaultDropdown
        variant="outlined"
        label="Signal*"
        placeholder="Signal"
        onChange={(e) => onChange("signal", e)}
        value={values.signal}
        sx={{
          marginTop: "12px",
        }}
        style={{ width: "100%" }}
        options={options.signal}
        select
      />
      <StyledText style={{ margin: "24px 0 0 0" }}>
        Read the documentation for the realtions between space, point and
        signal&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.dimensionfour.io/concepts/logical-structure"
        >
          here
        </a>
      </StyledText>
      {!!error && (
        <StyledNotification severity="error">
          {error?.message}
        </StyledNotification>
      )}
      {loading ? <Spinner /> : null}
      <StyledFooter>
        <StyledButton
          style={{ marginRight: "10%" }}
          onClick={onCancel}
          variant="secondary"
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={onSubmit}
          disabled={buttonDisabled}
          variant="primary"
        >
          Create token
        </StyledButton>
      </StyledFooter>
    </>
  );
}

const StyledText = styled("p")`
  line-height: 24px;
`;

const StyledHeadline = styled("h4")`
  font-size: 24px;
  font-weight: 500;
  line-height: 125%;
  margin: 15px 0 0 0;
`;

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

const StyledFooter = styled("div")`
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
`;

const StyledButton = styled(Button)`
  width: 45%;
`;

export default AddTokenForm;
