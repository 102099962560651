import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useTracker from "hooks/useTracker";
import useSpaceOptions from "hooks/useSpaceOptions";
import { ITenants } from "hooks/useTenant";
import { IPoints, ISpaceWithPoints } from "../../tenant.interface";
import { IOption } from "common/interfaces/shared.interface";
import { GET_TENANTS } from "common/graphql/tenant.gql";
import { GET_POINTS } from "common/graphql/point.gql";
import { GET_SPACE } from "common/graphql/space.gql";
import { CREATE_POINT } from "./createPoint.gql";
import Modal from "lib/organisms/Modal/Modal";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import TextField from "lib/atoms/TextField/TextField";
import Spinner from "common/assets/spinner/spinner";
import { Container } from "common/styles/material-ui-styles";

interface ICreateSpaceProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
}

interface ICreatePointState {
  name: string;
  parent: IOption | null;
  externalId: string;
}

const initialState = {
  name: "",
  externalId: "",
  parent: {
    label: "",
    value: "",
  },
};

function CreatePointModal({ open, onClose, tenantId }: ICreateSpaceProps) {
  const { trackEvent } = useTracker();
  const [values, setValues] = useState<ICreatePointState>(initialState);
  const { options, defaultOption } = useSpaceOptions(tenantId, "POINT");
  const context = {
    headers: {
      "x-tenant-id": tenantId,
    },
  };

  const [createPoint, { loading, error, reset: resetPoint }] = useMutation(
    CREATE_POINT,
    {
      context,
      update: (store, { data }) => {
        const tenantsData = store.readQuery<ITenants>({ query: GET_TENANTS });
        const pointsList = store.readQuery<IPoints>({
          query: GET_POINTS,
          variables: { pointsInput: {} },
        });
        const currentSpace = store.readQuery<ISpaceWithPoints>({
          query: GET_SPACE,
          variables: {
            spaceInput: { id: { _EQ: values.parent?.value } },
            pointsInput: {},
          },
        });
        const createPointData = data.point.create;

        if (currentSpace && "points" in currentSpace.space) {
          store.writeQuery<ISpaceWithPoints>({
            query: GET_SPACE,
            variables: {
              spaceInput: { id: { _EQ: values.parent?.value } },
              pointsInput: {},
            },
            data: {
              space: {
                ...currentSpace.space,
                points: {
                  nodes: [...currentSpace.space.points.nodes, createPointData],
                },
              },
            },
          });
        }

        if (pointsList && "points" in pointsList) {
          store.writeQuery<IPoints>({
            query: GET_POINTS,
            data: {
              points: { nodes: [...pointsList.points.nodes, createPointData] },
            },
          });
        }

        if (tenantsData && "tenants" in tenantsData) {
          store.writeQuery<ITenants>({
            query: GET_TENANTS,
            data: {
              tenants: tenantsData.tenants.map((tenant) =>
                tenant.id !== tenantId
                  ? tenant
                  : { ...tenant, totalPoints: tenant.totalPoints + 1 }
              ),
            },
          });
        }
      },
    }
  );

  const onSubmit = async () => {
    const { name, parent, externalId } = values;
    const normalizedValues = parent?.value
      ? { name, externalId, spaceId: parent.value } // It's space's parent, not point's parent!
      : { name, externalId };
    await createPoint({
      variables: {
        input: {
          ...normalizedValues,
        },
      },
    });
    trackEvent({ action: "clickedCreatePoint" });
    onClose();
  };

  useEffect(() => {
    setValues(initialState);
    resetPoint();
  }, [open, resetPoint]);

  useEffect(() => {
    setValues((prevState) => ({ ...prevState, parent: defaultOption }));
  }, [open]);

  return (
    <Modal
      open={open}
      title="Create point"
      footer={{
        okText: "Create point",
        onOk: onSubmit,
        onCancel: onClose,
        okButtonProps: {
          disabled: !values.name || !values.parent?.value,
        },
      }}
      style={{ width: "467px" }}
    >
      <Container>
        <TextField
          id="outlined-required"
          label="Name*"
          placeholder="e.g. Temperature reader 1"
          onChange={({ target: { value } }) =>
            setValues({ ...values, name: value })
          }
          value={values.name}
          sx={{
            mt: 2,
          }}
          style={{ width: "100%", marginTop: "16px" }}
        />
        <Autocomplete
          id="outlined-required"
          placeholder="e.g. Oslo kommune"
          options={options}
          onChange={(event, value) => setValues({ ...values, parent: value })}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          value={values.parent}
          sx={{
            mt: 2,
          }}
          style={{ width: "100%", marginTop: "16px" }}
          renderInput={(params) => <TextField {...params} label="Space*" />}
        />
        <TextField
          id="outlined-required"
          label="External Id"
          placeholder="e.g. 123123"
          onChange={({ target: { value } }) =>
            setValues({ ...values, externalId: value })
          }
          value={values.externalId}
          sx={{
            mt: 2,
          }}
          style={{ width: "100%", marginTop: "16px" }}
        />
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default CreatePointModal;
