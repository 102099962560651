import React, { useState } from "react";
import { styled } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MQQTType } from "common/interfaces/tenant.interface";
import TextField from "lib/atoms/TextField/TextField";
import {
  StyledHeader,
  StyledHeadline,
  StyledTable,
  StyledTableHead,
  StyledContainer,
} from "./manageTenant.cmp";
import IconButton from "lib/atoms/Button/IconButton";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Paragraph, theme } from "@gilbarbara/components";
interface IProps {
  data: MQQTType | undefined;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "500px",
    width: "100%",
    background: "#010032",
    boxShadow: "3px 4px 25px rgba(30, 26, 44, 0.02)",
    borderRadius: "8px",
    padding: 24,
    transform: "translate(-23px, 0) !important",
    cursor: "pointer !important",
  },
  "& .MuiTooltip-arrow": {
    color: "#010032",
    width: "7em",
    height: "2em",
    transform: "translate(16px, -12px) !important",
  },
  [`& .${tooltipClasses.popper}`]: {
    transform: "translate(394px, 442px) !important",
    cursor: "pointer !important",
  },
});
function MQTT({ data }: IProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [showPassword, setShowPassword] = useState(false);
  const inputType = showPassword ? "text" : "password";

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeadline>
          MQTT&nbsp;
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomWidthTooltip
                open={open}
                title={
                  <>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "20px",
                        lineHeight: "25px",
                      }}
                    >
                      Access token
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        marginTop: 8,
                      }}
                    >
                      MQTT is designed to be an efficient and low-overhead
                      protocol for transferring data between devices.
                      <br />
                      <br />
                      Learn more about MQTT in our &nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://dimensionfour.io/resources/user-guide/connect-your-sensor/mqtt"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "#ffa654",
                          cursor: "pointer",
                        }}
                      >
                        Documentation.
                      </a>
                    </Paragraph>
                  </>
                }
                placement="bottom-start"
                arrow
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  variant="neutral"
                  style={{ border: "none" }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#3237B8",
                      width: "18px !important",
                      height: "18px !important",
                    }}
                  />
                </IconButton>
              </CustomWidthTooltip>
            </div>
          </ClickAwayListener>
        </StyledHeadline>
      </StyledHeader>
      <StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Port</TableCell>
            <TableCell>Password</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          <TableRow key={`${data?.password}${data?.hostname}`}>
            <TableCell component="th" scope="row">
              {data?.username}
            </TableCell>
            <TableCell component="th" scope="row">
              {data?.hostname}
            </TableCell>
            <TableCell component="th" scope="row">
              {data?.port}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StyledInput
                value={data?.password}
                InputProps={{
                  readOnly: true,
                  type: inputType,
                }}
              />
              <StyledVisibilityIcon
                onClick={() => setShowPassword(!showPassword)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </StyledContainer>
  );
}

const StyledVisibilityIcon = styled(VisibilityIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.grey[5]};
  &:hover {
    color: ${({ theme }) => theme.palette.common.grey[4]};
  }
`;

const StyledInput = styled(TextField)`
  color: ${({ theme }) => theme.palette.common.grey[4]};
  input {
    padding: 0;
  }
`;

export default MQTT;
