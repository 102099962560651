import { gql } from "@apollo/client";

export const CREATE_TOKEN = gql`
  mutation CREATE_TOKEN($input: CreateAccessTokenInput!) {
    accessToken {
      create(input: $input) {
        id
        token
        name
      }
    }
  }
`;
