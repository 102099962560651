import React from "react";
import { Box, Grid } from "@mui/material";

export type CartType = "default" | "space";
interface CardProps {
  variant?: CartType;
  children: React.ReactNode;
  className?: string;
}

export const Card = ({
  children,
  variant = "default",
  className,
}: CardProps) => {
  if (variant === "space") {
    const newChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement<any>(child, {
          variant: "space",
        });
      }

      return child;
    });
    return (
      <Box
        className={className}
        sx={{
          maxWidth: "230px",
          padding: "16px",
          display: "flex",
          alignItems: "center",
          boxShadow: "3px 4px 25px 0px #1E1A2C05",
        }}
      >
        {newChildren}
      </Box>
    );
  } else {
    return (
      <Box
        className={className}
        sx={{
          maxWidth: "564px",
          padding: "32px",
          boxShadow: "3px 4px 25px 0px #1E1A2C05",
        }}
      >
        <Grid sx={{ alignItems: "center" }}>{children}</Grid>
      </Box>
    );
  }
};

export default Card;
