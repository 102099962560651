import React, { useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ClearIcon from "@mui/icons-material/Clear";
import { TMember } from "common/interfaces/tenant.interface";
import { GET_TENANTS } from "common/graphql/tenant.gql";
import useTenant, { ITenants } from "hooks/useTenant";
import useNotification from "hooks/useNotification";
import { capitalize } from "common/utils";
import { StyledTableRow } from "common/styles/material-ui-styles";
import Modal from "lib/organisms/Modal";
import MaterialIconButton from "lib/atoms/Button/MaterialIconButton";

const LEAVE_TENANT = gql`
  mutation LEAVE_TENANT($input: DeleteMemberInput!) {
    member {
      delete(input: $input) {
        success
      }
    }
  }
`;

interface IProps {
  user: { firstname: string; lastname: string; id: string };
}

const initialModalState = {
  tenantId: "",
  tenantName: "",
  memberId: "",
  visible: false,
};

const findMember = (members: TMember[], userId: string) =>
  members.find((member) => member.userId === userId);

const getRole = (members: TMember[], userId: string) =>
  findMember(members, userId)?.role || "-";

const getMemberId = (members: TMember[], userId: string) =>
  findMember(members, userId)?.id || "";

function TenantAccess({ user }: IProps) {
  const [modal, setModal] = useState(initialModalState);
  const [leaveTenant] = useMutation(LEAVE_TENANT);
  const { tenants } = useTenant();
  const hasNoTenants = !tenants.length;
  const { notify } = useNotification();

  const tableData = useMemo(
    () =>
      tenants?.map((tenant) => ({
        id: tenant.id,
        name: tenant.name,
        role: capitalize(getRole(tenant.members, user.id)),
        memberId: getMemberId(tenant.members, user.id),
      })),
    [tenants, user.id]
  );

  const onCancel = () => setModal(initialModalState);

  const onOpen = async (
    tenantId: string,
    tenantName: string,
    memberId: string
  ) => setModal({ tenantId, tenantName, memberId, visible: true });

  const onSubmit = async () => {
    try {
      await leaveTenant({
        variables: { input: { id: modal.memberId } },
        context: {
          headers: {
            "x-tenant-id": modal.tenantId,
          },
        },
        update: (store) => {
          const tenantsData = store.readQuery<ITenants>({ query: GET_TENANTS });
          if (tenantsData && "tenants" in tenantsData) {
            store.writeQuery<ITenants>({
              query: GET_TENANTS,
              data: {
                tenants: tenantsData.tenants.filter(
                  (tenant) => tenant.id !== modal.tenantId
                ),
              },
            });
          }
        },
      });
      notify.success(`You left the tenant ${modal.tenantName}`);
    } catch (e: any) {
      notify.error(e?.message);
    } finally {
      onCancel();
    }
  };

  return (
    <>
      <StyledHeadline>Tenant Access</StyledHeadline>
      <StyledUserName>{`${user.firstname} ${user.lastname}`}</StyledUserName>
      <StyledContainer>
        <StyledTitle>Tenant</StyledTitle>
        {hasNoTenants ? (
          <StyledNoData>You are not connected to any tenants</StyledNoData>
        ) : (
          <StyledTable
            sx={{ minWidth: 650, tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <StyledTableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Your role</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Leave tenant</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {tableData?.map((tenant) => (
                <StyledTableRow key={tenant.id}>
                  <TableCell component="th" scope="row">
                    {tenant.name}
                  </TableCell>
                  <TableCell>{tenant.id}</TableCell>
                  <TableCell>{tenant.role}</TableCell>
                  <TableCell
                    sx={{ textAlign: "right", padding: "12px 42px 12px 12px" }}
                  >
                    <MaterialIconButton
                      size="small"
                      color="primary"
                      onClick={() =>
                        onOpen(tenant.id, tenant.name, tenant.memberId)
                      }
                    >
                      <ClearIcon />
                    </MaterialIconButton>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}
      </StyledContainer>
      <Modal
        open={modal.visible}
        title={`Leaving tenant "${modal.tenantName}"`}
        footer={{ okText: "Leave", onOk: onSubmit, onCancel }}
        style={{ maxWidth: "467px" }}
      >
        <ModalText>
          Leaving will remove your access to the tenant. To gain new access you
          will have to contact the tenant owner or admin.
        </ModalText>
      </Modal>
    </>
  );
}

const StyledHeadline = styled("h1")`
  font-size: 36px;
  font-weight: 500;
  line-height: 100%;
`;

const StyledNoData = styled("div")`
  margin: 18px 9px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.common.grey[5]};
`;

const StyledUserName = styled("div")`
  font-size: 24px;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin: 6px 0;
`;

const StyledContainer = styled("div")`
  margin: 48px 0 18px 0;
`;

const StyledTitle = styled("h2")`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
`;

const StyledTable = styled(Table)`
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: none;
  border-radius: 0;
`;

const StyledTableHead = styled(TableHead)`
  background: ${({ theme }) => theme.palette.common.grey[1]};
`;

const ModalText = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export default TenantAccess;
