import * as React from "react";
import { styled } from "@mui/material";

import TabItem from "../../atoms/TabItem/TabItem";
import {
  TabPanelUnstyled,
  TabsListUnstyled,
  TabsListUnstyledProps,
  TabsUnstyled,
} from "@mui/base";
import { useAppContext } from "context";

export interface TabPanelProps {
  isParent?: boolean;
  children: React.ReactNode[];
  defaultValue?: number;
  onChange?: (event: React.SyntheticEvent) => void;
  panels: readonly [string?, string?, string?, string?, string?, string?];
}

interface TabsListStyledProps extends TabsListUnstyledProps {
  numOfElements: number;
}

export const TabsListStyled = styled(TabsListUnstyled)<TabsListStyledProps>(
  ({ numOfElements }) => {
    return {
      display: "inline-grid",
      alignContent: "center",
      width: "100%",
      gridTemplateColumns: `repeat(${numOfElements},1fr)`,
    };
  }
);

const TabsPanel: React.FC<TabPanelProps> = ({
  panels,
  onChange,
  defaultValue,
  isParent = true,
  children,
}) => {
  return (
    <TabsUnstyled value={defaultValue} onChange={onChange}>
      <TabsListStyled numOfElements={panels.length}>
        {panels.map((label, index) => (
          <TabItem
            id={isParent ? label?.toLowerCase().replaceAll(/\s/g, "") : ""}
            key={index}
          >
            {label}
          </TabItem>
        ))}
      </TabsListStyled>
      <React.Fragment>
        {children?.map((child, key) => (
          <TabPanelUnstyled key={key} value={key}>
            {child}
          </TabPanelUnstyled>
        ))}
      </React.Fragment>
    </TabsUnstyled>
  );
};

export default TabsPanel;
