import React, { useEffect, useContext, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Box, useTheme, styled, Skeleton, AppBar } from "@mui/material";
import { TreeView, TreeItem } from "../../molecules/TreeView";
import IconButton from "../../atoms/Button/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, NavLink, useLocation, useParams } from "react-router-dom";
import { AuthContext, initialUserState } from "../../../context/auth.context";
import useNavbar from "./useNavbar";
import useTenant from "../../../hooks/useTenant";
import { NavbarItem } from "./navbar.interface";
import TenantSwitcher from "./tenantSwitcher.cmp";
import useTracker from "../../../hooks/useTracker";
import { Button } from "@gilbarbara/components";
import { useAppContext } from "context";
const APP_VERSION = "0.1.0";
const SKELETON_IDS = [
  "skeleton_one",
  "skeleton_two",
  "skeleton_three",
  "skeleton_four",
]; // create hook for generating skeletons ?

interface INavItem {
  active?: boolean;
}

const AppVersion = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "12px",
  color: theme.palette.common.grey[4],
}));

const Navbar = () => {
  const {
    setState,
    state: { run },
  } = useAppContext();
  const [isCreatedAt, setIsCreatedAt] = useState(false);
  const { trackEvent } = useTracker();
  const client = useApolloClient();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { name: tenantId } = useParams();
  const authContext = useContext(AuthContext);
  const { createTenantButton, createTenantModal, tenants } = useTenant();

  const tenant = useMemo(
    () => tenants.find((tenant) => tenant.id === tenantId),
    [tenantId, tenants]
  );
  useEffect(() => {
    if (tenant?.createdAt) {
      if (
        new Date().valueOf() -
          new Date(Date.parse(tenant?.createdAt)).valueOf() <=
        5 * 60 * 1000
      ) {
        setIsCreatedAt(true);
        setState({
          run: true,
          tourActive: true,
          continuous: true,
          stepIndex: 0,
        });
      }
    }
  }, [tenant]);
  const navbar = useNavbar(tenant);
  const logout = async () => {
    trackEvent({ category: "profile", action: "clickedLogout" });
    authContext?.handlerLogoutUser(initialUserState);
    navigate("/");
    await client.resetStore();
  };

  const handleMenuClick = (to?: string) => {
    if (to) {
      navigate(to);
    }
  };

  const renderSideNavItems = (items: NavbarItem[], level: number) => {
    const qty = items?.length ?? 0;
    if (qty === 0) {
      return null;
    }

    return items.map((item) => (
      <TreeItem
        key={item.id}
        level={level}
        nodeId={location.pathname === `/overview/${tenantId}` ? "" : item.id}
        labelText={item.name}
        labelIcon={level > 1}
        to={item.to}
        expanded={item.expanded}
        path={location.pathname}
        // sx={{ fontSize: "14px" }} need to call Ilya, ask how to pass prop
      >
        {renderSideNavItems(item.children, level + 1)}
      </TreeItem>
    ));
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        maxWidth: "250px",
        left: 0,
        boxShadow: "none",
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.blue[1]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "100vh",
          minWidth: "250px",
          border: `1px solid ${theme.palette.common.blue[1]}`,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Box sx={{ pb: 6 }}>
          <TenantSwitcher tenants={tenants} />
        </Box>
        <Box sx={{ flex: 1 }}>
          {tenantId && (
            <NavLink
              style={{ textDecoration: "none" }}
              to={`/overview/${tenantId}`}
            >
              <StyledNavItem
                id={isCreatedAt && tenantId ? "home" : ""}
                active={location.pathname === `/overview/${tenantId}`}
              >
                {tenantId} overview
              </StyledNavItem>
            </NavLink>
          )}
          {!tenantId && (
            <NavLink style={{ textDecoration: "none" }} to={`/dashboard`}>
              <StyledNavItem active={location.pathname === `/dashboard`}>
                Home
              </StyledNavItem>
            </NavLink>
          )}
          <TreeView
            selectedNodeId={navbar.selected}
            handleMenuClick={handleMenuClick}
          >
            {navbar?.loading ? (
              <>
                <StyledParentSkeleton />
                <StyledParentSkeleton />
                {SKELETON_IDS.map((id) => (
                  <StyledChildSkeletonContainer key={id}>
                    <StyledCircularSkeleton variant="circular" />
                    <StyledChildSkeleton animation="wave" />
                  </StyledChildSkeletonContainer>
                ))}
              </>
            ) : (
              renderSideNavItems(navbar.menu, 1)
            )}
          </TreeView>
          <StyledButtonWrapper>
            {React.cloneElement(createTenantButton, { size: "small" })}
          </StyledButtonWrapper>
          {/* <Box
          sx={{
            borderTop: `1px solid ${theme.palette.common.blue[1]}`,
            padding: "24px 24px 24px 16px",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dimensionfour.io/resources/developer-docs/getting-started"
            style={{ textDecoration: "none", display: "inline-block" }}
          >
            <IconButton variant="neutral" size="small">
              <ArrowForwardIcon />
              API Documentation
            </IconButton>
          </a>
        </Box> */}
        </Box>
        <Box
          sx={{
            padding: "24px 24px 24px 16px",
            borderTop: `1px solid ${theme.palette.common.blue[1]}`,
          }}
        >
          <IconButton variant="primary" size="small" onClick={logout}>
            <ArrowForwardIcon />
            Log out
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: "24px 24px 24px 16px",
            borderTop: `1px solid ${theme.palette.common.blue[1]}`,
          }}
        >
          <AppVersion style={{}}>Version {APP_VERSION}</AppVersion>
        </Box>
        {createTenantModal}
      </Box>
    </AppBar>
  );
};

const StyledButtonWrapper = styled("div")`
  padding: 24px 24px 24px 16px;
`;

const StyledParentSkeleton = styled(Skeleton)`
  height: 54px;
  border-radius: 0;
  background: #edf2f7;
`;

const StyledChildSkeletonContainer = styled("div")`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const StyledCircularSkeleton = styled(Skeleton)`
  height: 28px;
  width: 28px;
  margin: 0 8px 0 0;
`;

const StyledChildSkeleton = styled(Skeleton)`
  height: 44px;
  width: 80%;
  border-radius: 0;
  background: #edf2f7;
`;

export default Navbar;

const StyledNavItem = styled("div")<INavItem>`
  margin: 0 0 16px 0;
  padding: 8px 0 8px 16px;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  text-transform: capitalize;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  color: ${({ theme }) => theme.palette.common.grey[5]};
  background: ${({ theme, active }) =>
    active ? theme.palette.common.blue[2] : null};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.palette.common.blue[2] : theme.palette.common.blue[1]};
  }
`;
