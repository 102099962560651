import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}`,
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://staging-iot.dimensionfour.io/subscription`,
    connectionParams: () => ({
      "x-tenant-id": window.location.pathname.split("/")[2], // Parsing the 'tenantId'. Replace it in the future
    }),
  })
);

// Using this logic, queries and mutations will use HTTP as normal, and subscriptions will use WebSocket.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export default client;
