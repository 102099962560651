import { gql } from "@apollo/client";

export const UPDATE_SPACE = gql`
  mutation UPDATE_SPACE($input: UpdateSpaceInput!) {
    space {
      update(input: $input) {
        id
        name
        metadata
        normalizedName
        createdAt
        updatedAt
        parent {
          id
          name
        }
      }
    }
  }
`;
