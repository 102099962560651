import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useVerifyAccount } from "../../hooks/useVerifyAccount";
import { ReactComponent as ErrorIcon } from "../../common/assets/error-icon.svg";
import { ReactComponent as SuccessIcon } from "../../common/assets/success-icon.svg";

import "./verifyAccount.page.css";
import Spinner from "../../common/assets/spinner/spinner";
import { styled } from "@mui/system";

const VerifyOuter = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

const VerifyOuterSpinner = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "100vh",
});

const VerifyTitleMessage = styled("h1")({
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "38px",
  letterSpacing: "0em",
  textAlign: "center",
  marginBottom: "16px",
  marginTop: "18px",
});

const VerifyMessage = styled("p")({
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "19px",
  textAlign: "center",
});

const VerifyAccount: React.FC = (props) => {
  const { trackPageView } = useMatomo();
  const { search } = useLocation();
  const token = search.match(/token=(.*)/)?.[1];
  const [response, setResponse] = useState(0);
  const navigate = useNavigate();

  const verifyAccount = useVerifyAccount({ token });

  useEffect(() => {
    verifyAccount({ token })
      .then((response) => {
        if (response?.errors) {
          setResponse(2);
        } else {
          setResponse(1);
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 5000);
        }
      })
      .catch((e) => {
        setResponse(2);
      });
  }, []);

  useEffect(() => {
    trackPageView({ documentTitle: "Verify account page" });
  }, []);

  if (response === 0) {
    return (
      <VerifyOuterSpinner>
        <Spinner />
      </VerifyOuterSpinner>
    );
  } else if (response === 1) {
    return (
      <VerifyOuter>
        <SuccessIcon />
        <VerifyTitleMessage>Email Verified</VerifyTitleMessage>
        <VerifyMessage>
          Your email address was successfully verified.
        </VerifyMessage>
      </VerifyOuter>
    );
  } else {
    return (
      <VerifyOuter>
        <ErrorIcon />
        <VerifyTitleMessage>Error</VerifyTitleMessage>
        <VerifyMessage>Your email address could not be verified.</VerifyMessage>
      </VerifyOuter>
    );
  }
};

export default VerifyAccount;
