import { gql } from "@apollo/client";

export const DELETE_MEMBER = gql`
  mutation DELETE_MEMBER($input: DeleteMemberInput!) {
    member {
      delete(input: $input) {
        success
      }
    }
  }
`;
