import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Button from "lib/atoms/Button/Button";
import IconButton from "lib/atoms/Button/IconButton";
import { Container } from "common/styles/material-ui-styles";

// any is temporary. probably we wil remove this component
interface IFieldModeButtons {
  mode: string;
  fieldName: string;
  id: string;
  onEdit: (fieldName: any) => void;
  onCancel: (fieldName: any) => void;
  onSave: (fieldName: any, id: string) => void;
  style?: object;
}

function FieldModeButtons({
  mode,
  fieldName,
  id,
  onEdit,
  onCancel,
  onSave,
  style,
}: IFieldModeButtons) {
  const viewMode = mode === "view";

  return viewMode ? (
    <Container style={style}>
      <IconButton
        size="small"
        onClick={() => onEdit(fieldName)}
        variant="primary"
      >
        <EditIcon />
        Edit
      </IconButton>
    </Container>
  ) : (
    <Container style={style}>
      <Button onClick={() => onCancel(fieldName)} variant="secondary">
        Cancel
      </Button>
      <Button
        style={{ margin: "0 0 0 16px" }}
        onClick={() => onSave(fieldName, id)}
        variant="primary"
      >
        Save
      </Button>
    </Container>
  );
}

export default FieldModeButtons;
