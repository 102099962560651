import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SelectChangeEvent, styled } from "@mui/material";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import useSpaceOptions from "hooks/useSpaceOptions";
import { EMPTY_STRING } from "common/utils";
import { LAST_ACTIVE, getOption, allPropsEmpty } from "../../tenant.utils";
import { IFilters } from "../../spaces/spaces.page";
import { IOption } from "common/interfaces/shared.interface";
import TextField from "lib/atoms/TextField/TextField";
import { StyledAutocomplete } from "common/styles/material-ui-styles";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";
import Search from "../search/search.cmp";

interface IProps {
  length: number | undefined;
  values: IFilters;
  onSearch: (value: string) => void | undefined;
  onFilter: (key: keyof IFilters, value: IOption | string | null) => void;
  onFiltersReset: () => void;
  loading: boolean;
}

export type TFilterKey = keyof Omit<IFilters, "name">;

const getSpaceId = (values: IFilters) =>
  "spaceId" in values && typeof values.spaceId === "object"
    ? values.spaceId.value
    : "";

const findOption = (options: IOption[], spaceId: string) =>
  options.find((option) => option.value === spaceId);

function SearchWithFilters({
  length = 0,
  loading,
  values,
  onSearch,
  onFilter,
  onFiltersReset,
}: IProps) {
  const { name: tenantId } = useParams();
  const { options: spaceOptions } = useSpaceOptions(tenantId, "POINT");
  // We need it to track the "clearIcon" show/hide state
  const [autocompleteInput, setAutocompleteInput] = useState("");
  const hideFilters = !loading && allPropsEmpty(values) && length <= 0;

  const onDropdownSelect = (
    e: SelectChangeEvent<any>,
    child: React.ReactNode
  ) => {
    const option = getOption(child);
    if (typeof option !== "string" && "value" in option) {
      option?.value ? onFilter("updatedAt", option) : onFilter("updatedAt", "");
    }
  };

  const clearFilters = (
    <div>
      {length} points found with the chosen filters.&nbsp;
      <StyledClearButton role="button" onClick={() => onFiltersReset()}>
        Clear the filters.
      </StyledClearButton>
    </div>
  );

  const clearSearch = (
    <div>
      {length} points found with the search term “{values.name}”.&nbsp;
      <StyledClearButton
        role="button"
        onClick={() => onFilter("name", EMPTY_STRING)}
      >
        Clear the search.
      </StyledClearButton>
    </div>
  );

  // Check if we have activated filter with deleted space and clear it if so
  useEffect(() => {
    if (spaceOptions.length > 1) {
      const spaceId = getSpaceId(values);
      if (spaceId && !findOption(spaceOptions, spaceId)) {
        onFilter("spaceId", EMPTY_STRING);
      }
    }
  }, [onFilter, spaceOptions, values]);

  if (hideFilters) return null;

  return (
    <>
      <StyledContainer>
        <Search
          value={values.name}
          onChange={onSearch}
          placeholder="Search by points name"
          size="small"
          sx={{ width: { lg: 280, xl: 320 } }}
        />
        <StyledFilters>
          <StyledAutocomplete
            sx={{ width: { lg: 280, xl: 320 }, margin: "0 0 0 40px" }}
            id="outlined-required"
            options={spaceOptions}
            value={values.spaceId}
            onChange={(event, option: any, reason) =>
              reason === "clear"
                ? onFilter("spaceId", EMPTY_STRING)
                : onFilter("spaceId", option)
            }
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            onInputChange={(e, val) => setAutocompleteInput(val)}
            clearIcon={
              autocompleteInput ? <ClearIcon fontSize="small" /> : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="e.g. Oslo kommune"
                label="Space"
                size="small"
              />
            )}
          />
          <DefaultDropdown
            sx={{ width: { lg: 280, xl: 320 }, margin: "0 0 0 40px" }}
            variant="outlined"
            label="Last active"
            placeholder="Time range"
            options={LAST_ACTIVE}
            value={values.updatedAt}
            size="small"
            SelectProps={{
              onChange: onDropdownSelect,
              displayEmpty: true,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </StyledFilters>
      </StyledContainer>
      <StyledChips>
        {Object.entries(values).map(([key, value]) =>
          typeof value === "object" ? (
            <StyledChip
              key={key}
              label={value.label}
              onDelete={() => onFilter(key as TFilterKey, EMPTY_STRING)}
              deleteIcon={<ClearIcon color="success" sx={{ color: "#000" }} />}
            />
          ) : null
        )}
      </StyledChips>
      <StyledResult>
        {values.updatedAt || values.spaceId
          ? clearFilters
          : values.name
          ? clearSearch
          : `${length} points`}
      </StyledResult>
    </>
  );
}

const StyledContainer = styled("div")`
  display: flex;
  align-items: center;
  margin: 40px 0;
`;

const StyledFilters = styled("div")`
  display: flex;
`;

const StyledChips = styled("div")`
  display: flex;
`;

const StyledChip = styled(Chip)`
  background: #0c116b;
  color: #ffffff;
  margin: 0 16px 24px 0;

  & .MuiChip-deleteIcon {
    font-size: 20px;
    margin: 0 8px 0 0;
    color: #ffffff;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  & .MuiChip-label {
    padding-right: 8px;
  }
`;

const StyledResult = styled("div")`
  color: ${({ theme }) => theme.palette.common.grey[5]};
`;

const StyledClearButton = styled("span")`
  // need to think about accessibility, maybe replace with button
  text-decoration: underline;
  cursor: pointer;
  color: #3237b8;
`;

export default SearchWithFilters;
