import React, { MouseEvent } from "react";
import { styled } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import {
  ModalUnstyled,
  ModalUnstyledProps,
  ButtonUnstyledProps,
} from "@mui/base";
import Button from "../../atoms/Button/Button";

interface IModalFooterProps {
  okText?: string;
  cancelText?: string | null;
  onOk?: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
  okButtonProps?: ButtonUnstyledProps;
}

interface IModalProps extends ModalUnstyledProps, IModalFooterProps {
  footer?: IModalFooterProps;
  title?: string;
}

/* If you're using a Form inside the Modal, don't need to pass 'onOk' prop.
  Instead of It, pass the { type: "submit", form: [formId] } to 'okButtonProps' */
function ModalFooter({
  okText,
  cancelText = "Cancel", // Pass 'null' if you want to hide the 'Cancel' button
  onOk,
  onCancel,
  okButtonProps,
}: IModalFooterProps) {
  return (
    <Buttons>
      {cancelText && (
        <StyledButton
          style={{ marginRight: "10%" }}
          onClick={onCancel}
          variant="secondary"
        >
          {cancelText}
        </StyledButton>
      )}
      {okText && (
        <StyledButton {...okButtonProps} onClick={onOk} variant="primary">
          {okText}
        </StyledButton>
      )}
    </Buttons>
  );
}

function Modal({
  children,
  title,
  footer,
  className,
  style,
  ...props
}: IModalProps) {
  return (
    <StyledModal slots={{ backdrop: StyledBackdrop }} {...props}>
      <Fade in={props.open}>
        <Container className={className} style={style}>
          {title && <Title>{title}</Title>}
          <Content>{children}</Content>
          {footer && <ModalFooter {...footer} />}
        </Container>
      </Fade>
    </StyledModal>
  );
}

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled("h3")`
  font-size: 36px;
  font-weight: 500;
  line-height: 100%;
  word-break: break-word;
  margin: 0 0 24px 0;
`;

const Container = styled("div")`
  padding: 48px;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 3px 4px 25px rgba(30, 26, 44, 0.02);
  border-radius: 20px;
`;

const Content = styled("div")``;

const Buttons = styled("div")`
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
`;

const StyledButton = styled(Button)`
  width: 45%;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
`;

export default Modal;
