import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { RegisterParamsInput } from "../common/interfaces/register.interface";

export const REGISTER = gql`
  mutation CREATEACCOUNT($input: RegisterInput!) {
    account {
      register(input: $input) {
        success
      }
    }
  }
`;

export const useRegister = (
  input: RegisterParamsInput
): ((input: RegisterParamsInput) => Promise<any>) => {
  const [register] = useMutation(REGISTER);
  return async (input) => register({ variables: { input } });
};
