import { gql } from "@apollo/client";

export const UPDATE_MEMBER = gql`
  mutation UPDATE_MEMBER($input: UpdateMemberInput!) {
    member {
      update(input: $input) {
        id
      }
    }
  }
`;
