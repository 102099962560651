import React, { createContext, useContext, useMemo } from "react";
import { useSetState } from "react-use";
import { Step } from "react-joyride";

interface AppState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  continuous: boolean;
}

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  continuous: false,
};

export const AppContext = createContext({
  state: appState,
  setState: () => undefined,
});
AppContext.displayName = "AppContext";

export function AppProviderOnboarding(props: any) {
  const [state, setState] = useSetState(appState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state]
  );

  return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext(): {
  setState: (
    patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>)
  ) => void;
  state: AppState;
} {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  return context;
}
