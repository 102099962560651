import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { styled, SvgIconProps, Divider as Line } from "@mui/material";
import { ReactComponent as Home } from "common/assets/overview/home-2.svg";
import { ReactComponent as HomeIcon } from "common/assets/overview/home-icon.svg";
import { ReactComponent as Member } from "common/assets/overview/member.svg";
import { ReactComponent as Points } from "common/assets/overview/points-overview.svg";
import { ReactComponent as IconRight } from "common/assets/icon-right.svg";
import { ReactComponent as Calender } from "common/assets/overview/calender.svg";
import { ReactComponent as Documentation } from "common/assets/overview/documentation.svg";
import { ReactComponent as Feedback } from "common/assets/overview/feedback.svg";
import { ReactComponent as Community } from "common/assets/overview/community.svg";
import { ReactComponent as Guide } from "common/assets/overview/guides.svg";
import { GET_POINTS } from "common/graphql/point.gql";
import { IPoints } from "../../../tenant/tenant.interface";
import TenantsTable from "../../../tenant/components/pointsTableHome/pointsTable.cmp";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { NavLink, useNavigate } from "react-router-dom";
import { convertDate } from "pages/tenant/tenant.utils";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/TaskAltOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  IAccessTokenType,
  ITenantData,
  PlanPriceType,
  TMember,
} from "common/interfaces/tenant.interface";
import AddTokenModal from "pages/tenant/components/addTokenModal/addTokenModal.cmp";
import CreateSpaceModal from "pages/tenant/components/createSpaceModal/createSpaceModal.cmp";
import { useQuery } from "@apollo/client";
import { GET_TENANT } from "common/graphql/tenant.gql";
import AddTenantMemberModal from "pages/tenant/components/addTenantMemberModal/addTenantMemberModal.cmp";
import CreatePointModal from "pages/tenant/components/createPointModal/createPointModal.cmp";
import { useAppContext } from "context";
type Variant = "TENANT" | "SPACE" | "POINT" | "Overview";
interface ITenant {
  id: string;
  name: string;
  totalPoints: number;
  totalSpaces: number;
  createdAt: string;
  billing: {
    currentPlanPrice: PlanPriceType;
  };
  members: TMember[];
  accessTokens: IAccessTokenType[];
}
interface IOverviewDataBox {
  variant: Variant;
  userName?: string;
  onClick: (() => void) | undefined;
  sx?: React.CSSProperties;
  space: number | undefined;
  point: number | undefined;
  tenant: ITenant | undefined;
  token: IAccessTokenType[];
}

interface IIcon extends SvgIconProps {
  variant: Variant;
}

const Welcome = ({ variant, onClick, userName = "", sx }: IOverviewDataBox) => {
  return (
    <Wrapper style={sx} className="welcome">
      <Content>
        <StyledTitle>Welcome {userName}</StyledTitle>
        <Box sx={{ width: 1 }}>
          <Box>
            <StyledLabel>
              This is your home space. This is where your data, updates and
              latest news will be
              <br />
              shown. For now you can see the steps we recommend to create the
              best dimension!
              <br />
              <br />
              It all starts with the tenant, let’s create yours!
            </StyledLabel>
            <button
              type="submit"
              className="btn large yellow"
              onClick={onClick}
            >
              Create tenant
            </button>
          </Box>
        </Box>
      </Content>
      <StyledIcon variant={variant} />
    </Wrapper>
  );
};
const Overview = ({
  variant,
  userName = "",
  point,
  space,
  tenant,
  sx,
}: IOverviewDataBox) => {
  const createdAt = tenant?.createdAt ? convertDate(tenant?.createdAt) : "";
  const tenantId = tenant?.id;
  const members = tenant?.members?.length ? tenant?.members?.length : 0;
  const spaces = space;
  const points = point;
  return (
    <NavLink style={{ textDecoration: "none" }} to={`/t/${tenantId}`}>
      <Wrapper style={sx} className="overview">
        <StyledIcon variant={variant} />
        <Content className="iconLabel">
          <StyledTitle className="overview">{userName}</StyledTitle>
          <Divider variant="fullWidth" />
          <Box sx={{ width: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4}>
              <Box gridColumn="span 3">
                <Item className="root">
                  <HomeIcon />
                  <StyledLabel className="iconLabel">
                    {spaces} spaces
                  </StyledLabel>
                </Item>
              </Box>
              <Box gridColumn="span 3">
                <Item className="root">
                  <Points />
                  <StyledLabel className="iconLabel">
                    {points} points
                  </StyledLabel>
                </Item>
              </Box>
              <Box gridColumn="span 3">
                <Item className="root">
                  <Calender />
                  <StyledLabel className="iconLabel">{createdAt}</StyledLabel>
                </Item>
              </Box>
              <Box gridColumn="span 3">
                <Item className="root">
                  <Member />
                  <StyledLabel className="iconLabel">
                    {members} member
                  </StyledLabel>
                </Item>
              </Box>
            </Box>
          </Box>
        </Content>
      </Wrapper>
    </NavLink>
  );
};

const Steps = ({
  onClick,
  tenant,
  userName = "",
  sx,
  space,
  variant,
  token,
  point,
}: IOverviewDataBox) => {
  const tenantId = tenant?.id;
  const [progress, setProgress] = useState(0);
  const [active, setActive] = useState(0);
  const [uncertain, setUncertain] = useState(0);
  const [noSignal, setNosignal] = useState(0);
  const navigate = useNavigate();
  const onClickPoint = () => {
    navigate(`/t/${tenantId}`, {
      replace: true,
      state: { defaultTab: 0 },
    });
  };
  const onClickSpace = () => {
    navigate(`/t/${tenantId}`, {
      replace: true,
      state: { defaultTab: 1 },
    });
  };
  const hasNoTokens = !token?.length;
  const [modals, setModals] = useState({
    createToken: false,
    createTenant: false,
    createMember: false,
    createSpace: false,
    createPoint: false,
  });
  const [getPoints, { data: pointsData, loading: pointsLoading }] =
    useLazyQuery<IPoints>(GET_POINTS, {
      context: {
        headers: {
          "x-tenant-id": tenant?.id,
        },
      },
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-only",
    });

  const points = pointsData?.points?.nodes;

  const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number }
  ) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            sx={{
              height: "5px",
              backgroundColor: "#EBF1FA",
              span: {
                "&.MuiLinearProgress-bar": {
                  height: "5px",
                  backgroundColor: "#D7E2FB !important",
                },
              },
            }}
            variant="determinate"
            {...props}
          />
        </Box>
      </Box>
    );
  };

  const { setState } = useAppContext();
  const hasNoSpaces = space == undefined || space == 0;
  const hasNoPoint = point == undefined || point == 0;
  const hasMember = tenant?.members
    ? tenant?.members?.length > 1
      ? true
      : false
    : false;
  useEffect(() => {
    if (!tenantId || hasNoTokens || hasNoSpaces || hasNoPoint || !hasMember) {
      setState({ run: false, tourActive: false });
      setProgress(0);
    } else {
      setState({ run: true, tourActive: true, continuous: true, stepIndex: 0 });
    }
    if (tenantId) {
      setProgress(progress + 20);
    }
    if (!hasNoTokens) {
      setProgress(progress + 20);
    }
    if (!hasNoSpaces) {
      setProgress(progress + 20);
    }
    if (!hasNoPoint) {
      setProgress(progress + 20);
    }
    if (hasMember) {
      setProgress(progress + 20);
    }
  }, [tenantId, hasNoTokens, hasNoSpaces, hasNoPoint, hasMember]);
  useEffect(() => {
    getPoints();
    setActive(
      points?.filter(
        (x) =>
          new Date().valueOf() -
            new Date(Date.parse(x?.lastActive)).valueOf() <=
          5 * 60 * 1000
      ).length
        ? points?.filter(
            (x) =>
              new Date().valueOf() -
                new Date(Date.parse(x?.lastActive)).valueOf() <=
              5 * 60 * 1000
          ).length
        : 0
    );
    setUncertain(
      points?.filter(
        (x) =>
          new Date().valueOf() -
            new Date(Date.parse(x?.lastActive)).valueOf() <=
          1 * 24 * 60 * 60 * 1000
      ).length
        ? points?.filter(
            (x) =>
              new Date().valueOf() -
                new Date(Date.parse(x?.lastActive)).valueOf() <=
              1 * 24 * 60 * 60 * 1000
          ).length
        : 0
    );
    setNosignal(
      points?.filter((x) => !x?.lastActive).length
        ? points?.filter((x) => !x?.lastActive).length
        : 0
    );
  }, [points, getPoints, tenantId]);
  return (
    <>
      {!tenantId || hasNoTokens || hasNoSpaces || hasNoPoint || !hasMember ? (
        <>
          {variant == "TENANT" && (
            <Welcome
              sx={sx}
              variant={variant}
              userName={userName}
              onClick={onClick}
              space={space}
              point={point}
              tenant={tenant}
              token={[]}
            />
          )}
          {variant == "Overview" && (
            <Overview
              sx={sx}
              variant={variant}
              userName={userName}
              onClick={onClick}
              space={space}
              point={point}
              tenant={tenant}
              token={[]}
            />
          )}
          <StyledTitle className="steps">
            Get familiar with our dimension
          </StyledTitle>
          <StyledLabel className="body">
            To get started and experience everything that Dimension Four has to
            offer follow the list below step by step
          </StyledLabel>
          <Box sx={{ width: "100%", margin: "32px 0 0" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
          <Box sx={{ width: 1 }}>
            <Box display="grid">
              <Item
                className="body6"
                sx={{
                  width: "100%",
                }}
              >
                <Item
                  className="body4"
                  onClick={!tenantId ? onClick : undefined}
                >
                  <Item
                    className="body3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled
                        checked={tenantId ? true : false}
                        sx={{
                          "&.Mui-disabled": {
                            span: {
                              color: "#010032 !important",
                              textDecoration: tenantId ? "line-through" : "",
                              textDecorationThickness: "1px !important",
                            },
                          },
                        }}
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleOutlineIcon
                                sx={{ color: "#BCEAE3" }}
                              />
                            }
                          />
                        }
                        label="Create tenant"
                      />
                    </FormGroup>
                    <ArrowForwardIosIcon
                      sx={{ color: "#3237B8", height: "20px", display: "none" }}
                    />
                  </Item>
                  <Divider className="body3" variant="fullWidth" />
                </Item>
                <Item
                  className="body4"
                  onClick={
                    hasNoTokens
                      ? () => setModals({ ...modals, createToken: true })
                      : undefined
                  }
                >
                  <Item
                    className="body3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled
                        checked={hasNoTokens ? false : true}
                        sx={{
                          "&.Mui-disabled": {
                            span: {
                              color: "#010032 !important",
                              textDecoration: hasNoTokens ? "" : "line-through",
                            },
                          },
                        }}
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleOutlineIcon
                                sx={{ color: "#BCEAE3" }}
                              />
                            }
                          />
                        }
                        label="Create token"
                      />
                    </FormGroup>
                    <ArrowForwardIosIcon
                      sx={{ color: "#3237B8", height: "20px" }}
                    />
                  </Item>
                  <Divider className="body3" variant="fullWidth" />
                </Item>
                <Item
                  className="body4"
                  onClick={
                    hasNoSpaces
                      ? () => setModals({ ...modals, createSpace: true })
                      : undefined
                  }
                >
                  <Item
                    className="body3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled
                        checked={hasNoSpaces ? false : true}
                        sx={{
                          "&.Mui-disabled": {
                            span: {
                              color: "#010032 !important",
                              textDecoration: hasNoSpaces ? "" : "line-through",
                            },
                          },
                        }}
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleOutlineIcon
                                sx={{ color: "#BCEAE3" }}
                              />
                            }
                          />
                        }
                        label="Set up a space structure"
                      />
                    </FormGroup>
                    <ArrowForwardIosIcon
                      sx={{ color: "#3237B8", height: "20px" }}
                    />
                  </Item>
                  <Divider className="body3" variant="fullWidth" />
                </Item>
                <Item
                  className="body4"
                  onClick={
                    hasNoPoint
                      ? () => setModals({ ...modals, createPoint: true })
                      : undefined
                  }
                >
                  <Item
                    className="body3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled
                        checked={hasNoPoint ? false : true}
                        sx={{
                          "&.Mui-disabled": {
                            span: {
                              color: "#010032 !important",
                              textDecoration: hasNoPoint ? "" : "line-through",
                            },
                          },
                        }}
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleOutlineIcon
                                sx={{ color: "#BCEAE3" }}
                              />
                            }
                          />
                        }
                        label="Create points"
                      />
                    </FormGroup>
                    <ArrowForwardIosIcon
                      sx={{ color: "#3237B8", height: "20px" }}
                    />
                  </Item>
                  <Divider className="body3" variant="fullWidth" />
                </Item>
                <Item
                  className="body4"
                  onClick={
                    !hasMember
                      ? () => setModals({ ...modals, createMember: true })
                      : undefined
                  }
                >
                  <Item
                    className="body3"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        disabled
                        checked={!hasMember ? false : true}
                        sx={{
                          "&.Mui-disabled": {
                            span: {
                              color: "#010032 !important",
                              textDecoration: !hasMember ? "" : "line-through",
                            },
                          },
                        }}
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleOutlineIcon
                                sx={{ color: "#BCEAE3" }}
                              />
                            }
                          />
                        }
                        label="Invite members"
                      />
                    </FormGroup>
                    <ArrowForwardIosIcon
                      sx={{ color: "#3237B8", height: "20px" }}
                    />
                  </Item>
                </Item>
              </Item>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ width: 1 }}>
          <StyledTitle className="steps">Tenant status</StyledTitle>
          <Wrapper className="device">
            <Content className="deviceLabel" onClick={onClickSpace}>
              <Item className="root">
                <StyledLabel className="iconLabel2"> Spaces</StyledLabel>
                <HomeIcon style={{ marginLeft: 8 }} />
              </Item>
              <Box sx={{ width: 1 }}>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)">
                  <Box>
                    <Item className="rootDevice">
                      <StyledLabel className="iconLabel2">
                        <DeviceTitle className="overview">{space} </DeviceTitle>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;Space{" "}
                      </StyledLabel>
                      <IconRight style={{ marginBottom: 8 }} />
                    </Item>
                  </Box>
                </Box>
              </Box>
            </Content>
            <Content className="deviceLabel" onClick={onClickPoint}>
              <Item className="root">
                <StyledLabel className="iconLabel2"> Points</StyledLabel>
                <Points style={{ marginBottom: 8 }} />
              </Item>
              <Box sx={{ width: 1 }}>
                <Box display="grid" gridTemplateColumns="repeat(1, 1fr)">
                  <Box>
                    <Item className="rootDevice">
                      <StyledLabel className="iconLabel2">
                        <DeviceTitle className="overview">{point}</DeviceTitle>
                        &nbsp;&nbsp;&nbsp;&nbsp;Manage Points
                      </StyledLabel>
                      <IconRight style={{ marginBottom: 8 }} />
                    </Item>
                  </Box>
                </Box>
              </Box>
            </Content>
          </Wrapper>
          <Wrapper className="deviceActivity">
            <StyledTitle className="steps">Points Activity</StyledTitle>
            <Content className="iconLabel">
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(3, 1fr)"
                  gap={4}
                >
                  <Item className="rootDevice">
                    <StyledLabel className="iconLabel3">
                      <DeviceTitle className="points">{active} </DeviceTitle>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Circle style={{ marginBottom: 12 }} className="circle" />
                      &nbsp;Active
                      <IconRight style={{ marginLeft: 8 }} />
                    </StyledLabel>
                  </Item>
                  <Item className="rootDevice">
                    <StyledLabel className="iconLabel3">
                      <DeviceTitle className="points">{uncertain} </DeviceTitle>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <Circle
                        style={{ marginBottom: 12 }}
                        className="uncertain"
                      />
                      &nbsp;Uncertain
                      <IconRight style={{ marginLeft: 8 }} />
                    </StyledLabel>
                  </Item>
                  <Item className="rootDevice">
                    <StyledLabel className="iconLabel3">
                      <DeviceTitle className="points">{noSignal} </DeviceTitle>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <Circle style={{ marginBottom: 12 }} className="signal" />
                      &nbsp;No Signal
                      <IconRight style={{ marginLeft: 8 }} />
                    </StyledLabel>
                  </Item>
                </Box>
              </Box>
            </Content>
          </Wrapper>
          <Wrapper className="deviceActivity">
            <StyledTitle className="steps">Points Signal</StyledTitle>
            <Content className="iconLabel">
              <Box sx={{ width: 1 }}>
                <TenantsTable points={points} loading={pointsLoading} />
              </Box>
            </Content>
          </Wrapper>
          {/* <Box display="grid">
            <Item
              className="body5"
              sx={{
                width: "100% !important",
                backgroundColor: "transparent !important",
                paddingLeft: "0!important",
                paddingRight: "0!important",
              }}
            >
              <Item
                className="body3"
                sx={{
                  backgroundColor: "transparent !important",
                  marginTop: "64px !important",
                  marginBottom: "64px !important",
                  width: "100% !important",
                }}
              >
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100% !important",
                  }}
                >
                  <FormControlLabel
                    disabled
                    checked={tenantId ? true : false}
                    sx={{
                      "&.Mui-disabled": {
                        span: {
                          color: "#010032 !important",
                        },
                      },
                    }}
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={
                          <CheckCircleOutlineIcon sx={{ color: "#BCEAE3" }} />
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 60 } }}
                      />
                    }
                    label=""
                  />
                  <Item className="root2">
                    <StyledTitle className="body">All set!</StyledTitle>
                    <StyledLabel className="body">
                      Everything is done for your set up! Come back here soon to
                      see the data from your spaces, points and signals
                    </StyledLabel>
                  </Item>
                </FormGroup>
              </Item>
            </Item>
          </Box> */}
        </Box>
      )}
      <Divider className="body" variant="fullWidth" />
      <Box sx={{ width: 1 }}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4}>
          <Box gridColumn="span 6">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dimensionfour.io/resources/developer-docs/getting-started"
              style={{ textDecoration: "none" }}
            >
              <Item className="body">
                <Documentation />
                <Item className="root1">
                  <StyledTitle className="body">Developer docs</StyledTitle>
                  <Item className="container">
                    <StyledLabel className="body">
                      Explore the developer docs.
                    </StyledLabel>
                  </Item>
                </Item>
              </Item>
            </a>
          </Box>
          <Box gridColumn="span 6">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dimensionfour.io/resources/user-guide/quickstart"
              style={{ textDecoration: "none" }}
            >
              <Item className="body">
                <Guide />
                <Item className="root1">
                  <StyledTitle className="body">User Guides</StyledTitle>
                  <Item className="container">
                    <StyledLabel className="body">
                      Explore support articles assisting you in getting the most
                      out of your setup.
                    </StyledLabel>
                  </Item>
                </Item>
              </Item>
            </a>
          </Box>
          <Box gridColumn="span 6">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dimensionfour.io/resources/community"
              style={{ textDecoration: "none" }}
            >
              <Item className="body2">
                <Community />
                <Item className="root1">
                  <StyledTitle className="body">Join the community</StyledTitle>
                  <Item className="container">
                    <StyledLabel className="body">
                      Share experiences with other users or ask the Dimension
                      Four team directly in our slack community
                    </StyledLabel>
                  </Item>
                </Item>
              </Item>
            </a>
          </Box>
          <Box gridColumn="span 6">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dimensionfour.io/feedback"
              style={{ textDecoration: "none" }}
            >
              <Item className="body2">
                <Feedback />
                <Item className="root1">
                  <StyledTitle className="body">Share feedback</StyledTitle>
                  <Item className="container">
                    <StyledLabel className="body">
                      Got feedback for features or improvements you’d like to
                      see? Reach out, your input is appreciated!
                    </StyledLabel>
                  </Item>
                </Item>
              </Item>
            </a>
          </Box>
        </Box>
      </Box>
      <AddTenantMemberModal
        open={modals.createMember}
        action={() => setModals({ ...modals, createMember: false })}
        tenantId={tenantId}
      />
      <AddTokenModal
        open={modals.createToken}
        onClose={() => setModals({ ...modals, createToken: false })}
        tenantId={tenantId}
      />
      <CreateSpaceModal
        open={modals.createSpace}
        onClose={() => setModals({ ...modals, createSpace: false })}
        tenantId={tenantId}
      />
      <CreatePointModal
        open={modals.createPoint}
        onClose={() => setModals({ ...modals, createPoint: false })}
        tenantId={tenantId}
      />
    </>
  );
};
function HomeDataBox({
  variant,
  onClick,
  userName = "",
  point,
  space,
  tenant,
  sx,
}: IOverviewDataBox) {
  const { data } = useQuery<ITenantData>(GET_TENANT, {
    context: {
      headers: {
        "x-tenant-id": tenant?.id,
      },
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });
  return (
    <>
      <Steps
        sx={sx}
        variant={variant}
        userName={userName}
        onClick={() => onClick}
        space={space}
        point={point}
        tenant={tenant}
        token={data?.accessTokens ? data?.accessTokens : []}
      />
    </>
  );
}

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 32px;
  background: linear-gradient(90deg, #010032 0%, #0c116b 100%);
  border-radius: 20px;
  box-shadow: 3px 4px 25px rgba(30, 26, 44, 0.02);
  border-radius: 20px;
  &.overview {
    cursor: pointer;
    margin-bottom: 64px;
  }

  &.welcome {
    margin-bottom: 64px;
  }
  &.device {
    background: none;
    box-shadow: none;
    padding: 32px 0;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &.deviceActivity {
    background: #ffffff;
    box-shadow: none;
    padding: 32px 24px;
    border-radius: 20px;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const DeviceTitle = styled("span")`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80%;
  /* or 51px */
  &.points {
    font-size: 110px;
  }
  letter-spacing: -0.05em;
`;
const Content = styled("div")`
  padding: 0 32px 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.common.grey[5]};
  width: 80%;
  &.iconLabel {
    margin-left: 32px;
  }
  &.deviceLabel {
    max-width: 600px;
    cursor: pointer;
    width: 100%;
    padding: 8px 16px 16px;
    background: linear-gradient(90deg, #010032 0%, #0c116b 100%);
    border-radius: 20px;
    box-shadow: 3px 4px 25px rgba(30, 26, 44, 0.02);
    border-radius: 20px;
    ${(props) => props.theme.breakpoints.up("md")} {
      max-width: 560px;
      margin-bottom: 16px;
    }
  }
`;

const Circle = styled("div")`
  flex: none;
  width: 8px;
  height: 8px;
  &.circle {
    background: #5ba180;
    border-radius: 8px;
  }
  &.uncertain {
    background: #ffa654;
  }
  &.signal {
    background-image: linear-gradient(
        to bottom right,
        transparent 50%,
        #e95433 0
      ),
      linear-gradient(to top right, #e95433 50%, transparent 0);
    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position: left, right;
  }
`;
const Divider = styled(Line)`
  border-color: #3237b8;
  width: 100%;
  &.body {
    margin: 64px 0;
    border-color: #d7e2fb;
  }
  &.body2 {
    margin: 64px 0;
    border-color: #d7e2fb;
  }
  &.body3 {
    border: 1px solid #d7e2fb;
  }
`;

const Item = styled(Paper)(({ theme }) => ({
  "&.root": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    color: "#fff",
    boxShadow: "none",
  },
  "&.rootDevice": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(5),
    display: "flex",
    alignItems: "flex-end",
    textAlign: "start",
    color: "#fff",
    boxShadow: "none",
  },
  "&.root1": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "11px 0 0 32px",
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    maxWidth: "517px",
    width: "100%",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.root2": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "0 0 0 16px",
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    width: "80%",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.container": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "11px 0 0 0",
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    boxShadow: "none",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.body": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    display: "flex",
    borderRadius: "20px",
    minHeight: "198px",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    border: "1px solid #D7E2FB",
  },
  "&.body2": {
    backgroundColor: "#FFFFFF",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    display: "flex",
    borderRadius: "20px",
    minHeight: "198px",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    border: "1px solid #D7E2FB",
  },
  "&.body3": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
    display: "flex",
    borderRadius: "20px",
    alignItems: "center",
    textAlign: "start",
    boxShadow: "none",
  },
  "&.body4": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    width: "100%",
  },
  "&.body5": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    borderRadius: "20px",
  },
  "&.body6": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D7E2FB",
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    borderRadius: "20px",
  },
}));

const StyledLabel = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 100%; 
  &.iconLabel {
    margin-left: 10px;
  }

  &.iconLabel2 {
    margin-right: 12px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    svg {
      align-items: center;
    }
  }
  &.iconLabel3 {
    margin-right: 12px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    color: #010032;
    justify-content: space-between;
  }
  &.body {
    color: var(--dark-blue-color);
    margin-left: 0;
    
`;

const StyledTitle = styled("h4")`
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 32px 0;
  font-family: "poppins";
  color: ${({ theme }) => theme.palette.common.white};
  &.overview {
    color: #ffa654;
    text-transform: capitalize;
  }
  &.body {
    margin: 0;
    color: var(--dark-blue-color);
  }
  &.document {
    color: var(--dark-blue-color);
    margin: 32px 0 0;
  }
  &.steps {
    color: var(--dark-blue-color);
    margin: 0;
  }
`;

const StyledIcon = styled(Home)<IIcon>`
  flex: none;
`;

export default HomeDataBox;
