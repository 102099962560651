import React, { useEffect, useState } from "react";
import { styled, StyledComponentProps } from "@mui/material";
import useSpaceOptions from "hooks/useSpaceOptions";
import useNotification from "hooks/useNotification";
import useFieldMode from "hooks/useFieldMode";
import { Dictionary } from "common/interfaces/shared.interface";
import TextField from "lib/atoms/TextField/TextField";
import Metadata from "lib/atoms/Metadata/Metadata.cmp";
import FieldModeButtons from "../fieldModeButtons/fieldModeButtons.cmp";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";

interface StyledRowProps extends StyledComponentProps {
  baseline?: boolean;
  viewMode?: boolean;
}

interface IState {
  id: string;
  name: string;
  externalId: string;
  spaceId: string;
  metadata: string;
  createdAt: string;
  lastActive: string;
  signalTypes?: string[];
}

interface IProps {
  pointId: string;
  tenantId: string;
  initialValues: IState;
  updatePoint: (arg0: any) => void;
}

const LABELS: Dictionary = {
  id: "Id",
  name: "Point name",
  externalId: "External Id",
  spaceId: "Space",
  metadata: "Metadata",
  lastActive: "Last active",
  createdAt: "Created",
  signalTypes: "Signal types",
};

const FIELD_NAMES = Object.keys(LABELS) as readonly TField[];

type GenericField = typeof FIELD_NAMES;
type TField = keyof IState;
type TEditableField = Exclude<TField, "id" | "signalTypes">;

const fieldModeButtonsStyles = {
  margin: "0 0 0 16px",
  display: "flex",
  justifyContent: "flex-end",
};

const isMetadata = (fieldName: TField) => fieldName === "metadata";

function UpdatePointForm({
  initialValues,
  pointId,
  tenantId,
  updatePoint,
}: IProps) {
  const [values, setValues] = useState<IState>({
    id: "",
    name: "",
    externalId: "",
    spaceId: "",
    metadata: "",
    lastActive: "",
    createdAt: "",
    signalTypes: [""],
  });
  const { options, defaultOption } = useSpaceOptions(tenantId, "POINT");
  const { notify } = useNotification();
  const { fieldMode, setEditMode, setViewMode } = useFieldMode<
    GenericField,
    TField
  >(FIELD_NAMES);

  const onChange = (fieldName: TEditableField, value: string) =>
    setValues({ ...values, [fieldName]: value });

  const setPreviousValue = (fieldName: TEditableField) =>
    onChange(fieldName, initialValues[fieldName]);

  const onCancel = (fieldName: TEditableField) => {
    setViewMode(fieldName);
    setPreviousValue(fieldName);
  };

  const onSave = async (fieldName: TEditableField, id: string) => {
    const data = isMetadata(fieldName)
      ? JSON.parse(values.metadata)
      : values[fieldName];
    try {
      await updatePoint({
        variables: { input: { point: { [fieldName]: data }, id } },
      });
      setViewMode(fieldName);
      notify.success(`"${LABELS[fieldName]}" has been successfully updated`);
    } catch (e: any) {
      notify.error(e?.message);
    }
  };

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <StyledWrapper>
      <StyledColumn>
        <StyledRow>
          <TextField
            id="outlined-required"
            label="Point name"
            type="text"
            style={{ width: "100%" }}
            value={values.name}
            onChange={(e) => onChange("name", e.target.value)}
            InputProps={{
              readOnly: fieldMode.name === "view",
            }}
          />
          <FieldModeButtons
            mode={fieldMode.name}
            fieldName="name"
            id={pointId || ""}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
            style={fieldModeButtonsStyles}
          />
        </StyledRow>
        <StyledRow>
          <TextField
            id="outlined-required"
            label="Id"
            type="text"
            style={{ width: "100%" }}
            value={values.id}
            InputProps={{
              readOnly: true,
            }}
          />
        </StyledRow>
        <StyledRow>
          <TextField
            id="outlined-required"
            label="External Id"
            type="text"
            style={{ width: "100%" }}
            value={values.externalId}
            onChange={(e) => onChange("externalId", e.target.value)}
            InputProps={{
              readOnly: fieldMode.externalId === "view",
            }}
          />
          <FieldModeButtons
            mode={fieldMode.externalId}
            fieldName="externalId"
            id={pointId || ""}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
            style={fieldModeButtonsStyles}
          />
        </StyledRow>
        <StyledRow>
          <DefaultDropdown
            variant="outlined"
            label="Space"
            placeholder="Space"
            onChange={(e) => onChange("spaceId", e.target.value)}
            value={values.spaceId || defaultOption.value} // need to check it, possible bug after refresh
            style={{ width: "100%" }}
            options={options}
            InputProps={{
              readOnly: fieldMode.spaceId === "view",
            }}
            select
          />
          <FieldModeButtons
            mode={fieldMode.spaceId}
            fieldName="spaceId"
            id={pointId || ""}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
            style={fieldModeButtonsStyles}
          />
        </StyledRow>
        <StyledRow>
          <TextField
            id="outlined-required"
            label="Signal types"
            type="text"
            style={{ width: "100%" }}
            value={values.signalTypes || "-"}
            InputProps={{
              readOnly: true,
            }}
            multiline
            maxRows={6}
          />
        </StyledRow>
      </StyledColumn>
      <StyledColumn>
        <StyledRow baseline viewMode={fieldMode.metadata === "view"}>
          <Metadata
            id="outlined-required"
            label="Metadata"
            type="text"
            style={{ width: "368px" }}
            value={values.metadata || "{}"}
            onChange={(e: any) => onChange("metadata", e.target.value)}
            InputProps={{
              readOnly: fieldMode.metadata === "view",
            }}
            minRows={16}
            maxRows={16}
            multiline
          />
          <FieldModeButtons
            mode={fieldMode.metadata}
            fieldName="metadata"
            id={pointId || ""}
            onEdit={setEditMode}
            onCancel={onCancel}
            onSave={onSave}
            style={fieldModeButtonsStyles}
          />
        </StyledRow>
        <StyledRow>
          <TextField
            id="outlined-required"
            label="Created"
            type="text"
            style={{ width: "100%" }}
            value={values.createdAt || "-"}
            onChange={(e) => onChange("name", e.target.value)}
            InputProps={{
              readOnly: true,
            }}
          />
        </StyledRow>
        <StyledRow>
          <TextField
            id="outlined-required"
            label="Last signal"
            type="text"
            style={{ width: "100%" }}
            value={values.lastActive || "-"}
            onChange={(e) => onChange("name", e.target.value)}
            InputProps={{
              readOnly: true,
            }}
          />
        </StyledRow>
      </StyledColumn>
    </StyledWrapper>
  );
}

const StyledWrapper = styled("div")`
  display: flex;
`;

const StyledColumn = styled("div")`
  flex: 1;
  padding: 32px 32px 32px 24px;
`;

const StyledRow = styled("div")<StyledRowProps>`
  display: flex;
  align-items: ${({ baseline }) => (baseline ? "baseline" : "center")};
  justify-content: ${({ viewMode }) =>
    viewMode ? "space-between" : "flex-start"};
  margin: 0 0 15.5px 0;
  padding: 5px 0 0 0;
`;

export default UpdatePointForm;
