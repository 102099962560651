import React, { Dispatch, SetStateAction } from "react";
import {
  format,
  formatISO,
  parseISO,
  subDays,
  subHours,
  subMinutes,
} from "date-fns";
import { IOption } from "common/interfaces/shared.interface";
import { EMPTY_STRING } from "../../common/utils";
import { IFilters } from "./spaces/spaces.page";

type TStateAction<T> = Dispatch<SetStateAction<T>>;

export const ROLES = [
  { label: "OWNER", value: "OWNER" },
  { label: "ADMIN", value: "ADMIN" },
  { label: "DEVELOPER", value: "DEVELOPER" },
  { label: "GUEST", value: "GUEST" },
];

export const LAST_ACTIVE = [
  { label: "Time Range", value: "" },
  { label: "Last 5 min", value: formatISO(subMinutes(new Date(), 5)) },
  { label: "Last 30 min", value: formatISO(subMinutes(new Date(), 30)) },
  { label: "Last hour", value: formatISO(subHours(new Date(), 1)) },
  { label: "Last 12 hours", value: formatISO(subHours(new Date(), 12)) },
  { label: "Last 24 hours", value: formatISO(subHours(new Date(), 24)) },
  { label: "Last 7 days", value: formatISO(subDays(new Date(), 7)) },
];

export const handleModal =
  <T, S extends TStateAction<T>>(state: T, setter: S) =>
  (modalName: keyof T, value: any) =>
    setter({
      ...state,
      [modalName]: value,
    });

export const modalsFactory = <T, S extends TStateAction<T>>(
  state: T,
  setter: S
) => {
  const setModal = handleModal(state, setter);
  return (modalName: keyof T) => ({
    showModal: () => setModal(modalName, { visible: true }),
    hideModal: () => setModal(modalName, { visible: false }),
    withParams: (params: T[typeof modalName]) =>
      setModal(modalName, {
        ...params,
      }),
  });
};

export const convertISO = (date: string | undefined) => {
  return date ? format(parseISO(date), "yyyy/MM/dd HH:mm") : "";
};

export const convertDate = (date: string | undefined) => {
  return date ? format(parseISO(date), "dd/MM/yyyy") : "";
};

export const getOptionValue = (
  option: IOption | string | string[] | undefined
) => (typeof option === "object" && "value" in option ? option.value : option);

const isValidOption = (node: React.ReactElement) =>
  "children" in node.props && "value" in node.props;

const createOption = (label: string, value: string): IOption => ({
  label,
  value,
});

export const getOption = (node: React.ReactNode): IOption | string =>
  React.isValidElement(node) && isValidOption(node)
    ? createOption(node.props.children, node.props.value)
    : EMPTY_STRING;

export const lastActiveNormalizer = (sessionData: IFilters) => {
  if ("updatedAt" in sessionData && typeof sessionData.updatedAt === "object") {
    const label = sessionData.updatedAt.label;
    return {
      ...sessionData,
      updatedAt: LAST_ACTIVE.find((item) => item.label === label),
    };
  }
  return sessionData;
};

export const allPropsEmpty = <T extends object>(state: T) =>
  Object.values(state).every((val) => val === "");
