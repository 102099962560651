import React, { useState, createContext, useEffect } from "react";

type AuthUser = {
  data:
    | {
        id: string | null;
        lastname: string | null;
        firstname: string | null;
        emails: { address: string }[] | null;
      }
    | undefined;
  isAuth: boolean;
  xTenantHeader?: string;
};

type AuthContextType = {
  user: AuthUser;
  handlerLoginUser: (params: AuthUser) => void;
  handlerLogoutUser: (params: AuthUser) => void;
};

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType | null>(
  {} as AuthContextType
);

export const initialUserState = {
  data: { id: null, lastname: null, firstname: null, emails: null },
  isAuth: false,
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = useState<AuthUser>(initialUserState);
  const handlerLoginUser = (params: AuthUser) => {
    setUser(params);
    localStorage.setItem("userData", JSON.stringify(params));
  };

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const authContext = JSON.parse(localStorage.getItem("userData") || "{}");
      setUser(authContext);
    } else {
      setUser(initialUserState);
    }
  }, []);

  const handlerLogoutUser = (params: AuthUser) => {
    setUser(initialUserState);
    localStorage.removeItem("userData");
  };
  return (
    <AuthContext.Provider value={{ user, handlerLoginUser, handlerLogoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};
