import { gql } from "@apollo/client";

export const GET_PROFILE = gql`
  query GET_PROFILE {
    profile {
      firstname
      lastname
      id
      primaryEmail
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE($input: UpdateUserInput!) {
    profile {
      update(input: $input) {
        firstname
        lastname
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD($input: UpdatePasswordInput!) {
    profile {
      updatePassword(input: $input) {
        success
      }
    }
  }
`;
