import * as React from "react";
import { styled } from "@mui/material";
// import Tab from "@mui/material/Tab";
import { TabUnstyled, TabUnstyledProps, tabUnstyledClasses } from "@mui/base";

const StyledTabItem = styled(TabUnstyled)<TabUnstyledProps>(({ theme }) => {
  const fontSize = "16px";
  const lineHeight = "20px";
  const fontWeight = 400;
  const fontWeightActive = 500;
  const color = theme.palette.grey[900];
  const colorHover = theme.palette.grey[500];
  const colorActive = "#0C116B";
  const backgroundColorHover = theme.palette.grey[50];
  const fontFamily = theme.typography.fontFamily;
  const borderBottom = `1px solid ${colorHover}`;
  const padding = "12px 0px 16px 24px";
  const paddingRight = "clamp(16px, 35px, 50px)";
  const margin = "0px";
  const backgroundColor = "transparent";
  const borderTop = "none";
  const borderLeft = "none";
  const borderRight = "none";

  return {
    color,
    lineHeight,
    fontWeight,
    borderTop,
    borderRight,
    borderLeft,
    fontSize,
    fontFamily,
    borderBottom,
    backgroundColor,
    margin,
    padding,
    paddingRight,
    "&:hover": {
      color,
      backgroundColor: "transparent",
      borderBottom: `3px solid ${colorHover}`,
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderBottom: `3px dashed ${colorHover}`,
    },
    [`&.${tabUnstyledClasses.selected}`]: {
      color: colorActive,
      fontWeight: fontWeightActive,
      borderBottom: `3px solid ${colorActive}`,
    },
  };
});

const TabItem: React.FC<TabUnstyledProps> = (props) => {
  return <StyledTabItem {...props} />;
};

export default TabItem;
