import { gql } from "@apollo/client";

export const GET_CARDS = gql`
  query GET_CARDS {
    cards {
      id
      name
      brand
      lastFourDigit
      currency
      isDefault
      address {
        id
        country
        state
        city
        postalCode
        line
        line2
      }
      expMonth
      expYear
    }
  }
`;
