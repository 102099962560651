import * as React from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import { ButtonUnstyled, ButtonUnstyledProps } from "@mui/base";
import { LabelFont } from "@mui/material/styles/createTypography";

export type IconButtonVariant = "primary" | "neutral";
export type IconButtonSize = "default" | "small";

export interface IconButtonProps extends ButtonUnstyledProps {
  variant: IconButtonVariant;
  size?: IconButtonSize;
  iconAtEnd?: boolean;
}

const StyledIconButton = styled(ButtonUnstyled)<IconButtonProps>(
  ({ theme, variant, size }) => {
    let color: string;
    let borderColor: string;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    let font: LabelFont;

    switch (variant) {
      case "primary":
        color = theme.palette.common.blue[3];
        borderColor = theme.palette.common.blue[2];
        break;

      case "neutral":
      default:
        color = theme.palette.common.grey[5];
        borderColor = theme.palette.common.grey[4];
        break;
    }

    switch (size) {
      case "small":
        font = theme.typography.custom.BodyS;
        break;

      case "default":
      default:
        font = theme.typography.custom.BodyM;
        break;
    }

    return {
      color,
      backgroundColor: "transparent",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: isMobile ? font.sizeMobile : font.sizeDesktop,
      lineHeight: "26px",
      border: "2px solid transparent",
      margin: "-2px",
      display: "block",

      "&:hover": {
        borderBottom: `2px solid ${borderColor}`,
      },

      "&:focus": {
        borderBottom: `2px dashed ${borderColor}`,
        outline: "none",
      },

      "&:active": {
        transform: "scale(0.95)",
        borderBottom: `2px solid ${borderColor}`,
      },

      "&.Mui-disabled, &.Mui-disabled:hover, &.Mui-disabled:focus": {
        color: theme.palette.common.grey[3],
        borderBottom: "none",
      },

      "&.Mui-disabled:active": {
        transform: "none",
        borderBottom: "none",
      },
    };
  }
);

const IconButton: React.FC<IconButtonProps> = ({
  iconAtEnd,
  children,
  size,
  ...props
}) => {
  return (
    <StyledIconButton size={size} {...props}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& > svg": {
            width: size === "small" ? "16px" : "24px",
            height: size === "small" ? "16px" : "24px",
            marginRight: iconAtEnd ? "0" : "4px",
            marginLeft: iconAtEnd ? "4px" : "0",
          },
        }}
      >
        {children}
      </Box>
    </StyledIconButton>
  );
};

export default IconButton;
