import React from "react";
import { useParams } from "react-router-dom";
import "d4-counter-sensor-component";
import "d4-counter-sensor-graph";
import "d4-analogue-sensor";
import { styled } from "@mui/material";

// @ts-ignore
function Dashboard({ metadata }) {
  const { name: tenantId, pointId } = useParams();

  const { visitorsTotal, visitorsNow, voltage, popularTimes } = metadata;

  return (
    <div>
      <StyledGrid>
        {visitorsTotal && (
          /* @ts-ignore*/
          <d4-counter-sensor
            api_endpoint={`${process.env.REACT_APP_API_URL}`}
            title={visitorsTotal.title ?? "Number of visitors"}
            tenant_id={tenantId}
            point_id={pointId}
            type={visitorsTotal.signalType}
            style={{ flex: 1, minWidth: 450 }}
            show_history
          >
            {/* @ts-ignore*/}
          </d4-counter-sensor>
        )}
        {visitorsNow && (
          /* @ts-ignore*/
          <d4-counter-sensor
            api_endpoint={`${process.env.REACT_APP_API_URL}`}
            title={visitorsNow.title ?? "Visitors now"}
            usecase="visitors_now"
            signal_type_in={visitorsNow.signalTypeIn}
            signal_type_out={visitorsNow.signalTypeIn}
            tenant_id={tenantId}
            point_id={pointId}
            style={{ flex: 1, minWidth: 450 }}
          >
            {/* @ts-ignore*/}
          </d4-counter-sensor>
        )}
        {voltage && (
          /* @ts-ignore*/
          <d4-analogue-sensor
            api_endpoint={`${process.env.REACT_APP_API_URL}`}
            title={voltage.title ?? "Current Voltage"}
            type={voltage.signalType}
            tenant_id={tenantId}
            point_id={pointId}
            style={{ flex: 1, minWidth: 450 }}
          >
            {/* @ts-ignore*/}
          </d4-analogue-sensor>
        )}
        {popularTimes && (
          /* @ts-ignore*/
          <d4-counter-sensor-graph
            api_endpoint={`${process.env.REACT_APP_API_URL}`}
            title={popularTimes.title ?? "Popular times"}
            tenant_id={tenantId}
            point_id={pointId}
            type={popularTimes.signalType}
            style={{ flex: 1, minWidth: "100%" }}
            show_history
          >
            {/* @ts-ignore*/}
          </d4-counter-sensor-graph>
        )}
      </StyledGrid>
    </div>
  );
}

const StyledGrid = styled("div")`
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 30px;
`;

export default Dashboard;
