import React, {
  useState,
  ChangeEventHandler,
  MouseEvent,
  useEffect,
} from "react";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

import FormHelperText from "@mui/material/FormHelperText";
import {
  StyledInputLabel,
  StyledOutlinedTextFiled,
} from "../../common/styles/material-ui-styles";
import InputLabel from "@mui/material/InputLabel";

import { useResetPasswordWithToken } from "../../hooks/useResetPasswordWithToken";

import "./resetPasswordWithToken.page.css";
import { IconButton } from "@mui/material";

const ResetPasswordWithToken: React.FC = () => {
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const { search } = useLocation();
  const token = search.match(/token=(.*)/)?.[1];
  const [response, setResponse] = useState(0);

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [showRules, setShowRules] = useState<boolean>(false);
  const [validatePassword, setValidatePassword] = useState({
    upper: false,
    special: false,
    lower: false,
    passwordsMatch: false,
  });

  const reset = useResetPasswordWithToken({
    password: password,
    token: token,
  });

  // ====================================================================

  const isUpper = (arg: string) => {
    return arg.match(/^(?=.*[A-Z])/) ? true : false;
  };

  const isLower = (arg: string) => {
    return arg.match(/^(?=.*[a-z])/) ? true : false;
  };

  const isSpecialOrNumber = (arg: string) => {
    return arg.match(/^((?=.*\d)|(?=.*\W+))/) ? true : false;
  };
  const isMatch = (arg: string, arg1: string) => {
    return arg === arg1 ? true : false;
  };

  // =====================================================================

  const validatePasswordTypeHandler: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { value } = e.target;
    setPassword(value);
    setShowRules(true);
    if (value === "") {
      setShowRules(false);
    } else {
      setValidatePassword({
        upper: isUpper(value),
        lower: isLower(value),
        special: isSpecialOrNumber(value),
        passwordsMatch: isMatch(value, confirmPassword),
      });
    }
  };

  const validateConfirmPasswordTypeHandler: ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const { value } = e.target;
    setValidatePassword({
      ...validatePassword,
      passwordsMatch: isMatch(password, value),
    });
    setConfirmPassword(e.target.value);
  };

  const showPasswordEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
    setShowPassword(!showPassword);
  };

  const showConfirmPasswordEventHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  //===================================================================

  const submitHandlerReset = (event: MouseEvent<HTMLButtonElement>) => {
    //API CALL TO RESET PASSWORD
    reset({ password, token })
      .then((res) => {
        setResponse(1);
        navigate("/404", { state: "password_updated", replace: true });
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  useEffect(() => {
    trackPageView({ documentTitle: "Reset password with token page" });
  }, []);

  //===================================================================

  return (
    <ResetOuter>
      <ResetLeft>
        <NavLink to="/" style={{ width: "100%" }}>
          <img className="logo" src="/logo.svg" alt="D4 logo" />
        </NavLink>
        <IconographyOuter>
          <ResetForm>
            <H1>Reset Password</H1>
            <div className="reset-form">
              {response === 0 && (
                <>
                  <div className="reset-container">
                    <FormControl variant="outlined" sx={{ mt: "15px" }}>
                      <FormHelperText
                        className="input-label"
                        sx={{
                          marginBottom: "16px",
                        }}
                        id="outlined-password-helper-text"
                      >
                        Password
                      </FormHelperText>

                      <StyledOutlinedTextFiled
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={validatePasswordTypeHandler}
                        aria-describedby="outlined-password-helper-text"
                        inputProps={{
                          "aria-label": "Password",
                        }}
                        sx={{
                          "#outlined-adornment-password": {
                            color: "#ffffff",
                          },
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={showPasswordEventHandler}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: "#ffffff" }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {showRules && (
                      <div className="reset-password-rules">
                        <ul>
                          <li
                            className={
                              validatePassword.upper ? "valid" : "not-valid"
                            }
                          >
                            Needs to contain at least 1 upper case letter{" "}
                          </li>
                          <li
                            className={
                              validatePassword.lower ? "valid" : "not-valid"
                            }
                          >
                            Needs to contain at least 1 lower case letter
                          </li>
                          <li
                            className={
                              validatePassword.special ? "valid" : "not-valid"
                            }
                          >
                            Needs to contain at least 1 number or special
                            charcter
                          </li>
                          <li
                            className={
                              validatePassword.passwordsMatch
                                ? "valid"
                                : "not-valid"
                            }
                          >
                            Passwords match
                          </li>
                        </ul>
                      </div>
                    )}
                    <FormControl variant="outlined" sx={{ mt: "15px" }}>
                      <FormHelperText
                        className="input-label"
                        sx={{
                          marginBottom: "16px",
                        }}
                        id="outlined-confirm-password-helper-text"
                      >
                        Confirm Password
                      </FormHelperText>
                      <StyledOutlinedTextFiled
                        id="outlined-adornment-confirm-password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        aria-describedby="outlined-confirm-password-helper-text"
                        inputProps={{
                          "aria-label": "Confirm Password",
                        }}
                        sx={{
                          "#outlined-adornment-confirm-password": {
                            color: "#ffffff",
                          },
                        }}
                        onChange={validateConfirmPasswordTypeHandler}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ color: "#ffffff" }}
                              aria-label="toggle password visibility"
                              onClick={showConfirmPasswordEventHandler}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <button
                      className="btn super-large yellow reset"
                      onClick={submitHandlerReset}
                    >
                      Reset
                    </button>
                  </div>
                </>
              )}
            </div>
          </ResetForm>
        </IconographyOuter>
      </ResetLeft>
    </ResetOuter>
  );
};

const ResetOuter = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "100vh",
  background: "#010032",
});
const H1 = styled("h1")({
  color: "#FFFFFF",
  marginBottom: 32,
});

const ResetLeft = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundImage: "url('/home-page-background.svg')",
  objectFit: "contain",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "bottom",
});

const ResetForm = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});

const IconographyOuter = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  maxWidth: "520px",
  margin: "0 auto",
});

export default ResetPasswordWithToken;
