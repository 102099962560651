import React, { Dispatch, useEffect, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ReactComponent as Signal } from "common/assets/overview/signal.svg";
import Box from "@mui/material/Box";
import { Transition, TransitionGroup } from "react-transition-group";
import { ReactComponent as DataGenerator } from "common/assets/overview/datagenerator.svg";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import LinearProgress from "@mui/material/LinearProgress";
import { ISignal } from "./signals.interface";
import Paper from "@mui/material/Paper";
import { GET_SIGNALS, SIGNAL_ADDED_FORWARDING } from "./signals.gql";
interface ISignalProps {
  metaData: IState;
  isIntervalActive: boolean;
}

interface IState {
  id: string;
  name: string;
  externalId: string;
  spaceId: string;
  metadata: string;
  createdAt: string;
  lastActive: string;
  signalTypes?: string[];
}
interface ILastSignal {
  signals: {
    nodes: ISignal[] | [];
  };
}

function Signals({ metaData, isIntervalActive }: ISignalProps) {
  const [lastSignals, setLastSignals] = useState<ISignal[] | []>([]);
  const [metaObject, seMetaObject] = React.useState(
    JSON.parse(metaData.metadata)
  );
  useEffect(() => {
    seMetaObject(JSON.parse(metaData.metadata));
  }, [metaData.metadata]);
  const [isInterval, setIsInterval] = React.useState<any | null>(
    metaObject?.datagenerator?.isInterval
      ? metaObject?.datagenerator?.isInterval
      : false
  );
  const [connectSignals, setConnectSignals] = useState(false);
  const { name: tenantId, pointId } = useParams();
  const checkSignalin1minute = () => {
    const noData = !lastSignals.length;
    if (noData) {
      setConnectSignals(true);
    }
  };
  const { data: lastSignalsData } = useQuery<ILastSignal>(GET_SIGNALS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    variables: { where: { pointId: { _EQ: pointId } } },
  });
  const { data } = useSubscription(SIGNAL_ADDED_FORWARDING, {
    variables: { pointId },
  });

  const noData = !lastSignals.length;
  useEffect(() => {
    const MINUTE_MS = 4000;
    const interval = setInterval(() => {
      if (!lastSignalsData) {
        setConnectSignals(true);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (lastSignalsData) {
      const data1 = lastSignalsData?.signals?.nodes
        ? [...lastSignalsData.signals.nodes]
        : [];
      // const isGenerator = data1.some((a: any) => {
      //   return a?.metadata?.isDataGenerator == true;
      // });
      // setDataGenerator(isGenerator);
      setLastSignals(data1.reverse());
    }
  }, [lastSignalsData, data]);

  useEffect(() => {
    if (data?.signalAdded) {
      const data1 = lastSignalsData?.signals?.nodes
        ? [...lastSignalsData.signals.nodes]
        : [];
      // const isGenerator = data1.some((a: any) => {
      //   return a?.metadata?.isDataGenerator == true;
      // });
      // setDataGenerator(isGenerator);
      setLastSignals(data1.reverse());
    }
  }, [data?.signalAdded?.id]);

  return (
    <StyledLayout>
      {noData ? (
        <>
          <StyledHeader>
            <StyledHeaderText>Waiting for signals...</StyledHeaderText>
            <LinearProgress
              sx={{
                background: "#FFFFFF",
                margin: "16px 0 0 0",
                ".MuiLinearProgress-bar": { background: "#7AD4C7" },
              }}
            />
          </StyledHeader>
          {noData && connectSignals && (
            <Box gridColumn="span 6">
              <Item className="body">
                <Signal />
                <Item className="root1">
                  <StyledTitle className="body">
                    No signals showing up?
                  </StyledTitle>
                  <Item className="container">
                    <StyledLabel className="body">
                      Make sure you connected the device with Dimension Four
                      through our{" "}
                      <a
                        style={{ color: "#010032" }}
                        href="https://dimensionfour.io/resources/developer-docs/api-overview/graphql-playground"
                      >
                        GraphQL API
                      </a>{" "}
                      or read our{" "}
                      <a
                        style={{ color: "#010032" }}
                        href="https://dimensionfour.io/resources/developer-docs/getting-started"
                      >
                        documentation
                      </a>{" "}
                      about how to get started.
                    </StyledLabel>
                  </Item>
                </Item>
              </Item>
            </Box>
          )}
        </>
      ) : (
        <>
          {(isInterval || isIntervalActive) && (
            <Box gridColumn="span 12" style={{ margin: "64px 0 32px" }}>
              <Item className="body2">
                <DataGenerator />
                <Item className="root1">
                  <StyledTitle className="body">
                    Data Generator is Active
                  </StyledTitle>
                  <Item className="container">
                    <StyledLabel className="body">
                      The data will be generated as long as you keep your
                      browser open. You can edit the generator at all times.
                    </StyledLabel>
                  </Item>
                </Item>
              </Item>
            </Box>
          )}
          <StyledHeader>
            <StyledHeaderText>
              Last 10 signals with real time data
            </StyledHeaderText>
          </StyledHeader>
          <StyledTable
            sx={{ minWidth: 650, tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <StyledTableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </StyledTableHead>
            <TransitionGroup component={TableBody}>
              {lastSignals.map((signal) => (
                <Transition timeout={500} key={signal.id}>
                  {(state) => (
                    // state change: exited -> entering -> entered -> exiting -> exited
                    <TableRowWithFade
                      state={state}
                      key={signal.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {signal.type}
                      </StyledTableCell>
                      <StyledTableCell>{signal.unit}</StyledTableCell>
                      <StyledTableCell>{signal.data.rawValue}</StyledTableCell>
                      <TableCell>{signal.createdAt}</TableCell>
                    </TableRowWithFade>
                  )}
                </Transition>
              ))}
            </TransitionGroup>
          </StyledTable>
        </>
      )}
    </StyledLayout>
  );
}

const StyledHeader = styled("div")`
  padding: 16px;
  background: ${({ theme }) => theme.palette.common.green[1]};
  border-radius: 4px;
`;

const StyledHeaderText = styled("div")`
  color: ${({ theme }) => theme.palette.common.grey[5]};
`;

const StyledLayout = styled("div")`
  margin: 28px 0;
`;

const StyledTable = styled(Table)`
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: none;
  border-radius: 0;
`;

const StyledTableHead = styled(TableHead)`
  background: ${({ theme }) => theme.palette.common.grey[1]};
`;

const StyledTableCell = styled(TableCell)`
  max-width: 320px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TableRowWithFade = styled(TableRow)<any>`
  transition: 0.5s ease-in;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "table-row")};
  background: ${({ state, theme }) => {
    switch (state) {
      case "entering":
        return theme.palette.common.green[2];
      case "entered":
        return theme.palette.common.white;
    }
  }};
`;

export default Signals;
const Item = styled(Paper)(({ theme }) => ({
  "&.root": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    color: "#fff",
    boxShadow: "none",
  },
  "&.root1": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "11px 0 0 32px",
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    maxWidth: "80%",
    width: "100%",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.container": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "11px 0 0 0",
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    boxShadow: "none",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.body": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    display: "flex",
    borderRadius: "20px",
    minHeight: "138px",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    border: "1px solid #D7E2FB",
  },
  "&.body2": {
    backgroundColor: "#FFFFFF",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    display: "flex",
    borderRadius: "20px",
    minHeight: "198px",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
  },
  "&.body3": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
    display: "flex",
    borderRadius: "20px",
    alignItems: "center",
    textAlign: "start",
    boxShadow: "none",
  },
  "&.body4": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    width: "100%",
  },
  "&.body5": {
    backgroundColor: "#FFFFFF",

    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    borderRadius: "20px",
  },
}));
const StyledLabel = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 100%;
  &.iconLabel {
    margin-left: 10px;
  }
  &.body {
    color: var(--dark-blue-color);
    margin-left: 0;
    max-width: 100%;
  }
`;

const StyledTitle = styled("h4")`
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 32px 0;
  font-family: "poppins";
  color: ${({ theme }) => theme.palette.common.white};
  &.overview {
    color: #ffa654;
    text-transform: capitalize;
  }
  &.body {
    margin: 0;
    color: var(--dark-blue-color);
  }
  &.document {
    color: var(--dark-blue-color);
    margin: 32px 0 0;
  }
  &.steps {
    color: var(--dark-blue-color);
    margin: 0;
  }
`;
