import React from "react";
import "./spinner.css";
import Modal from "../../../cmp/modals/modal/Modal.modals.cmp";

/* TODO: Replace it by MUI component */

const Spinner: React.FC = () => {
  return (
    <Modal type="spinner" height={80} width={80}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Modal>
  );
};

export default Spinner;
