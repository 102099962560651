import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import ClearIcon from "@mui/icons-material/Clear";
import useToggle from "hooks/useToggle";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { ITenantData } from "common/interfaces/tenant.interface";
import Spinner from "common/assets/spinner/spinner";
import { Container } from "common/styles/material-ui-styles";
import IconButton from "lib/atoms/Button/IconButton";
import Members from "./members";
import AccessTokens from "./accessTokens";
import MQTT from "./mqtt";
import DeleteTenantModal from "../deleteTenantModal/deleteTenantModal.cmp";
import TenantInfo from "./tenantInfo";
import Webhooks from "./webhooks/webhooks";

function ManageTenant() {
  const { toggle, open } = useToggle();
  const { name: id } = useParams();
  const { data, loading } = useQuery<ITenantData>(GET_TENANT, {
    context: {
      headers: {
        "x-tenant-id": id,
      },
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  const tenantId = data?.tenant?.id;
  const members = data?.tenant?.members;
  const accessTokens = data?.accessTokens;
  const mqtt = data?.tenant?.settings?.mqtt;
  const tenantInfo = {
    id: tenantId,
    name: data?.tenant?.name,
    createdAt: data?.tenant?.createdAt,
    updatedAt: data?.tenant?.updatedAt,
  };

  if (loading) return <Spinner />;

  return (
    <>
      <StyledContainer>
        <TenantInfo data={tenantInfo} />
        <Members data={members} tenantId={tenantId} />
        <AccessTokens data={accessTokens} tenantId={tenantId} />
        <MQTT data={mqtt} />
        <Webhooks />
        <StyledFooter>
          <IconButton variant="neutral" onClick={toggle}>
            <ClearIcon />
            Delete tenant
          </IconButton>
        </StyledFooter>
      </StyledContainer>
      <DeleteTenantModal open={open} tenantId={tenantId} onClose={toggle} />
    </>
  );
}

export const StyledContainer = styled(Container)`
  margin: 48px 0;
`;

export const StyledHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 4px 0;
`;

export const StyledHeadline = styled("h2")`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 125%;
`;

export const StyledTable = styled(Table)`
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: none;
  border-radius: 0;
`;

export const StyledTableHead = styled(TableHead)`
  background: ${({ theme }) => theme.palette.common.grey[1]};
`;

export const StyledFooter = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const StyledSubHeadline = styled("div")`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 16px;
  color: #000000;
`;

export default ManageTenant;
