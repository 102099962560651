import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ISpace, ISpaces } from "pages/tenant/tenant.interface";
import { IOption } from "common/interfaces/shared.interface";
import { GET_SPACES } from "common/graphql/space.gql";

type ID = string | undefined;
type TSpaces = ISpace[] | undefined;
type Type = "SPACE" | "PARENT" | "POINT";

const ROOT_OPTION = {
  label: "ROOT",
  value: "",
};

const findSpace = (spaces: TSpaces, spaceId: ID): ISpace | undefined =>
  spaces?.find((node) => node.id === spaceId);

function useSpaceOptions(tenantId: ID, type: Type) {
  const { spaceId } = useParams();
  const { data } = useQuery<ISpaces>(GET_SPACES, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });
  const spaces = data?.spaces?.nodes;
  const CURRENT_SPACE = findSpace(spaces, spaceId);

  const createOption = (label: string, value: string): IOption => ({
    label,
    value,
  });

  const getParentSpace = (space: ISpace): IOption =>
    space?.parent
      ? createOption(space.parent.name, space.parent.id)
      : ROOT_OPTION;

  const getDefaultOption = (space: ISpace | undefined): IOption => {
    if (!space) return ROOT_OPTION;
    if (type === "PARENT") return getParentSpace(space);
    return createOption(space.name, space.id);
  };

  const getParents = (initialSpace: ISpace | undefined) => {
    const parents = [ROOT_OPTION] as IOption[];
    const addParent = (currentSpace: ISpace | undefined) =>
      spaces?.forEach((space) => {
        if (currentSpace?.parent?.id === space.id) {
          parents.push(createOption(space.name, space.id));
          addParent(space);
        }
      });
    if (type === "PARENT") addParent(initialSpace);
    return parents;
  };

  const getOptionsList = () => {
    const result =
      spaces?.map((node) => createOption(node.name, node.id)) || [];
    if (!result.length) return [ROOT_OPTION];
    if (type === "POINT") return result; // Because we can't create point without space
    return [...result, ROOT_OPTION];
  };

  const options = getOptionsList();
  const defaultOption = getDefaultOption(CURRENT_SPACE);
  const parentOptions = getParents(CURRENT_SPACE);
  return { options, parentOptions, defaultOption };
}

export default useSpaceOptions;
