import * as React from "react";
import { styled } from "@mui/material";
import { ButtonUnstyled, ButtonUnstyledProps } from "@mui/base";

export type ButtonVariant = "primary" | "neutral" | "secondary";

interface ButtonProps extends ButtonUnstyledProps {
  variant: ButtonVariant;
}

const StyledButton = styled(ButtonUnstyled)<ButtonProps>(
  ({ theme, variant }) => {
    let backgroundColor: string;
    let backgroundColorHover: string;
    let color: string;
    let colorHover: string;
    let borderColor: string;
    let borderColorHover: string;

    switch (variant) {
      case "primary":
        backgroundColor = theme.palette.common.blue[3];
        backgroundColorHover = theme.palette.common.blue[4];
        color = theme.palette.common.white;
        colorHover = theme.palette.common.white;
        borderColor = theme.palette.common.white;
        borderColorHover = theme.palette.common.white;
        break;

      case "secondary":
        backgroundColor = theme.palette.common.white;
        backgroundColorHover = theme.palette.common.blue[1];
        color = theme.palette.common.grey[4];
        colorHover = theme.palette.common.grey[4];
        borderColor = theme.palette.common.grey[2];
        borderColorHover = theme.palette.common.grey[2];
        break;

      case "neutral":
      default:
        backgroundColor = theme.palette.common.white;
        backgroundColorHover = theme.palette.common.white;
        color = theme.palette.common.blue[3];
        colorHover = theme.palette.common.blue[5];
        borderColor = theme.palette.common.blue[3];
        borderColorHover = theme.palette.common.blue[5];
        break;
    }

    return {
      backgroundColor,
      color,
      borderColor,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "4px",
      lineHeight: "26px;",
      padding: "8px 24px",

      "&:hover": {
        backgroundColor: backgroundColorHover,
        color: colorHover,
        borderColor: borderColorHover,
      },

      "&:focus": {
        borderStyle: "dashed",
        outline: "none",
      },

      "&:active": {
        transform: "scale(0.95)",
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette.common.grey[1],
        borderColor: theme.palette.common.grey[2],
        color: theme.palette.common.grey[3],
        cursor: "not-allowed",
      },

      "&.Mui-disabled:active": {
        transform: "none",
      },
    };
  }
);

const Button: React.FC<ButtonProps> = (props) => {
  return <StyledButton {...props} />;
};

export default Button;
