import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { convertISO } from "../tenant.utils";
import useTracker from "hooks/useTracker";
import useToggle from "hooks/useToggle";
import useNotification from "hooks/useNotification";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { ReactComponent as DataGenerator } from "common/assets/overview/datagenerator.svg";
import {
  DELETE_POINT,
  GET_POINT,
  GET_POINTS,
  UPDATE_POINT,
} from "common/graphql/point.gql";
import { CREATE_SIGNAL } from "../components/updateSignalsModal/updateSignals.gql";
import { GET_TENANTS } from "common/graphql/tenant.gql";
import Spinner from "common/assets/spinner/spinner";
import Modal from "lib/organisms/Modal";
import IconButton from "lib/atoms/Button/IconButton";
import Layout from "cmp/templates/layoutTemplate.cmp";
import UpdatePointForm from "../components/updatePointForm/updatePointForm";
import { ITenants } from "hooks/useTenant";
import { IPoints } from "../tenant.interface";
import { ReactComponent as ProfileImage } from "../../../common/assets/profile.svg";
import TabsPanel from "../../../lib/molecules/TabsPanel/TabsPanel";
import Dashboard from "../components/dashboard/dashboard.cmp";
import Signals from "../components/signals/signals.cmp";
import { ISpacePageState } from "../spaces/spaces.page";
import { ReactComponent as Document } from "common/assets/overview/document.svg";
import { useLocation } from "react-use";
import CreateSignalsModal from "../components/createSignalsModal/createSignalsModal.cmp";
import UpdateSignalsModal from "../components/updateSignalsModal/updateSignalsModal.cmp";
const ENTER_CODE = "\x0D";

const TABS = ["SIGNALS", "MANAGE POINT"] as const;
const TABS_DASH = ["DASHBOARD", "SIGNALS", "MANAGE POINT"] as const;

function PointPage() {
  const { trackPageView, trackEvent } = useTracker();
  const [modals, setModals] = useState({
    createSignal: false,
    updateSignal: false,
  });
  const [isInterval, setIsInterval] = React.useState<boolean>(false);
  const { name: tenantId, pointId } = useParams();
  const navigate = useNavigate();
  const { open: showModal, toggle: toggleModal } = useToggle();
  const { notify } = useNotification();
  const location = useLocation();

  const pageState = location?.state as ISpacePageState;

  const [tab, setTab] = useState(
    pageState?.defaultTab == undefined ? 1 : pageState?.defaultTab
  );
  const context = {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
  };

  const variables = {
    variables: {
      input: {
        id: {
          _EQ: pointId,
        },
      },
    },
  };

  const { data, loading } = useQuery(GET_POINT, {
    ...context,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    ...variables,
  });

  const [updatePoint] = useMutation(UPDATE_POINT, {
    ...context,
    update: (store, { data }) => {
      const pointsList = store.readQuery<IPoints>({
        query: GET_POINTS,
        variables: { pointsInput: {} },
      });
      const updatePointData = data.point.update;

      if (pointsList && "points" in pointsList) {
        store.writeQuery<IPoints>({
          query: GET_POINTS,
          data: {
            points: {
              nodes: pointsList.points.nodes.map((node) =>
                node.id === updatePointData.id ? updatePointData : node
              ),
            },
          },
        });
      }
    },
  });

  const [deletePoint] = useMutation(DELETE_POINT, {
    ...context,
    update: (store, { data }) => {
      const tenantsData = store.readQuery<ITenants>({ query: GET_TENANTS });
      const pointsList = store.readQuery<IPoints>({
        query: GET_POINTS,
        variables: { pointsInput: {} },
      });
      const deletePointData = data.point.delete;

      if (pointsList && "points" in pointsList) {
        store.writeQuery<IPoints>({
          query: GET_POINTS,
          data: {
            points: {
              nodes: pointsList.points.nodes.filter(
                (node) => node.id !== deletePointData.id
              ),
            },
          },
        });
      }

      if (tenantsData && "tenants" in tenantsData) {
        store.writeQuery<ITenants>({
          query: GET_TENANTS,
          data: {
            tenants: tenantsData.tenants.map((tenant) =>
              tenant.id !== tenantId
                ? tenant
                : { ...tenant, totalPoints: tenant.totalPoints - 1 }
            ),
          },
        });
      }
    },
  });

  const onTabChange = (event: React.SyntheticEvent | any) => {
    const getActionName = () => {
      switch (event.target.innerText) {
        case "MANAGE POINT":
          setTab(1);
          return "clickedManagePoint";
        case "SIGNALS":
          setTab(0);
          return "clickedViewSignals";
        default:
          setTab(0);
          return "";
      }
    };
    const action = getActionName();
    trackEvent({ action });
  };

  const pointName = data?.point?.name;

  const onSubmit = async () => {
    await deletePoint({
      variables: {
        input: { id: pointId },
      },
    });
    trackEvent({ action: "clickedDeletePoint" });
    notify.success(`Point "${pointName}" has been successfully deleted!`);
    navigate(`/t/${tenantId}`, { replace: true });
  };

  // converting array of the 'signalTypes' and the 'metadata' object to string
  const initialValues = useMemo(
    () => ({
      name: data?.point?.name,
      id: data?.point?.id,
      externalId: data?.point?.externalId,
      spaceId: data?.point?.spaceId,
      createdAt: convertISO(data?.point?.createdAt),
      lastActive: convertISO(data?.point?.lastActive),
      metadata: JSON.stringify(data?.point?.metadata),
      signalTypes: data?.point?.existingSignalTypes?.join(ENTER_CODE),
    }),
    [
      data?.point?.externalId,
      data?.point?.id,
      data?.point?.createdAt,
      data?.point?.lastActive,
      data?.point?.metadata,
      data?.point?.name,
      data?.point?.spaceId,
      data?.point?.existingSignalTypes,
    ]
  );

  const dashboardMetadata = data?.point?.metadata?.dashboard;
  const metadata = data?.point?.metadata ? data?.point?.metadata : [];
  const externalId = metadata?.datagenerator?.id
    ? metadata?.datagenerator?.id
    : "";
  useEffect(() => trackPageView({ documentTitle: "Point's page" }), []);
  const timerRef: number | ReturnType<typeof setTimeout> = 0;

  const context1 = {
    headers: {
      "x-tenant-id": tenantId,
    },
  };

  const [createSignal] = useMutation(CREATE_SIGNAL, {
    context: context1,
  });

  function randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min).toString();
  }
  const onFocusFunction = () => {
    if (data?.point?.metadata?.datagenerator?.data) {
      if (data?.point?.metadata?.datagenerator?.isInterval) {
        setIsInterval(true);
        setTimeout(async () => {
          const dd = data.point.metadata.datagenerator.data;

          const a = dd.map(({ ...obj }) => {
            const o = Object.assign({}, obj);
            if (o?.value) {
              o.value = randomNumber(
                o.value - Math.floor(o.value * 0.1),
                Math.floor(o.value) + Math.floor(o.value * 0.1)
              );
              return o;
            }
          });
          await createSignal({
            variables: {
              input: {
                pointId: pointId,
                signals: a,
              },
            },
          });
        }, Math.floor(data?.point?.metadata?.datagenerator?.interval) * 1000);
      } else {
        setIsInterval(false);
        return () => clearTimeout(timerRef);
      }
    } else {
      setIsInterval(false);
      return () => clearTimeout(timerRef);
    }
  };

  const onBlurFunction = () => {
    setIsInterval(false);
    return () => clearTimeout(timerRef);
  };

  useEffect(() => {
    if (data?.point?.metadata?.datagenerator?.data) {
      onFocusFunction();
      window.addEventListener("focus", onFocusFunction);
      window.addEventListener("blur", onBlurFunction);
      return () => {
        onBlurFunction();
        window.removeEventListener("focus", onFocusFunction);
        window.removeEventListener("blur", onBlurFunction);
      };
    }
  }, [data, onFocusFunction]);
  return (
    <Layout>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledHeader>
            <StyledHeadline>{pointName}</StyledHeadline>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://dimensionfour.io/resources/user-guide/quickstart"
                style={{ textDecoration: "none", marginRight: "28px" }}
              >
                <Document />
              </a>
              <NavLink to="/profile/settings">
                <ProfileImage />
              </NavLink>
            </div>
          </StyledHeader>
          {dashboardMetadata ? (
            <TabsPanel
              panels={TABS_DASH}
              onChange={onTabChange}
              isParent={false}
              defaultValue={tab}
            >
              <Dashboard metadata={dashboardMetadata} />
              <Signals metaData={initialValues} isIntervalActive={isInterval} />
              <StyledContainer>
                <UpdatePointForm
                  tenantId={tenantId || ""}
                  pointId={pointId || ""}
                  initialValues={initialValues}
                  updatePoint={updatePoint}
                />
                <StyledButtons>
                  <IconButton onClick={toggleModal} variant="neutral">
                    <ClearIcon />
                    Delete point
                  </IconButton>
                </StyledButtons>
              </StyledContainer>
            </TabsPanel>
          ) : (
            <TabsPanel
              panels={TABS}
              onChange={onTabChange}
              isParent={false}
              defaultValue={tab}
            >
              <Signals metaData={initialValues} isIntervalActive={isInterval} />
              <div>
                {(externalId == `dg-a-${pointId}` ||
                  externalId == `dg-m-${pointId}`) && (
                  <Box gridColumn="span 12" style={{ marginTop: 64 }}>
                    <button
                      onClick={() =>
                        setModals({ ...modals, updateSignal: true })
                      }
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Item className="body2">
                        <DataGenerator />
                        <Item className="root1">
                          <StyledTitle className="body">
                            Data Generator is{" "}
                            {isInterval ? `Active` : `Stopped`}
                          </StyledTitle>
                          <Item className="container">
                            <StyledLabel className="body">
                              {isInterval
                                ? `The data will be generated as long as you keep
                              your browser open. You can edit the generator at
                              all times`
                                : `You can edit the generator at
                                all times and generate mocked signals for this point using Data generator`}
                              .
                            </StyledLabel>
                          </Item>
                        </Item>
                      </Item>
                    </button>
                  </Box>
                )}
                <StyledContainer>
                  <UpdatePointForm
                    tenantId={tenantId || ""}
                    pointId={pointId || ""}
                    initialValues={initialValues}
                    updatePoint={updatePoint}
                  />
                  <StyledButtons>
                    <IconButton onClick={toggleModal} variant="neutral">
                      <ClearIcon />
                      Delete point
                    </IconButton>
                  </StyledButtons>

                  <CreateSignalsModal
                    open={modals.createSignal}
                    onClose={() =>
                      setModals({ ...modals, createSignal: false })
                    }
                    tenantId={tenantId}
                    pointId={pointId}
                    metaData={initialValues}
                  />
                  <UpdateSignalsModal
                    open={modals.updateSignal}
                    onClose={() =>
                      setModals({ ...modals, updateSignal: false })
                    }
                    tenantId={tenantId}
                    pointId={pointId}
                    metaData={initialValues}
                  />
                </StyledContainer>
                {!(
                  externalId == `dg-m-${pointId}` ||
                  externalId == `dg-a-${pointId}`
                ) && (
                  <Box gridColumn="span 12">
                    <button
                      onClick={() =>
                        setModals({ ...modals, createSignal: true })
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <Item className="body2">
                        <DataGenerator />
                        <Item className="root1">
                          <StyledTitle className="body">
                            Data Generator
                          </StyledTitle>
                          <Item className="container">
                            <StyledLabel className="body">
                              Our new data generator tool is designed to help
                              you create mock data for your points. With just a
                              few clicks, you can generate realistic data that
                              will help you test and showcase your data without
                              connecting a device.
                            </StyledLabel>
                          </Item>
                        </Item>
                      </Item>
                    </button>
                  </Box>
                )}
              </div>
            </TabsPanel>
          )}
        </>
      )}
      <Modal
        open={showModal}
        title={`Are you sure you want to delete the point “${pointName}”?`}
        footer={{
          okText: "Delete point",
          onOk: onSubmit,
          onCancel: toggleModal,
        }}
        style={{ maxWidth: "467px" }}
      >
        <div>The point cannot be recovered after being deleted.</div>
      </Modal>
    </Layout>
  );
}

const StyledHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 60px 0;
`;

const StyledContainer = styled("div")`
  margin: 48px 0;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
`;

const StyledHeadline = styled("h2")`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledButtons = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding: 32px;
`;
const Item = styled(Paper)(({ theme }) => ({
  "&.root1": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "11px 0 0 32px",
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    maxWidth: "100%",
    width: "100%",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.container": {
    backgroundColor: "transparent",
    ...theme.typography.body2,
    margin: "11px 0 0 0",
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    boxShadow: "none",
    svg: {
      path: {
        fill: "#000000",
      },
    },
  },
  "&.body": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    display: "flex",
    borderRadius: "20px",
    minHeight: "198px",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    border: "1px solid #D7E2FB",
  },
  "&.body2": {
    backgroundColor: "#FFFFFF",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    display: "flex",
    borderRadius: "20px",
    minHeight: "130px",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    border: "1px solid #D7E2FB",
  },
  "&.body3": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
    display: "flex",
    borderRadius: "20px",
    alignItems: "center",
    textAlign: "start",
    boxShadow: "none",
  },
  "&.body4": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    width: "100%",
  },
  "&.body5": {
    backgroundColor: "#FFFFFF",
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    borderRadius: "20px",
  },
  "&.body6": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D7E2FB",
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "Column",
    alignItems: "start",
    textAlign: "start",
    boxShadow: "none",
    borderRadius: "20px",
  },
}));

const StyledTitle = styled("h4")`
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 32px 0;
  font-family: "poppins";
  color: ${({ theme }) => theme.palette.common.white};
  &.overview {
    color: #ffa654;
    text-transform: capitalize;
  }
  &.body {
    margin: 0;
    color: var(--dark-blue-color);
  }
  &.document {
    color: var(--dark-blue-color);
    margin: 32px 0 0;
  }
  &.steps {
    color: var(--dark-blue-color);
    margin: 0;
  }
`;

const StyledLabel = styled("div")`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 100%;
  &.iconLabel {
    margin-left: 10px;
  }

  &.iconLabel2 {
    margin-right: 12px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    svg {
      align-items: center;
    }
  }
  &.iconLabel3 {
    margin-right: 12px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    color: #010032;
    justify-content: space-between;
  }
  &.body {
    color: var(--dark-blue-color);
    margin-left: 0;
  }
`;
export default PointPage;
