import { gql } from "@apollo/client";

export const CREATE_TENANT = gql`
  mutation CREATE_TENANT($input: CreateTenantInput!) {
    tenant {
      create(input: $input) {
        id
        createdAt
        name
      }
    }
  }
`;
