/* eslint-disable react/display-name */
import { useMount } from "react-use";
import React, { forwardRef } from "react";
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom";
import { Paragraph, theme } from "@gilbarbara/components";
import { useAppContext } from "./context";
import { ReactComponent as Alert } from "common/assets/overview/alert.svg";
import Joyride, {
  BeaconRenderProps,
  CallBackProps,
  TooltipRenderProps,
} from "react-joyride";

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Box, Button, H3, Spacer } from "@gilbarbara/components";

export default function MultiRouteWrapper() {
  const {
    setState,
    state: { run, stepIndex, steps, continuous },
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const Tooltip = ({
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
  }: TooltipRenderProps) => {
    return (
      <Box
        {...tooltipProps}
        border={false}
        maxWidth={530}
        minWidth={224}
        overflow="hidden"
        radius="md"
        style={{
          background: "#010032",
          padding: "24px",
          color: "white",
          borderRadius: 8,
        }}
      >
        <Box padding="md">
          {step.title && (
            <H3 mb="md" variant="primary">
              {step.title}
            </H3>
          )}
          {step.content && <Box>{step.content}</Box>}
        </Box>
        <Box
          padding="xs"
          style={{
            background: "#010032",
            padding: "32px 0 0",
            color: "white",
          }}
          variant="primary"
        >
          <Spacer distribution="space-between">
            {index == 0 && (
              <Button
                {...skipProps}
                size="lg"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "200px",
                  height: "48px",
                  border: "1px solid #FFA654",
                  borderRadius: 4,
                  background: "transparent",
                  fontFamily: "Sora",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#FFA654",
                }}
              >
                {continuous ? "back" : "Great, I’m on my way"}
              </Button>
            )}
            {index > 0 && (
              <Button
                {...skipProps}
                size="sm"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "111px",
                  height: "48px",
                  border: "1px solid #FFA654",
                  borderRadius: 4,
                  background: "transparent",
                  fontFamily: "Sora",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#FFA654",
                }}
              >
                {continuous ? "back" : "I got it"}
              </Button>
            )}
            <Paragraph style={{ fontSize: 15, fontFamily: "Sora" }}>
              {index !== 0 && (
                <>
                  {index}&nbsp;/&nbsp;{steps.length - 1}
                </>
              )}
            </Paragraph>
            {index > 0 && (
              <Spacer>
                <Button
                  {...primaryProps}
                  size="sm"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "111px",
                    height: "48px",
                    border: "1px solid #FFA654",
                    borderRadius: 4,
                    background: "transparent",
                    fontFamily: "Sora",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "#FFA654",
                  }}
                >
                  {continuous ? "Next" : "Next"}
                </Button>
              </Spacer>
            )}
          </Spacer>
        </Box>
      </Box>
    );
  };
  const { name: tenantId } = useParams();

  useMount(() => {
    setState({
      steps: [
        {
          target: "#home",
          placement: "right" as const,
          event: "hover",
          hideCloseButton: true,
          beaconComponent: BeaconComponentHome,
          content: (
            <>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: 24,
                }}
                bold
                size="large"
              >
                Your tenant is ready
              </Paragraph>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 16,
                  padding: "12px 0 0",
                }}
              >
                Your tenant is set up and ready to be used, go have a look!
              </Paragraph>
            </>
          ),
        },
        {
          target: "#managetenant",
          event: "hover",
          hideCloseButton: true,
          placement: "bottom" as const,
          content: (
            <>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: 20,
                  lineHeight: "24px",
                }}
                bold
                size="large"
              >
                Manage tenant
              </Paragraph>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 16,
                  padding: "12px 0 0",
                  lineHeight: "24px",
                }}
              >
                This is where you find all the information about your tenant,
                your members, integrations and external connections. <br />
                <br />
                This is also where you can edit and delete everything connected
                to your tenant. <br />
              </Paragraph>
            </>
          ),
        },
        {
          target: "#spaces",
          event: "hover",
          hideCloseButton: true,
          placement: "bottom" as const,
          content: (
            <>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: 24,
                }}
                bold
                size="large"
              >
                Spaces
              </Paragraph>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 16,
                  padding: "12px 0 0",
                }}
              >
                Here you’ll find the spaces belonging to your tenant. Add, edit
                and delete your spaces to create the perfect structure for you.
                <br />
              </Paragraph>
            </>
          ),
        },
        {
          target: "#points",
          event: "hover",
          hideCloseButton: true,
          placement: "bottom" as const,
          content: (
            <>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: 24,
                }}
                bold
                size="large"
              >
                Points
              </Paragraph>
              <Paragraph
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 16,
                  padding: "12px 0 0",
                }}
              >
                All the points connected to this tenant will show up in this
                tab. Add, delete and edit your points and see the live signals
                come in.
                <br />
              </Paragraph>
            </>
          ),
        },
      ],
    });
  });

  const handleCallback = async (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;
    if (location.pathname == `/overview/${tenantId}`) {
      if (action !== "skip" && type === "step:after") {
        setState({
          run: true,
          tourActive: true,
          stepIndex: 0,
          continuous: true,
        });
      }
      if (type === "step:after" && index === 0) {
        setState({
          run: true,
          tourActive: true,
          stepIndex: 1,
          continuous: true,
        });
        navigate(`/overview/${tenantId}`, {
          replace: true,
        });
      }
    } else if (location.pathname == `/t/${tenantId}`) {
      if (type === "step:after" && index === 0) {
        setState({
          run: true,
          tourActive: true,
          stepIndex: 1,
          continuous: true,
        });
        navigate(`/t/${tenantId}`, {
          replace: true,
          state: { defaultTab: 2 },
        });
      } else if (type === "step:after" && index === 1) {
        setState({
          run: true,
          tourActive: true,
          stepIndex: 2,
          continuous: true,
        });
        navigate(`/t/${tenantId}`, {
          replace: true,
          state: { defaultTab: 1 },
        });
      } else if (type === "step:after" && index === 2) {
        setState({
          run: true,
          tourActive: true,
          stepIndex: 3,
          continuous: true,
        });
        navigate(`/t/${tenantId}`, {
          replace: true,
          state: { defaultTab: 0 },
        });
      } else if (action === "reset" || lifecycle === "complete") {
        setState({ run: false, stepIndex: 0, tourActive: false });
      }
    }
  };

  return (
    <>
      <Outlet />
      <Joyride
        callback={handleCallback}
        beaconComponent={BeaconComponent}
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        tooltipComponent={Tooltip}
        styles={{
          overlay: {
            backgroundColor: "none",
          },
          options: {
            arrowColor: "#010032",
            backgroundColor: "linear-gradient(90deg, #010032 0%, #0c116b 100%)",
            primaryColor: "#0c116b",
            textColor: theme.white,
            zIndex: 10000,
          },
        }}
      />
    </>
  );
}

const pulse = keyframes`
0% {
  transform: scale(0.9);
}

60% {
  transform: scale(1);
}
`;

const BeaconButton = styled.button`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: transparent;
  margin-top: -80%;
  margin-left: -240%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  &.overview {
    margin-top: auto;
    margin-left: auto;
  }
  &:focus-visible {
    outline: transparent !important;
  }
  svg {
    &:focus {
      outline: transparent !important;
    }
    outline: transparent !important;
  }
`;
const BeaconComponent = forwardRef<HTMLButtonElement, BeaconRenderProps>(
  (props, ref) => {
    return (
      <BeaconButton ref={ref} {...props}>
        <Alert />
      </BeaconButton>
    );
  }
);
const BeaconComponentHome = forwardRef<HTMLButtonElement, BeaconRenderProps>(
  (props, ref) => {
    return (
      <BeaconButton className="overview" ref={ref} {...props}>
        <Alert />
      </BeaconButton>
    );
  }
);
