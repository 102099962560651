import { gql } from "@apollo/client";

export const GET_TENANT = gql`
  query GET_TENANT {
    plans {
      name
      normalizedName
      id
      free
      prices {
        amount
        name
        id
      }
    }
    tenant {
      id
      createdAt
      updatedAt
      name
      createdBy
      totalPoints
      billing {
        currentPlanPrice
      }
      owner {
        primaryEmail
      }
      members {
        id
        email
        role
        status
        displayName
      }
      settings {
        mqtt {
          url
          port
          hostname
          username
          password
        }
      }
    }
    accessTokens {
      name
      id
      scopes
    }
  }
`;

export const GET_TENANTS = gql`
  query GET_TENATS {
    tenants {
      id
      createdAt
      name
      totalPoints
      totalSpaces
      billing {
        currentPlanPrice
      }
      members {
        id
        userId
        role
        status
      }
    }
  }
`;

export const DELETE_TENANT = gql`
  mutation DELETE_TENANT($input: DeleteTenantInput!) {
    tenant {
      delete(input: $input) {
        id
        createdAt
        name
      }
    }
  }
`;

export const ADD_TENANT_MEMBER = gql`
  mutation ADD_TENANT_MEMBER($input: InviteMemberInput!) {
    member {
      invite(input: $input) {
        id
        email
        role
        status
      }
    }
  }
`;
