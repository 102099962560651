import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as R from "ramda";
import useNotification from "hooks/useNotification";
import useFormBuilder from "./useFormBuilder";
import useWebhookModal from "./useWebhookModal";
import { IWebhook, TAuth, TFormState, TValue } from "./webhooks.interface";
import {
  AUTH_FIELDS_KEYS,
  invalidAuth,
  invalidValues,
  normalizeFormData,
} from "./webhooks.utils";
import Modal from "lib/organisms/Modal";
import WebhookModalForm from "./webhookForm.cmp";

interface IProps {
  open: boolean;
  onClose: () => void;
  webhook: IWebhook;
}

function EditWebhookModal({ open, onClose, webhook }: IProps) {
  const [fieldsTouched, setFieldsTouched] = useState(false);
  const { name: tenantId } = useParams();
  const { updateWebhook, values, authFields, setValues, onChange } =
    useWebhookModal(tenantId || "");
  const { notify } = useNotification();

  const { values: formValues, formElements } = useFormBuilder(
    authFields,
    values.auth.type,
    webhook?.auth,
    fieldsTouched
  );

  const onEditChange = (fieldName: string, value: TValue) => {
    !fieldsTouched && setFieldsTouched(true);
    onChange(fieldName, value);
  };

  const onSubmit = async (
    formState: TFormState,
    additionalFieldsState: TAuth
  ) => {
    const input = normalizeFormData(formState, additionalFieldsState);

    try {
      await updateWebhook({
        variables: { input: { data: input, id: webhook.id } },
      });
      notify.success("Webhook has been successfully updated");
      onClose();
    } catch (e: any) {
      notify.error(e?.message);
    }
  };

  const disabledOkButton = R.or(invalidValues(values), invalidAuth(formValues));

  // In edit mode we receive data from the backend in a little different format
  useEffect(() => {
    if (open) {
      setValues((prevState) => ({
        ...prevState,
        ...R.omit(
          ["active", "createdAt", "updatedAt", "id", "__typename"],
          webhook
        ),
        auth: {
          ...R.omit(["__typename"], webhook.auth),
          type: AUTH_FIELDS_KEYS[webhook.auth.type],
        },
      }));
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title="Edit Webhook"
      footer={{
        okText: "Save",
        onOk: () => onSubmit(values, formValues),
        onCancel: onClose,
        okButtonProps: {
          disabled: disabledOkButton,
        },
      }}
      style={{ width: "467px" }}
    >
      <>
        <div>
          Change your endpoint, types, or auth method. Please be aware, changes
          will be applied immediately.
        </div>
        <WebhookModalForm values={values} onChange={onEditChange}>
          {formElements}
        </WebhookModalForm>
      </>
    </Modal>
  );
}

export default EditWebhookModal;
