import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import { Dictionary, IProfile } from "common/interfaces/shared.interface";
import {
  GET_PROFILE,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
} from "common/graphql/profile.gql";
import useFieldMode from "hooks/useFieldMode";
import useNotification from "hooks/useNotification";
import { EMPTY_STRING } from "common/utils";
import TextField from "lib/atoms/TextField/TextField";
import { Container } from "common/styles/material-ui-styles";
import FieldModeButtons from "../../../tenant/components/fieldModeButtons/fieldModeButtons.cmp";

const LABELS: Dictionary = {
  firstname: "First name",
  lastname: "Last name",
  primaryEmail: "Email",
  password: "Password",
  newPassword: "New password",
  oldPassword: "Old password",
  confirmPassword: "Repeat new password",
};

const FIELD_NAMES = [
  "firstname",
  "lastname",
  "primaryEmail",
  "password",
  "newPassword",
  "oldPassword",
  "confirmPassword",
] as const;
type GenericField = typeof FIELD_NAMES;
type UnionField = typeof FIELD_NAMES[number];

interface IProps {
  user: {
    firstname: string;
    lastname: string;
    id: string;
    primaryEmail: string;
  };
}

interface IState {
  firstname: string;
  lastname: string;
  primaryEmail: string;
  password: string;
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
}

function MemberProfile({ user }: IProps) {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    primaryEmail: "",
    password: "*********",
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  });

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    update: (store, { data }) => {
      const profileData = store.readQuery<IProfile>({ query: GET_PROFILE });
      const updateProfileData = data.profile.update;
      store.writeQuery<IProfile>({
        query: GET_PROFILE,
        data: {
          profile: {
            firstname: updateProfileData.firstname,
            lastname: updateProfileData.lastname,
            id: profileData?.profile.id || "",
            primaryEmail: profileData?.profile.primaryEmail || "",
          },
        },
      });
    },
  });

  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const { notify } = useNotification();
  const { fieldMode, setEditMode, setViewMode } = useFieldMode<
    GenericField,
    UnionField
  >(FIELD_NAMES);

  const onChange = (fieldName: keyof IState, value: string) =>
    setValues((prevState) => ({ ...prevState, [fieldName]: value }));

  const setPreviousValue = (fieldName: keyof IState) => {
    if (fieldName === "password") return;
    // TS throws an error if I use the Object "in" here...
    const value =
      fieldName === "newPassword" ||
      fieldName === "oldPassword" ||
      fieldName === "confirmPassword"
        ? EMPTY_STRING
        : user[fieldName];

    onChange(fieldName, value);
  };

  const onCancel = (fieldName: keyof IState) => {
    setViewMode(fieldName);
    setPreviousValue(fieldName);
  };

  const onUpdateProfile = () =>
    updateProfile({
      variables: {
        input: { firstname: values.firstname, lastname: values.lastname },
      },
    });

  const onUpdatePassword = () =>
    updatePassword({
      variables: {
        input: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
      },
    });

  const onSave = async (fieldName: keyof IState) => {
    const updateUser =
      fieldName === "password" ? onUpdatePassword : onUpdateProfile;

    try {
      await updateUser();
      setViewMode(fieldName);
      notify.success(
        `User's ${LABELS[fieldName]} has been successfully updated`
      );
    } catch (e: any) {
      notify.error(e?.message);
    }
  };

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      firstname: user.firstname,
      lastname: user.lastname,
      primaryEmail: user.primaryEmail || "",
    }));
  }, [user.firstname, user.lastname, user.primaryEmail]);

  return (
    <>
      <StyledHeadline>Member Profile</StyledHeadline>
      <StyledUserName>{`${user.firstname} ${user.lastname}`}</StyledUserName>
      <StyledContainer>
        <StyledFormTitle>Information</StyledFormTitle>
        <StyledRow>
          <StyledColumn style={{ minWidth: "382px" }}>
            <TextField
              id="outlined-required"
              label={LABELS.firstname}
              type="text"
              style={{ width: "100%" }}
              value={values.firstname}
              onChange={(e) => onChange("firstname", e.target.value)}
              InputProps={{
                readOnly: fieldMode.firstname === "view",
              }}
            />
          </StyledColumn>
          <StyledColumn style={{ margin: "6px 0px 0px 16px" }}>
            <FieldModeButtons
              mode={fieldMode.firstname}
              fieldName="firstname"
              id={user.id}
              onEdit={setEditMode}
              onCancel={onCancel}
              onSave={onSave}
            />
          </StyledColumn>
        </StyledRow>
        <StyledRow>
          <StyledColumn style={{ minWidth: "382px" }}>
            <TextField
              id="outlined-required"
              label={LABELS.lastname}
              type="text"
              style={{ width: "100%" }}
              value={values.lastname}
              onChange={(e) => onChange("lastname", e.target.value)}
              InputProps={{
                readOnly: fieldMode.lastname === "view",
              }}
            />
          </StyledColumn>
          <StyledColumn style={{ margin: "6px 0px 0px 16px" }}>
            <FieldModeButtons
              mode={fieldMode.lastname}
              fieldName="lastname"
              id={user.id}
              onEdit={setEditMode}
              onCancel={onCancel}
              onSave={onSave}
            />
          </StyledColumn>
        </StyledRow>
        <StyledRow>
          <StyledColumn style={{ minWidth: "382px" }}>
            <TextField
              id="outlined-required"
              label={LABELS.primaryEmail}
              type="text"
              style={{ width: "100%" }}
              value={values.primaryEmail}
              onChange={(e) => onChange("primaryEmail", e.target.value)}
              InputProps={{
                readOnly: true,
              }}
            />
          </StyledColumn>
        </StyledRow>
        {fieldMode.password === "view" && (
          <StyledRow>
            <StyledColumn style={{ minWidth: "382px" }}>
              <TextField
                id="outlined-required"
                label={LABELS.password}
                type="password"
                style={{ width: "100%" }}
                value={values.password}
                onChange={(e) => onChange("password", e.target.value)}
                InputProps={{
                  readOnly: fieldMode.password === "view",
                }}
              />
            </StyledColumn>
            <StyledColumn style={{ margin: "6px 0px 0px 16px" }}>
              <FieldModeButtons
                mode={fieldMode.password}
                fieldName="password"
                id={user.id}
                onEdit={setEditMode}
                onCancel={onCancel}
                onSave={onSave}
              />
            </StyledColumn>
          </StyledRow>
        )}
        {fieldMode.password === "edit" && (
          <>
            <StyledRow>
              <StyledColumn style={{ minWidth: "382px" }}>
                <TextField
                  id="outlined-required"
                  label={LABELS.oldPassword}
                  type="password"
                  style={{ width: "100%" }}
                  value={values.oldPassword}
                  onChange={(e) => onChange("oldPassword", e.target.value)}
                />
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <StyledColumn style={{ minWidth: "382px" }}>
                <TextField
                  id="outlined-required"
                  label={LABELS.newPassword}
                  type="password"
                  style={{ width: "100%" }}
                  value={values.newPassword}
                  onChange={(e) => onChange("newPassword", e.target.value)}
                />
              </StyledColumn>
            </StyledRow>
            <StyledRow>
              <StyledColumn style={{ minWidth: "382px" }}>
                <TextField
                  id="outlined-required"
                  label={LABELS.confirmPassword}
                  type="password"
                  style={{ width: "100%" }}
                  value={values.confirmPassword}
                  onChange={(e) => onChange("confirmPassword", e.target.value)}
                />
              </StyledColumn>
              <StyledColumn style={{ margin: "6px 0px 0px 16px" }}>
                <FieldModeButtons
                  mode={fieldMode.password} // I use the "password" field here to control the "show/hide" password related fields
                  fieldName="password"
                  id={user.id}
                  onEdit={setEditMode}
                  onCancel={onCancel}
                  onSave={onSave}
                />
              </StyledColumn>
            </StyledRow>
          </>
        )}
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  padding: 32px 24px;
  margin: 48px 0;
`;

const StyledRow = styled("div")`
  display: flex;
  margin: 16px 0 0 0;
`;

const StyledColumn = styled("div")`
  display: inline-block;
`;

const StyledHeadline = styled("h2")`
  font-size: 36px;
  font-weight: 500;
  line-height: 100%;
`;

const StyledUserName = styled("div")`
  font-size: 24px;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin: 6px 0;
`;

const StyledFormTitle = styled("h3")`
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
  color: ${({ theme }) => theme.palette.common.grey[5]};
  margin: 0 0 32px 0;
`;

export default MemberProfile;
