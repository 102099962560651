import React, { useState, useContext } from "react";

import { AuthContext } from "../../context/auth.context";

import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import { useRegister } from "../../hooks/useRegister";
import useTracker from "../../hooks/useTracker";

import { LoginParamsInput } from "../../common/interfaces/login.interface";
import { RegisterParamsInput } from "../../common/interfaces/register.interface";

import "./auth.cmp.css";
import LoginForm from "./loginForm.cmp";
import RegisterForm from "./registerForm.cmp";
import { ServerError } from "../../common/interfaces/serverError.interface";

const Auth: React.FC<{ init?: boolean }> = ({ init = true }) => {
  const { trackEvent } = useTracker();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [isSignIn, setIsSignIn] = useState<boolean>(init);
  const [loading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<ServerError>({
    showError: false,
    errorMessage: "",
  });
  const [registerForm, setRegisterForm] = useState<RegisterParamsInput>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const [loginForm, setLoginForm] = useState<LoginParamsInput>({
    service: "PASSWORD",
    params: {
      email: "",
      password: "",
      token: "",
    },
  });

  const login = useLogin(loginForm);
  const register = useRegister(registerForm);
  const token = location?.search?.match(/verifyTenantToken=(.*)/)?.[1];

  const loginHandler = () => {
    setLoading(true);
    login(loginForm)
      .then((response) => {
        const { user } = response?.data?.account?.login || "";
        authContext?.handlerLoginUser({ data: user, isAuth: true });
        setLoading(false);
        setServerError({ showError: false, errorMessage: "" });
        trackEvent({ category: "profile", action: "clickedLogin" });
        if (token !== undefined) {
          navigate(`/verifyTenantInvitation?token=${token}`, { replace: true });
        } else {
          navigate("/dashboard", { replace: true });
        }
      })
      .catch((e) => {
        setServerError({ showError: true, errorMessage: e.message });
        setLoading(false);
      });
  };

  const registerHandler = () => {
    setLoading(true);
    register(registerForm)
      .then((response) => {
        setLoading(false);
        setServerError({ showError: false, errorMessage: "" });
        navigate("/successSignUp", { replace: true });
      })
      .catch((e) => {
        setServerError({ showError: true, errorMessage: e.message });
        setLoading(false);
      });
  };

  const switchToRegistration = () => {
    setServerError({ showError: false, errorMessage: "" });
    setIsSignIn(false);
  };

  const switchToLogin = () => {
    setIsSignIn(true);
  };

  if (isSignIn && !loading && authContext?.user?.isAuth) {
    return <Navigate replace to="/dashboard" />;
  } else {
    return isSignIn ? (
      <LoginForm
        loading={loading}
        loginForm={loginForm}
        setLoginForm={setLoginForm}
        onSwitchView={switchToRegistration}
        loginHandler={loginHandler}
        serverError={serverError}
      />
    ) : (
      <RegisterForm
        loading={loading}
        serverError={serverError}
        registerForm={registerForm}
        setRegisterForm={setRegisterForm}
        onSwitchView={switchToLogin}
        registerHandler={registerHandler}
      />
    );
  }
};

export default Auth;
