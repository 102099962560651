import React, { ChangeEventHandler, KeyboardEvent, MouseEvent } from "react";
import Spinner from "../../common/assets/spinner/spinner";
import TextField from "lib/atoms/TextFieldSimple/TextField";
import { LoginParamsInput } from "../../common/interfaces/login.interface";
import { Link } from "react-router-dom";
import { ServerError } from "../../common/interfaces/serverError.interface";
import useTracker from "../../hooks/useTracker";
import { Divider } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

export interface LoginFormProps {
  loading: boolean;
  loginForm: LoginParamsInput;
  setLoginForm: React.Dispatch<React.SetStateAction<LoginParamsInput>>;
  serverError: ServerError;
  onSwitchView: React.DispatchWithoutAction;
  loginHandler: React.DispatchWithoutAction;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const {
    loading,
    loginForm,
    setLoginForm,
    onSwitchView,
    loginHandler,
    serverError,
  } = props;

  const { trackEvent } = useTracker();
  const emailHandlerLogin: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { password } = loginForm?.params || "";
    const { service } = loginForm;
    setLoginForm({ service, params: { email: event.target.value, password } });
  };

  const passwordHandlerLogin: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { email, token } = loginForm?.params || "";
    const { service } = loginForm;
    setLoginForm({
      service,
      params: { password: event.target.value, email, token },
    });
  };

  const onEnterPressHandler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      loginHandler();
    }
  };

  const changeFormHandler = (_: MouseEvent<HTMLButtonElement>) => {
    trackEvent({ category: "profile", action: "clickedCreateAccount" });
    if (onSwitchView) {
      onSwitchView();
    }
  };

  return (
    <>
      {loading ? <Spinner /> : null}

      <div className="auth-container">
        <div className="auth-wrapper">
          <p className="auth-title">Log in to your account</p>
          <p className="auth-description">
            {/* Welcome back!&nbsp;&nbsp;Your dimension is waiting for you! */}
          </p>
        </div>
        <div className="auth-input-login-container">
          <InputLabel
            sx={{}}
            className="input-label"
            shrink
            htmlFor="bootstrap-input"
          >
            Email*
          </InputLabel>
          <TextField
            id="outlined-required"
            value={loginForm.params.email}
            onChange={emailHandlerLogin}
            onKeyPress={onEnterPressHandler}
            required
            sx={{
              marginTop: "8px",
            }}
          />
          <InputLabel
            className="input-label"
            shrink
            htmlFor="bootstrap-input"
            sx={{
              marginTop: "16px",
            }}
          >
            Password*
          </InputLabel>
          <TextField
            id="outlined-required"
            type="password"
            required
            value={loginForm.params.password}
            onChange={passwordHandlerLogin}
            onKeyPress={onEnterPressHandler}
            sx={{
              marginTop: "8px",
            }}
          />
        </div>
        <div className="auth-redirect">
          <Link
            to={{ pathname: `/reset` }}
            style={{ textDecoration: "none" }}
            onClick={() =>
              trackEvent({
                category: "profile",
                action: "clickedForgotPassword",
              })
            }
          >
            Forgot your Password?
          </Link>
        </div>

        <Divider className="divider" orientation="horizontal" />
        <button
          type="submit"
          className="btn super-large yellow auth-submit-button"
          onClick={loginHandler}
        >
          Log in
        </button>
        {serverError.showError ? (
          <div className="auth-error-message">
            Error: {serverError.errorMessage}
          </div>
        ) : null}
        <div className="auth-row">
          <p>
            Need an account?&nbsp;
            <button className="auth-switch-button" onClick={changeFormHandler}>
              Sign up for free
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
