import React from "react";

interface TreeViewContextProps {
  selected: string;
  handleMenuClick?: (to?: string) => void;
  selectItem?: (nodeId: string) => void;
}

const TreeViewContext = React.createContext<TreeViewContextProps>({
  selected: "",
});

export default TreeViewContext;
