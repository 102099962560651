import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { AuthContextProvider } from "../../context/auth.context";
import { NotificationProvider } from "../../context/notification.context";

import client from "../../common/apollo-client";
import routes from "../../common/config/routes";

import StatusPage from "../../pages/status/status.page";

import "../../common/styles";
import "./app.cmp.css";
import { ProtectedRoute } from "../protectedRoute/protectedRoute.cmp";
import ThemeProvider from "lib/atoms/ThemeProvider/ThemeProvider";

import { instance } from "../tracking/tracking";

import "@fontsource/sora/600.css";
import "@fontsource/sora/400.css";
import "@fontsource/poppins/400.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

import { MatomoProvider } from "@jonkoops/matomo-tracker-react";
import MultiRouteWrapper from "Wrapper";
import { AppProviderOnboarding } from "context";

const App = () => {
  useEffect(() => {
    // clean navbar tree to trigger re-calculation
    sessionStorage.removeItem("navigation");
  }, []);

  return (
    <ApolloProvider client={client}>
      <MatomoProvider value={instance}>
        <NotificationProvider>
          <ThemeProvider>
            <AuthContextProvider>
              <AppProviderOnboarding>
                <Router>
                  <Routes>
                    {routes.map((route, index) => {
                      return route?.protected ? (
                        <Route
                          key={index}
                          path={route.path}
                          element={
                            <ProtectedRoute>
                              <MultiRouteWrapper />
                              <route.component
                                name={route.name}
                                {...route.props}
                              />
                            </ProtectedRoute>
                          }
                        />
                      ) : (
                        <Route
                          key={index}
                          path={route.path}
                          element={
                            <route.component
                              name={route.name}
                              {...route.props}
                            />
                          }
                        />
                      );
                    })}
                    <Route path="*" element={<StatusPage />} />
                  </Routes>
                </Router>
              </AppProviderOnboarding>
            </AuthContextProvider>
          </ThemeProvider>
        </NotificationProvider>
      </MatomoProvider>
    </ApolloProvider>
  );
};

export default App;
