import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { styled } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import useTracker from "hooks/useTracker";
import { ITenants } from "hooks/useTenant";

function TenantSwitcher({ tenants }: ITenants) {
  const { trackEvent } = useTracker();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { name: tenantId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    trackEvent({ category: "profile", action: "clickedOpenSwitchTenant" });
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onTenantClick = async (tenantId: string, index: number) => {
    setAnchorEl(null);
    trackEvent({ category: "profile", action: "clickedSwitchTenant" });
    if (selectedIndex !== index) {
      setSelectedIndex(index);
      await client.resetStore();
      navigate(`/overview/${tenantId}`, { replace: true });
    }
  };

  useEffect(() => {
    if (tenants.length)
      setSelectedIndex(tenants.findIndex((tenant) => tenant.id === tenantId));
  }, [tenantId, tenants]);

  if (!tenants.length || location.pathname === "/dashboard")
    return (
      <NavLink
        to="/dashboard"
        style={{ padding: "16px 0 0 16px", display: "inline-block" }}
      >
        <LogoIcon />
      </NavLink>
    );

  return (
    <div>
      <List
        component="nav"
        aria-label="Tenant switcher"
        sx={{
          bgcolor: "background.paper",
          padding: 0,
          borderBottom: "1px solid #EBF1FA",
        }}
      >
        <ListItemButton
          sx={{
            padding: "16px",
          }}
          aria-expanded={open ? "true" : undefined}
          onClick={onOpen}
          component="a"
          href="#simple-list"
          divider
        >
          <LogoIcon />
          <ListItemText
            primary={tenants[selectedIndex]?.name}
            sx={{
              margin: "0 0 0 16px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              color: "#010032",
            }}
          />
          <KeyboardArrowDownIcon sx={{ color: "#010032" }} />
        </ListItemButton>
      </List>
      <StyledMenu
        id="tenant-switcher"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {tenants.map((tenant, index) => (
          <MenuItem
            sx={{
              minWidth: "250px",
              padding: "16px",
              borderBottom: "1px solid #D4C7E9",
            }}
            key={tenant.id}
            selected={index === selectedIndex}
            onClick={() => onTenantClick(tenant.id, index)}
          >
            <ListItemText
              primary={tenant.name}
              secondary={
                <div style={{ display: "flex", padding: "4px 0 0 0" }}>
                  <div style={{ margin: "0 16px 0 0" }}>
                    {tenant.totalSpaces} spaces
                  </div>
                  <div>{tenant.totalPoints} points</div>
                </div>
              }
            />
            {index === selectedIndex && (
              <CheckIcon style={{ color: "010032" }} />
            )}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    border-radius: 0;
    box-shadow: none;
    filter: drop-shadow(3px 3px 16px rgba(30, 26, 44, 0.08));
    .MuiMenuItem-root {
      &:hover {
        background: #f6f4fb;
      }
    }
    .Mui-selected {
      background-color: #ffffff;
    }
  }
  .MuiMenu-list {
    padding: 0;
  }
`;

export default TenantSwitcher;
