import IRoute from "../interfaces/route.interface";
import HomePage from "../../pages/home/home.page";
import TenantPage from "../../pages/tenant/tenant.page";
import VerifyAccount from "../../pages/verifyAccount/verifyAccount.page";
import ResetPassword from "../../pages/resetPassword/resetPassword.page";
import SuccessSignUp from "../../pages/successSignUp/successSignUp.page";
import VerifyTenantInvitation from "../../pages/verifyTenantInvitation/verifyTenantInvitation.page";
import ResetPasswordWithToken from "../../pages/resetPasswordWithToken/resetPasswordWithToken.page";
import SpacesPage from "../../pages/tenant/spaces/spaces.page";
import PointPage from "../../pages/tenant/point/point.page";
import ProfilePage from "../../pages/profile/profile.page";
import Overview from "pages/overview/overview.page";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Home Page",
    component: HomePage,
    protected: false,
  },
  {
    path: "/home/*",
    name: "Home Page",
    component: HomePage,
    protected: false,
  },
  {
    path: "/successSignUp",
    name: "Success Sign Up",
    component: SuccessSignUp,
    protected: false,
  },
  {
    path: "/verify",
    name: "Verify Account Page",
    component: VerifyAccount,
    protected: false,
  },
  {
    path: "/verifyTenantInvitation",
    name: "Verify Tenant Invitation",
    component: VerifyTenantInvitation,
    protected: true,
  },
  {
    path: "/reset",
    name: "Reset Password Page",
    component: ResetPassword,
    protected: false,
  },
  {
    path: "/resetPassword",
    name: "Reset Password Page",
    component: ResetPasswordWithToken,
    protected: false,
  },
  {
    path: "/overview/:name",
    name: "Dashboard Page",
    component: Overview,
    protected: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard Page",
    component: Overview,
    protected: true,
  },
  {
    path: "/t/:name",
    name: "Tenat Page",
    component: TenantPage,
    protected: true,
  },
  {
    path: "/t/:name/s/:spaceId",
    name: "Spaces Page",
    component: SpacesPage,
    protected: true,
  },
  {
    path: "/t/:name/s/:spaceId/p/:pointId",
    name: "Points Page",
    component: PointPage,
    protected: true,
  },
  {
    path: "/profile/:section",
    name: "Profile Page",
    component: ProfilePage,
    protected: true,
  },
];

export default routes;
