import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material";
import { GET_TENANT } from "common/graphql/tenant.gql";
import { UPDATE_MEMBER } from "./updateMember.gql";
import { ROLES } from "../../tenant.utils";
import Modal from "lib/organisms/Modal/Modal";
import ToastNotification from "lib/molecules/ToastNotification/ToastNotification";
import TextField from "lib/atoms/TextField/TextField";
import { Container } from "common/styles/material-ui-styles";
import Spinner from "common/assets/spinner/spinner";
import DefaultDropdown from "cmp/materialCmp/defaultDropdown/defaultDropdown.materialCmp.cmp";

interface IProps {
  open: boolean;
  onClose: () => void;
  tenantId: string | undefined;
  member: {
    id: string;
    email: string;
    lastName: string;
    firstName: string;
    role: string;
  };
}

function UpdateMemberModal({ open, onClose, tenantId, member }: IProps) {
  const [role, setRole] = useState("");
  const [updateMember, { error, loading }] = useMutation(UPDATE_MEMBER, {
    context: {
      headers: {
        "x-tenant-id": tenantId,
      },
    },
    refetchQueries: [GET_TENANT],
  });

  const onSubmit = async () => {
    await updateMember({
      variables: { input: { id: member.id, role: role } },
    });
    onClose();
  };

  useEffect(() => {
    setRole(member.role);
  }, [member]);

  return (
    <Modal
      open={open}
      title="Edit member"
      footer={{
        okText: "Save",
        onOk: onSubmit,
        onCancel: onClose,
      }}
      style={{ maxWidth: "467px" }}
    >
      <Container>
        <TextField
          id="outlined-required"
          label="First name"
          placeholder="Ana"
          value={member.firstName}
          sx={{
            marginTop: "16px",
          }}
          style={{ width: "100%" }}
          disabled
        />
        <TextField
          id="outlined-required"
          label="Last name"
          placeholder="Andersen"
          value={member.lastName}
          sx={{
            marginTop: "16px",
          }}
          style={{ width: "100%" }}
          disabled
        />
        <TextField
          id="outlined-required"
          label="Email"
          placeholder="email@email.com"
          value={member.email}
          sx={{
            marginTop: "16px",
          }}
          style={{ width: "100%" }}
          disabled
        />
        <DefaultDropdown
          variant="outlined"
          label="Role"
          placeholder="Role"
          onChange={(e) => setRole(e.target.value)}
          value={role}
          sx={{
            marginTop: "16px",
          }}
          style={{ width: "100%" }}
          options={ROLES}
          select
        />
        {!!error && (
          <StyledNotification severity="error">
            {error?.message}
          </StyledNotification>
        )}
        {loading ? <Spinner /> : null}
      </Container>
    </Modal>
  );
}

const StyledNotification = styled(ToastNotification)`
  margin: 20px 0;
`;

export default UpdateMemberModal;
