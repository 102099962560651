import { styled } from "@mui/material/styles";
import { InputLabel, OutlinedInput } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TableRow from "@mui/material/TableRow";

const inputColors = {
  inactiveOutlineBorder: "rgba(82, 88, 235, 0.5)",
  textPrimary: "rgb(0,0,0,87%)",
  textSecondary: "rgb(0,0,0,60%)",
  primary: "rgb(83,29,167,100%)",
  error: "#F16063",
  white: "#FFFFFF",
};

const StyledInputLabel = styled(InputLabel)({});
const StyledOutlinedTextFiled = styled(OutlinedInput)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& input:valid + div + fieldset": {
    borderColor: `${inputColors.inactiveOutlineBorder}`,
    color: `${inputColors.textPrimary}`,
  },
  "& input:invalid + div + fieldset": {
    borderColor: "red",
  },
  "& input:valid:focus + div + fieldset": {
    borderColor: `${inputColors.primary}`,
    color: "red",
  },
  "& input:valid:hover + div + fieldset": {
    borderColor: `${inputColors.primary}`,
  },
  "& .MuiFilledInput-root": {
    color: `${inputColors.textSecondary}`,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `${inputColors.inactiveOutlineBorder}`,
    },
    "&:hover fieldset": {
      borderColor: `${inputColors.primary}`,
    },
    "&.Mui-focused fieldset": {
      borderColor: `${inputColors.primary}`,
    },
  },

  "& input:valid:hover + .MuiFilledInput-root": {
    color: `${inputColors.primary}`,
  },
  "& .Mui-focused": {
    color: `${inputColors.primary}`,
  },
});

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-endAdornment {
    display: ${({ readOnly }) => (readOnly ? "none" : "block")};
  }
  & fieldset {
    border: ${({ readOnly }) => (readOnly ? "none" : null)};
  }
  &:hover fieldset {
    border: ${({ readOnly }) => (readOnly ? "none" : null)};
    border-color: ${inputColors.primary};
  }
  & label.Mui-focused {
    color: ${({ theme, readOnly }) =>
      readOnly ? theme.palette.text.secondary : theme.palette.primary.main};
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ readOnly }) => (readOnly ? "none" : null)};
  }
`;

const Container = styled("div")``;

// with hover on child buttons
const StyledTableRow = styled(TableRow)`
  &:hover .MuiIconButton-root {
    background: #f6f4fb;
  }
`;

export {
  StyledInputLabel,
  StyledOutlinedTextFiled,
  StyledTableRow,
  StyledAutocomplete,
  inputColors,
  Container,
};
