import React from "react";
import {
  IconButtonProps,
  createTheme,
  ThemeProvider,
  styled,
} from "@mui/material";
import MUIIconButton from "@mui/material/IconButton";

export type IconButtonVariant = "primary";

interface IProps extends IconButtonProps {
  variant?: IconButtonVariant;
}

export const theme = createTheme({
  components: {
    // Name of the component
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "#3237B8",
          border: "1px solid transparent",
          ":hover": {
            backgroundColor: "#EBF1FA !important",
          },
          ":focus": {
            backgroundColor: "#EBF1FA !important",
            border: "1px dashed #531DA7",
          },
          ":active": {
            backgroundColor: "#D4C7E9 !important",
            color: "#3237B8",
          },
        },
      },
    },
  },
});

function MaterialIconButton({ ...props }: IProps) {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton {...props} />
    </ThemeProvider>
  );
}

const StyledButton = styled(MUIIconButton)<IProps>``;

export default MaterialIconButton;
