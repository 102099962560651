import { gql } from "@apollo/client";

export const DELETE_TOKEN = gql`
  mutation DELETE_TOKEN($input: DeleteAccessTokenInput!) {
    accessToken {
      delete(input: $input) {
        name
      }
    }
  }
`;
